import React, { useEffect, useState } from 'react';
import { deleteProjectMembership, getProjectMemberships, getProjectParticipations, postSendEmailInvitation, putProjectMembership, putProjectParticipations } from '../../api/projectMembership';
import { NotificationService } from '../../helper/notification';
import "./ProjectParticipationShareButton.css";

const ShareButton = ({ projectId }) => {
    const [showModal, setShowModal] = useState(false);
    const [projectParticipations, setProjectParticipations] = useState(null);
    const [projectMemberships, setProjectMemberships] = useState(null);
    const [accessType, setAccessType] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [email, setEmail] = useState("");
    const [showCollaborators, setShowCollaborators] = useState(false);

    const emailValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/


    useEffect(() => {
        const getProjectInvitation = async () => {
            try {
                const participationData = await getProjectParticipations(projectId);
                setProjectParticipations(participationData);
                setAccessType(participationData.projectAccessType);

                const membershipData = await getProjectMemberships(projectId);
                setProjectMemberships(membershipData);

            } catch (error) {
                NotificationService.error("An error occured");
            }
        }

        getProjectInvitation();
    }, []);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(projectParticipations.inviteLink);
        alert('Link copied to clipboard!');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleKeyDown = (e) => {
        if ((e.key === " " || e.key === ",") && emailValidation.test(email.trim())) {
            e.preventDefault();
            handleAddEmail();
        }
    };

    const handleAddEmail = () => {
        if (emailValidation.test(email.trim())) {
            setEmailList([...emailList, email.trim()]);
            setEmail("");
        }
    };

    const handleRemoveEmail = (index) => {
        const newEmailList = emailList.filter((_, i) => i !== index);
        setEmailList(newEmailList);
    };

    const sendLinkToEmail = async () => {
        await postSendEmailInvitation(emailList.join("&invitedUsersEmail="), projectParticipations.project.projectName, projectParticipations.inviteLink);
        NotificationService.success("Invitation mail is sent successfully.")
        setEmailList([]);
    }

    const handleAccessTypeChange = (type) => {
        setAccessType(type);
        putProjectParticipations(projectId, type);
    };

    const handleShowCollaborators = () => {
        setShowCollaborators(true);
    };

    const handleBack = () => {
        setShowCollaborators(false);
    };

    const handleCollaboratorsAccessTypeChange = async (id, type) => {
        if (type === "NO_ACCESS") {
            // Call the function to delete the membership
            await deleteProjectMembership(id);

            // Update the local state by filtering out the removed membership
            setProjectMemberships((prevMemberships) =>
                prevMemberships.filter((membership) => membership.id !== id)
            );
        } else {
            // Update the project access type on the server
            await putProjectMembership(id, { projectAccessType: type });

            // Update the local state immediately
            setProjectMemberships((prevMemberships) =>
                prevMemberships.map((membership) =>
                    membership.id === id ? { ...membership, projectAccessType: type } : membership
                )
            );
        }
    };


    return (
        <>
            {/* Share Button */}
            <button className="btn secondary-btnbtn secondary-btn fw-bold mx-1 my-3 px-3 py-2" onClick={handleShowModal}>
                Share &nbsp; <i className="bi bi-link" />
            </button>

            {/* Share Modal */}
            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Share Link</h5>
                                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                {showCollaborators ? (
                                    <>
                                        <button className="btn btn-outline-dark" onClick={handleBack}>
                                            <i className="bi bi-arrow-left" />
                                        </button>
                                        {/* Collaborators Section */}
                                        <h5 className="text-center">Collaborators</h5>
                                        <div className="mb-3">
                                            {
                                                projectMemberships.map((collaborator, index) => (
                                                    index === 0 ? (
                                                        <>
                                                            <div className="border d-flex justify-content-between px-2 pt-2">
                                                                <p>{collaborator.userEmail}</p>
                                                                <p>{collaborator.projectAccessType}</p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="border d-flex justify-content-between px-2 pt-2">
                                                                <p>{collaborator.userEmail}</p>
                                                                <div>
                                                                    <select
                                                                        className="form-select"
                                                                        id="accessType"
                                                                        value={collaborator.projectAccessType}
                                                                        onChange={(e) => handleCollaboratorsAccessTypeChange(collaborator.id, e.target.value)}
                                                                    >
                                                                        <option value="NO_ACCESS">No Access</option>
                                                                        <option value="VIEW_ONLY">Viewer</option>
                                                                        <option value="CAN_EDIT">Editor</option>
                                                                        <option value="FULL_ACCESS">Full Access</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                )
                                                )
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* Access Type Dropdown */}
                                        <div className="mb-3">
                                            <label htmlFor="accessType" className="form-label">Access Type</label>
                                            <select
                                                className="form-select"
                                                id="accessType"
                                                value={accessType}
                                                onChange={(e) => handleAccessTypeChange(e.target.value)}
                                            >
                                                <option value="NO_ACCESS">No Access</option>
                                                <option value="VIEW_ONLY">Viewer</option>
                                                <option value="CAN_EDIT">Editor</option>
                                                <option value="FULL_ACCESS">Full Access</option>
                                            </select>
                                        </div>
                                        {/* Enter Email Address */}
                                        <div className="collaborators-input mb-3">
                                            <div className="input-wrapper d-flex flex-wrap align-items-center w-100 me-3 mb-3">
                                                {emailList.map((email, index) => (
                                                    <div key={index} className="email-tag">
                                                        {email}
                                                        <button
                                                            type="button"
                                                            className="remove-btn"
                                                            onClick={() => handleRemoveEmail(index)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    type="email"
                                                    className="form-control email-input"
                                                    id="email"
                                                    placeholder="Invite by email"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                            <button
                                                className="btn secondary-btn w-100"
                                                onClick={sendLinkToEmail}
                                                disabled={email !== "" || emailList.length === 0}
                                            >
                                                Invite
                                            </button>
                                        </div>
                                        {/* Collaborators Section Button */}
                                        <div className="mb-3">
                                            <button
                                                className="btn btn-outline-dark"
                                                style={{ width: "100%" }}
                                                onClick={handleShowCollaborators}>
                                                Collaborators &nbsp;<i className="bi bi-arrow-right" />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                {/* Share Link */}
                                <button
                                    type="button"
                                    className="btn btn-link link-underline link-underline-opacity-0"
                                    onClick={handleCopyLink}
                                >
                                    <i className="bi bi-link-45deg" /> Copy Link
                                </button>
                                <button className="btn btn-secondary" onClick={handleCloseModal}>
                                    Close
                                </button>

                            </div>
                        </div>
                    </div >
                </div >
            )}
        </>
    );
};

export default ShareButton;
