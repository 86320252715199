import { getUserInfo, isAuthenticated } from "../../helper/auth";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import "./Navbar.css";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
const Navbar = () => {
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(isAuthenticated());
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuth(isAuthenticated());
      let userInfoResponse = getUserInfo();

      if (userInfoResponse.roleName === null) {
        userInfoResponse.roleName = "";
        console.log(userInfoResponse.role);
      }

      if (userInfoResponse.subscriptionPlanName == null) {
        userInfoResponse.subscriptionPlanName = "";
      }

      setUserInfo(userInfoResponse);
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener('scroll', function () {
      let topPos = window.scrollY;
      if (topPos > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const {t, i18n} = useTranslation();


  const clickHandle = async lang => {
    await i18n.changeLanguage(lang);
  }

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
      <>
        <nav className={`row navbar navbar-expand-xxl custom-navbar fixed-top d-flex p-3  ${isScrolled ? "scrolled" : ""}`} >
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between ps-5 ">
              <a
                  className="navbar-brand"
                  href={
                    !isAuth
                        ? "/"
                        : userInfo.roleName === "ADMIN"
                            ? "/dashboard"
                            : "/projects"
                  }
              >
                <img src={logo} alt="logo" width={"30%"} />
              </a>
              <button
                  className="navbar-toggler"
                  type="button"
                  aria-expanded={isMenuOpen}
                  onClick={toggleMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}>
              <ul className="navbar-nav mb-2 mb-lg-0" >
                {!isAuth ? (
                    <>
                      <li className="nav-item pe-5">
                        <a className={`nav-link border border-dark rounded-4  ${location.pathname === '/' ? 'border-2' : 'border-0'} active custom-link`} href="/">
                          {t('navbar.home')}
                        </a>
                      </li>
                      <li
                          className="nav-item dropdown pe-5"
                          onMouseEnter={() => setIsDropdownOpen(true)}
                          onMouseLeave={() => setIsDropdownOpen(false)}
                      >
                        <div
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/about') ? 'border-2' : 'border-0'} active custom-link dropdown-toggle`}
                            id="aboutDropdown"
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen}
                        >
                          <a
                              className={`text-dark link-underline link-underline-opacity-0 ${location.pathname.startsWith('/about')}`}
                              href="/about"
                          >
                            {t('navbar.about')}
                          </a>
                        </div>
                        <div
                            className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                            aria-labelledby="aboutDropdown"
                        >
                          <a
                              className={`nav-link ${location.pathname.startsWith('/about/partnership')}`}
                              href="/about/partnership"
                          >
                            {t('navbar.partnership')}
                          </a>
                        </div>
                      </li>
                      <li
                          className="nav-item dropdown pe-5"
                          onMouseEnter={() => setIsDropdownOpen2(true)}
                          onMouseLeave={() => setIsDropdownOpen2(false)}
                      >
                        <div
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/pricing') ? 'border-2 ' : 'border-0'} active custom-link dropdown-toggle`}
                            id="pricingDropdown"
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen2}
                        >
                          <a
                              className={`text-dark link-underline link-underline-opacity-0 ${location.pathname.startsWith('/pricing')}`}
                              href="/pricing"
                          >
                            {t('navbar.pricing')}
                          </a>
                        </div>
                        <div
                            className={`dropdown-menu ${isDropdownOpen2 ? "show" : ""}`}
                            aria-labelledby="pricingDropdown"
                        >
                          <a
                              className={`nav-link ${location.pathname.startsWith('/pricing/architect-and-engineer')}`}
                              href="/pricing/architect-and-engineer"
                          >
                            {t('navbar.architectandengineer')}
                          </a>
                          <a
                              className={`nav-link ${location.pathname.startsWith('/pricing/real-estate-developer')}`}
                              href="/pricing/real-estate-developer"
                          >
                            {t('navbar.realestatedeveloper')}
                          </a>
                          <a
                              className={`nav-link ${location.pathname.startsWith('/pricing/researcher')}`}
                              href="/pricing/researcher"
                          >
                            {t('navbar.researcher')}
                          </a>
                        </div>
                      </li>
                      <li className="nav-item pe-5">
                        <a className={`nav-link border border-dark rounded-4 ${location.pathname === '/resources' ? 'border-2' : 'border-0'} active custom-link`} href="/resources">
                          {t('navbar.resources')}
                        </a>
                      </li>
                      <li className="nav-item pe-5">
                        <a
                            className={`nav-link border border-dark rounded-4 ${location.pathname === '/contact' ? ' border-2' : 'border-0'} active custom-link`}
                            href="/contact"
                        >
                          {t('navbar.contact')}
                        </a>
                      </li>


                    </>
                ) : userInfo.roleName === "ADMIN" ? (
                    <>
                      <li className="nav-item pe-5">
                        <a
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/dashboard') ? 'border-2' : 'border-0'} active custom-link`}
                            href="/dashboard"
                        >
                          {t('navbar.dashboard')}
                        </a>
                      </li>
                      <li className="nav-item pe-5">
                        <a
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/material-list') ? 'border-2' : 'border-0'} active custom-link`}
                            href="/material-list"
                        >
                          {t('navbar.materialList')}
                        </a>
                      </li>
                    </>
                ) : (
                    <>
                      <li className="nav-item pe-5">
                        <a
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/projects') ? 'border-2' : 'border-0'} active custom-link`}
                            href="/projects"
                        >
                          {t('navbar.projects')}
                        </a>
                      </li>
                      <li className="nav-item pe-5">
                        <a
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/material-analyze') ? 'border-2' : 'border-0'} active custom-link`}
                            href="/material-analyze"
                        >
                          {t('navbar.materialAnalyze')}
                        </a>
                      </li>
                      <li className="nav-item pe-5">
                        <a
                            className={`nav-link border border-dark rounded-4 ${location.pathname.startsWith('/submit-data') ? 'border-2' : 'border-0'} active custom-link`}
                            href="/submit-data"
                        >
                          {t('navbar.submitData')}
                        </a>
                      </li>
                    </>
                )}
              </ul>
              <ul className="navbar-nav ms-auto">
                <li className={"nav-item pe-2"}>
                  <button
                      onClick={() => clickHandle(i18n.language === 'tr' ? 'en' : 'tr')}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#000',
                        padding: '10px 15px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        fontSize: '16px',
                        transition: 'background-color 0.3s',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')} // Hover rengi
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                  >
                    {i18n.language === 'tr' ? 'EN' : 'TR'}
                  </button>
                </li>

                {!isAuth ? (
                    <>
                    <li className="nav-item pe-2 ">
                      <a className="btn third-btn" href="/login">
                        {t('navbar.signIn')}
                      </a>
                    </li>
                    <li className="nav-item ">
                        <a className="btn secondary-btn" href="/sign-up">
                        {t('navbar.signUp')}
                      </a>
                    </li>
                    </>
                ) : (
                    <>
                      <li className="nav-item d-flex align-items-center"><i className="bi bi-bell-fill"></i></li>
                      <div className="d-flex align-items-center d-inline-block px-2">
                        <div className="vr" style={{width: '1px'}}></div>
                      </div>
                      <li
                          className="nav-item dropdown pe-5"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          onMouseLeave={() => setIsDropdownOpen(false)}
                      >
                        <div
                            className={`nav-link active custom-link d-flex`}
                            id="profileDropdown"
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen}
                        >
                          <div
                              className="bg-dark rounded-circle text-center d-flex align-self-center align-items-center px-3 py-2">
                            <b className="text-light">{userInfo.firstname.charAt(0)}</b></div>
                          <div className="mx-3">
                            <b className="m-0 p-0">{userInfo.firstname}</b>
                            <div><sup
                                className="m-0 p-0">{!userInfo.roleName ? "" : userInfo.roleName.charAt(0) + userInfo.roleName.slice(1).toLowerCase()}</sup>
                            </div>
                          </div>
                          <div className="d-flex align-items-center"><i
                              className={`bi ${isDropdownOpen ? "bi-caret-up-fill" : "bi-caret-down-fill"}`}></i></div>
                        </div>
                        <div
                            className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                            aria-labelledby="profileDropdown"
                        >
                          <a
                              className={`nav-link ${location.pathname === '/profile/profile-settings' ? 'bg-dark text-light' : ''} custom-link dropdown-item`}
                              href="/profile/profile-settings"
                          >
                            {t('navbar.profileSettings')}
                          </a>
                          <a
                              className="nav-link bg-danger text-light custom-link dropdown-item"
                              href="/"
                              onClick={() => {
                                localStorage.clear();
                                setIsAuth(false);
                                setUserInfo({});
                              }}
                          >
                            {t('navbar.logout')}
                          </a>
                        </div>
                      </li>
                    </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </>
  );
};

export default Navbar;
