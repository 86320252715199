import instance from "./baseInstance";

export async function analyzeSingleMaterial(jsonData) {
    const response = await instance.post("material-analyze/single-material", jsonData);
    return response.data;
}

export async function analyzeMaterialList(jsonData) {
    const response = await instance.post("material-analyze/material-list", jsonData);
    return response.data;
}