const transformDataForSankey = (groupAnalyzeResponse) => {
    const nodes = [];
    const links = [];

    groupAnalyzeResponse.forEach((group, groupIndex) => {
        const groupNodeName = group.name;

        nodes.push({ name: groupNodeName });

        group.buildingComponentAnalyzeResponses.forEach((component, componentIndex) => {
            const componentNodeName = component.buildingComponent;

            if (!nodes.find((n) => n.name === componentNodeName)) {
                nodes.push({ name: componentNodeName });
            }

            links.push({
                source: nodes.findIndex((n) => n.name === groupNodeName),
                target: nodes.findIndex((n) => n.name === componentNodeName),
                value: (component.totalCo2Emission),
            });


            component.typeAnalyzeResponses.forEach((type) => {
                const typeNodeName = type.type;

                if (!nodes.find((n) => n.name === typeNodeName)) {
                    nodes.push({ name: typeNodeName });
                }

                links.push({
                    source: nodes.findIndex((n) => n.name === componentNodeName),
                    target: nodes.findIndex((n) => n.name === typeNodeName),
                    value: Math.abs(type.totalCo2Emission),
                });
            });
        });
    });

    return { nodes, links };
};

export default transformDataForSankey;