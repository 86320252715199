import React, { useState } from 'react';
import { NotificationService } from '../../helper/notification';

const AddressField = ({ address, suggestedAddress }) => {
  const [inputAddress, setInputAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    try {
      const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(inputAddress)}&format=json&addressdetails=1`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        setSuggestions(data.slice(0, 5));
        setShowSuggestions(true);
        setError('');
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
        setError('Address could not found');
      }
    } catch (err) {
      NotificationService.error('An error occured when data fetching');
      setError('An error occured when data fetching');
    }
  };

  const handleInputChange = (value) => {
    suggestedAddress(value);
    setInputAddress(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputAddress(suggestion.display_name);
    setShowSuggestions(false);
    suggestedAddress(suggestion.display_name);
  };

  return (
      <div>
        <input
            type="text"
            className="form-control shadow-none"
            id="address"
            value={address}
            onChange={(event) => {
              handleInputChange(event.target.value);
              if (inputAddress.length > 2) {
                handleSearch();
              }
            }}
        />

        {showSuggestions && suggestions.length > 0 && (
            <ul style={{ border: '1px solid #ccc', listStyleType: 'none', padding: 0, margin: 0 }}>
              {suggestions.map((suggestion, index) => (
                  <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
                  >
                    {suggestion.display_name}
                  </li>
              ))}
            </ul>
        )}

        {error && (
            <div style={{ color: 'red' }}>
              <p>{error}</p>
            </div>
        )}
      </div>
  );
};

export default AddressField;