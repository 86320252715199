import { useNavigate } from "react-router-dom";
import "./Background.css";
import {useTranslation} from "react-i18next";

export const InsideAction = () => {
    const navigate = useNavigate();
    const {t,i18n} = useTranslation();
    return (
        <div className="bg-inside-action p-5">
            <div className="row align-items-center justify-content-center py-4">
                {i18n.language === 'tr' ? (
                    <>
                        <h1 className="col-md-9 fw-light">{t('take__action')} <span
                            className={"fw-bold"}>{t('take__action2')}</span></h1>

                    </>
                ):
                    <>
                        <h1 className="col-md-9 fw-bold">{t('take__action')} <span
                            className={"fw-light"}>{t('take__action2')}</span></h1>

                    </>
                }
                <div className="col-md-3 d-flex">
                    <button className="btn btn-light rounded-0  m-3" onClick={() => navigate("/login")}><span className="gradient-text">{t('navbar.signIn')}</span>&nbsp;&nbsp;&nbsp;<i class="bi gradient-text-2 bi-arrow-right-short"></i></button>
                    <button className="btn btn-light rounded-0 px-3 py-3 m-3" onClick={() => navigate("/contact")}><span className="gradient-text">{t('contact_us')}</span> &nbsp;&nbsp;&nbsp;<i class="bi gradient-text-2 bi-telephone invert"></i></button>
                </div>
            </div>
        </div>
    );
}