import { useState } from "react";
import { updateUserEmail } from "../../../../api/auth";
import { NotificationService } from "../../../../helper/notification";

const ChangeEmail = ({ userInfo, close }) => {
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(userInfo.email);

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleSave = async () => {
        if (!validateEmail(userInfo.email)) {
            setError("Email uygun formatta değil.");
            return;
        }

        try {
            await updateUserEmail({ email: email });
            NotificationService.success("Email changed successfully!");
            close();
        } catch {
            NotificationService.error("An error occurred");
        }
    };


    return (
        <section className="pb-5">
            <div className=" mb-3">
                <input
                    type="email"
                    className="form-control shadow-none"
                    id="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                        if (!validateEmail(event.target.value)) {
                            setError("Email uygun formatta değil.");
                        } else {
                            setError("");
                        }
                    }}
                />

                <button
                    type="submit"
                    className="btn secondary-btn w-100 mt-3 mb-2"
                    onClick={handleSave}
                >
                    Change Email
                </button>
            </div>
        </section>
    );
};

export default ChangeEmail;
