export const unitFinder = (unit) => {
  switch (unit.trim()) {
    case "m3":
      return <td>&#x33A5;</td>;
    case "m2":
      return <td>&#13217;</td>;
    default:
      return <td>{unit}</td>;
  }
};
