import React, { useEffect, useState } from 'react';
import transformDataForSankey from '../../../helper/transformDataForSankey';
import { Sankey, Tooltip } from 'recharts';

const SankeyChart = ({ groupAnalyzeResponse }) => {
    const [sankeyData, setSankeyData] = useState({ nodes: [], links: [] });

    useEffect(() => {
        // JSON verisini Sankey formatına dönüştür
        if (groupAnalyzeResponse) {
            const transformedData = transformDataForSankey(groupAnalyzeResponse);
            setSankeyData(transformedData);
        }
    }, [groupAnalyzeResponse]);

    return (
        <div>
            {sankeyData.nodes.length > 0 && sankeyData.links.length > 0 ? (
                <Sankey
                    width={800}
                    height={400}
                    data={sankeyData}
                    nodePadding={2}
                    nodeWidth={20}

                    node={{
                        fill: ({ index }) => {
                            const colors = ["#127E87", "#A5A5A5", "#223E54"];
                            return colors[index % colors.length];
                        }
                    }}
                    link={{
                        stroke: ({ index }) => {
                            const colors = ["#127E87", "#A5A5A5", "#223E54"];
                            return colors[index % colors.length];
                        },
                        strokeWidth: 10,
                        opacity: 1
                    }}
                >
                    <Tooltip />
                </Sankey>
            ) : (
                <p>Veri yükleniyor...</p>
            )}
        </div>
    );
};

export default SankeyChart;
