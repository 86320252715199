import { useEffect, useState } from "react";
import { getMaterialDataInfo } from "../../api/materialData";
import { NotificationService } from "../../helper/notification";
import { analyzeMaterialList } from "../../api/materialAnalyze";
import ImportFromExcelButton from "../../components/ImportFromExcelButton/ImportFromExcelButton";
import MaterialAnalyzeCharts from "../../components/Charts/MaterialAnalyzeCharts/MaterialAnalyzeCharts";
import ImportFromRevitButton from "../../components/ImportFromRevitButton/ImportFromRevitButton";

const MaterialAnalyze = () => {
    const [materialData, setMaterialData] = useState([]);
    const [materialList, setMaterialList] = useState([
        { buildingComponent: "", type: "", quantity: 0 },
    ]);
    const [materialAnalyze, setMaterialAnalyze] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
    const [failedModalBody, setFailedModalBody] = useState("");

    useEffect(() => {
        const getMaterialData = async () => {
            try {
                const response = await getMaterialDataInfo();
                setMaterialData(response.data.materialDataInfoMap);
            } catch (error) {
                NotificationService.error("An error occured!");
            }
        }

        getMaterialData();
    }, []);

    const handleAnalyze = async () => {
        try {
            const response = await analyzeMaterialList(materialList);
            setMaterialAnalyze(response);
            closeModal();
            NotificationService.success("Material(s) analyzed successfully");
        } catch (error) {
            NotificationService.error("An error occured!");
        }
    }

    const addFormItem = () => {
        setMaterialList([...materialList, { buildingComponent: "", type: "", quantity: 0 }]);
    };

    const deleteFormItem = (index) => {
        setMaterialList((prevMaterialList) => prevMaterialList.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, field, value) => {
        setMaterialList((prevMaterialList) => {
            const updatedFormData = [...prevMaterialList];
            updatedFormData[index][field] = value;
            return updatedFormData;
        });
    };

    const openModal = () => {
        setIsModalOpen(true);
        setMaterialList([
            {
                buildingComponent: "",
                type: "",
                quantity: 0
            }
        ]
        );
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const openFailedModal = () => {
        setIsFailedModalOpen(true);
    }

    const closeFailedModal = () => {
        setIsFailedModalOpen(false);
    }

    const setFailedModal = (failures) => {
        if (failures === null)
            return;

        openFailedModal();
        setFailedModalBody(failures.map(failuredMaterial => <p>Family: {failuredMaterial.buildingComponent} | Material Name:  {failuredMaterial.type}  |  Schedule:  {failuredMaterial.quantity}<br /></p>));
    }

    return (
        <>
            <div className="py-5"></div>
            <section>
                <div className="container mt-5">
                    <div className="d-flex justify-content-between align-items-center mb-5">
                        <h2 className="text-dark">Material Analyze</h2>
                        <button
                            type="button"
                            className="btn secondary-btn fw-bold mx-1 my-3 px-3 py-2"
                            onClick={openModal}
                        >
                            Material Analyze <i className="bi bi-graph-up"></i>
                        </button>
                    </div>
                </div>
            </section>
            <section>
                {materialAnalyze && (
                    <div className="container mt-5">
                        <hr className="mb-5" />
                        <div className="d-flex justify-content-between align-items-center mb-5">
                            <h2>Analyze Results</h2>
                        </div>
                        <MaterialAnalyzeCharts data={materialAnalyze} />
                    </div>
                )}
            </section>
            {isModalOpen && (
                <div
                    className="modal fade show d-block"
                    id="addConstructionModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="addConstructionModalLabel"
                    aria-hidden="true"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div
                        className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                        role="document"
                        style={{ maxWidth: "800px" }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="addConstructionModalLabel">
                                    Analyze Material
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={closeModal}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="container mt-2">
                                    {materialData && (
                                        <div className="mt-3">
                                            {materialList.map((item, index) => (
                                                <div key={index} className="row mb-3">
                                                    <div className="col">
                                                        <label htmlFor={`type-${index}`} className="form-label">
                                                            Material Family:
                                                        </label>
                                                        <select
                                                            id={`type-${index}`}
                                                            className="form-select"
                                                            value={item.buildingComponent}
                                                            onChange={(event) => handleInputChange(index, "buildingComponent", event.target.value)}
                                                        >
                                                            <option value="">Select</option>
                                                            {materialData &&
                                                                Object.keys(materialData).map(
                                                                    (buildingComponent) => <option value={buildingComponent}>{buildingComponent}</option>
                                                                )}
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <label htmlFor={`type-${index}`} className="form-label">
                                                            Material Name:
                                                        </label>
                                                        <select
                                                            id={`type-${index}`}
                                                            className="form-select"
                                                            value={item.type}
                                                            disabled={item.buildingComponent === ""}
                                                            onChange={(event) => handleInputChange(index, "type", event.target.value)}
                                                        >
                                                            <option value="">Select</option>
                                                            {materialData &&
                                                                materialData[item.buildingComponent]?.map(
                                                                    (type) => <option value={type.name}>{type.name}</option>
                                                                )}
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <label
                                                            htmlFor={`quantity-${index}`}
                                                            className="form-label"
                                                        >
                                                            Schedule:
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                id={`quantity-${index}`}
                                                                type="number"
                                                                className="form-control"
                                                                value={item.quantity}
                                                                onChange={(event) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        "quantity",
                                                                        event.target.value
                                                                    )
                                                                }
                                                                disabled={item.type === ""}
                                                            />
                                                            <div className="input-group-append">
                                                                {materialData &&
                                                                    materialData[item.buildingComponent]?.find(component => component.name === item.type)?.unit ? (
                                                                    <span
                                                                        className="input-group-text"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: materialData[
                                                                                item.buildingComponent
                                                                            ].find(component => component.name === item.type).unit.replace(/(\d)/, "<sup>$1</sup>"),
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span
                                                                        className="input-group-text"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: "&nbsp;",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-1 d-flex align-items-end justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => deleteFormItem(index)}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                            <div className="d-flex justify-content-end pb-2 gap-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => addFormItem()}
                                                >
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <hr />
                                    <div className="d-flex justify-content-end pb-2 gap-3">
                                        {materialData && (
                                            <>
                                                <ImportFromRevitButton
                                                    setFormData={setMaterialList}
                                                    setConstructionData={() => { }}
                                                    setFailedModal={setFailedModal}
                                                />
                                                <ImportFromExcelButton
                                                    setFormData={setMaterialList}
                                                    setConstructionData={() => { }}
                                                    materialData={materialData}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    className="btn secondary-btn"
                                    disabled={materialList.every(item => item.buildingComponent === "" || item.type === "" || item.quantity === "")}
                                    onClick={handleAnalyze}
                                >
                                    Material Analyze
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isFailedModalOpen && (
                <div class="modal fade show d-block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Thoese listed materials failured to convert:</h1>
                                <button type="button" class="btn-close" onClick={closeFailedModal} aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                {failedModalBody}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" onClick={closeFailedModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MaterialAnalyze;