import { useState } from "react";
import { saveUserInfo, signInProcess } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { NotificationService } from "../../../helper/notification";
import { getUserInfo } from "../../../helper/auth";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import image from "../auth-image-2.png";
import "../Background.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setError("Email uygun formatta değil.");
      return;
    }

    if (password.length === 0) {
      setError("Şifre boş olamaz.");
      return;
    }

    setLoading(true); // Loading başlıyor

    try {
      await signInProcess({
        email,
        password,
      });

      await saveUserInfo();
      let userRole = getUserInfo();
      console.log(userRole);
      if (userRole.roleName === null) {
        userRole.roleName = "";
      }
      window.dispatchEvent(new Event("storage"));
      navigate(userRole.roleName === "ADMIN" ? "/dashboard" : "/projects");
      NotificationService.success("You logged in successfully!");
    } catch (error) {
      setError("An error occurred!");
      NotificationService.error("An error occurred!");
    } finally {
      setLoading(false); // Loading bitiyor
    }
  };

  const { t } = useTranslation();

  return (
      <div className={"row mb-5 align-items-center"}>
        <div className={"col-md-1"}></div>
        <div className={"col-md-5 mt-5 mb-5 p-4 align-items-center justify-content-center"}>
          <h2 className="text-dark mb-3 mt-5">{t("login")}</h2>
          <p className="text-dark pb-4">{t("loginDescription")}</p>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="form-group mb-5">
            <div className="mb-3">
              <input
                  type="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (!validateEmail(event.target.value)) {
                      setError(t("invalidEmail"));
                    } else {
                      setError("");
                    }
                  }}
                  className="form-control shadow-none"
                  id="email"
                  placeholder={t("emailPlaceholder")}
              />
            </div>
            <div className="mb-3">
              <input
                  type="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    if (event.target.value.length === 0) {
                      setError(t("emptyPassword"));
                    } else {
                      setError("");
                    }
                  }}
                  className="form-control shadow-none"
                  id="password"
                  placeholder={t("passwordPlaceholder")}
              />
            </div>
            <button
                className="btn secondary-btn w-100 mt-3 mb-2 fs-5 d-flex align-items-center justify-content-center"
                onClick={handleLogin}
                disabled={loading} // Buton, loading sırasında devre dışı
            >
              {loading ? <Spinner animation="border" size="sm" /> : t("login")} {/* Spinner */}
            </button>
            <div className="text-black text-center" style={{ fontSize: "12px" }}>
              <span className={"fs-6"}>{t("noAccount")} </span>
              &nbsp;
              <a
                  className="text-danger fs-6 link-offset-2 link-underline link-underline-opacity-0"
                  href="/sign-up"
              >
                {t("signUpp")}
              </a>
              <br />
              <span className={"fs-6"}>{t("forgotPasswordd")} </span>
              &nbsp;
              <a
                  className="text-danger fs-6 link-offset-2 link-underline link-underline-opacity-0"
                  href="/forget-password-request"
              >
                {t("forgetPassword")}
              </a>
            </div>
          </div>
        </div>
        <div className={"col-md-5 mt-5 mb-5 align-items-center justify-content-center"}>
          <img src={image} width={"100%"} />
        </div>
        <div className={"col-md-1"}></div>
      </div>
  );
};

export default Login;
