import "./LogoSlider.css";
import "./Background.css";
import icon_1 from "./release.png";
import icon_2 from "./house.png";
import icon_3 from "./report.png";
import icon_4 from "./award.png";
import icon_5 from "./database.png";
import home_image_3 from "./home-image-3.png";
import home_image_3_tr from "./home-image-3-tr.png";
import home_image_4 from "./home-image-4.png";
import home_image_5 from "./home-image-5.png";
import home_image_6 from "./home-image-6.png";
import home_image_7 from "./home-image-7.png";
import home_image_8 from "./home-image-8.png";
import home_image_9 from "./home-image-9.png";
import home_image_11 from "./home-image-11.png";
import home_image_11_tr from "./home-image-11-tr.png";
import { useNavigate } from "react-router-dom";
import { InsideBanner } from "../../../components/LandingPageComponents/InsideBanner/InsideBanner";
import { useState } from "react";
import {useTranslation} from "react-i18next"


const logos = [
  "https://seeklogo.com/images/V/vitra-logo-41E765F326-seeklogo.com.png",
  "https://ceramicconcept.com/wp-content/uploads/elementor/thumbs/logo-quaseramik-pvk9exj3gli7lbez3d4reoo79tluj0g0fxjndjygow.png",
  "https://www.logovector.org/wp-content/uploads/logos/png/k/kale_seramik_logo.png",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9rgl9qPFTaabzWjMzkXiOACwxt1tovkqTbw&s",
  "https://kouzaris.com/wp-content/uploads/2021/04/logo-mobile-1.png",
  "https://www.fum.it/wp-content/uploads/2021/03/FRANCHI-UMBERTO-MARMI_logo-orizzontale-nero.png",
  "https://s3.eu-central-1.amazonaws.com/stajim/media/images/internship/image/3029_20220425173557.jpg",
  "https://saray.com/wp-content/uploads/2018/04/saray-aluminyum-yatay.png",
  "https://www.kme.com/fileadmin/DOWNLOADCENTER/KME_LOGOS/SCREEN/2019/logo_KME_2019_sw_.png",
  "https://www.cmetals.com/wp-content/uploads/2023/06/Rheinzink_LOGO_grey.png",
  "https://logowik.com/content/uploads/images/baumit6022.jpg",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZWwvxq8ccwKn6xtkW6XH0TsgVu7gJe4Lygw&s",
  "https://www.yapimarka.com/resimler/logolar/Kalekim-441.jpg",
  "https://seekvectors.com/files/download/jotun-black.png",
  "https://www.polisanholding.com/Content/SayfaIcerik/Logo_PolisanHomeCosmetics_77d88.jpeg",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/DYO_turkey_Logo.svg/800px-DYO_turkey_Logo.svg.png",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeN37kuwplgsYpIQUuoFB5vUpnI1Zm77MQjg&s",
  "https://www.gentas.com.tr/wp-content/uploads/2021/04/logo.png",
];

const LogoSlider = () => {
  return (
      <div className="logo-slider">
        <div className="logo-track">
          <div className="logo-track-inner">
            {logos.map((logo, index) => (
                <div className="logo" key={index}>
                  <img
                      src={logo}
                      alt={`Logo ${index + 1}`}
                      onError={(e) => (e.target.style.display = "none")}
                  />
                </div>
            ))}
            {logos.map((logo, index) => (
                <div className="logo" key={index + logos.length}>
                  <img
                      src={logo}
                      alt={`Logo ${index + 1}`}
                      onError={(e) => (e.target.style.display = "none")}
                  />
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

const Home = () => {
  const navigate = useNavigate();

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const {t, i18n} = useTranslation();

  return (
      <>
        <main className="pb-5 ">
          <div className="bg-home-1 ms-2 rounded-5 pt-5 mb-5 ">
            <div className="pt-5 ">
              <div className="row align-items-center justify-content-center">
                <div className="pt-5 ps-5 col-md-4">
                  <div className="pt-4">
                    <b>{t('section_title_1')}</b>
                    <p>{t('section_subtitle_1')}</p>
                  </div>
                  <div className="pt-4">
                    <h1 className="title"><b>Let's <span className="gradient-text">Spare</span> the Oxygen</b></h1>

                  </div>
                  <div className="pt-3">
                    <p className="primary-text pt-3">{t('first_explanation')}</p>
                    <p className="primary-text pt-3">{t('second_explanation')}</p>
                  </div>
                  <button
                      className="btn primary-btn rounded-0 p-3 px-4 mt-5"
                      onClick={() => navigate("/login")}>{t('button_join')} &nbsp;&nbsp;&nbsp;&nbsp;<i
                      className="bi bi-arrow-right"></i></button>
                  <div className="pt-5">
                  </div>
                </div>
                <div className="col-md-8 text-right">
                  {i18n.language === "en" ? (
                      <img src={home_image_11} width={"90%"} />
                  ) : <img src={home_image_11_tr} width={"100%"}/>}
              </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-8 bg-light border border-2 shadow rounded-5 pt-3">
                <h1 className="text-center text-dark">{t('collaborators_title')}</h1>
                <div className="d-flex justify-content-center py-3">
                  <LogoSlider/>
                </div>
              </div>
            </div>
          </div>
        </main>

        <section className="py-5">
          <div className="bg-home-2 pt-5 text-dark">
            <div className="d-flex justify-content-center">
              <h5 className="secondary-text">{t('capabilities')}</h5>
            </div>
            <div className="d-flex justify-content-center py-5">
              <h1 className="text-center col-6"><b>{t('why_ror360')}</b></h1>
            </div>
            <div className=" row d-flex justify-content-center pb-5">

              <div
                  className="card col-md-3 border-0 shadow rounded-5 p-4 m-3" onClick={() => navigate("/about")}>
                <div className="py-2"><img src={icon_1} alt={"icon-1"} width={"20%"}/></div>
                <h5 className="pt-3"><b>{t('service_1')}</b></h5>
                <p className="pt-3">{t('service_1_explanation')}
                </p>

              </div>
              <div className="card col-md-3 border-0 shadow rounded-5 p-4 m-3" onClick={() => navigate("/about")}>
                <div className={"py-2"}>
                  <img src={icon_2} alt={"icon-2"} width={"20%"}/>
                </div>
                <h5 className="pt-3"><b>{t('service_2')}</b></h5>
                <p className="pt-3">{t('service_2_explanation')}
                </p>

              </div>
              <div className="card col-md-3 border-0 shadow rounded-5 p-4 m-3" onClick={() => navigate("/about")}>
                <div className={"py-2"}>
                  <img src={icon_3} alt={"icon-3"} width={"20%"}/>
                </div>
                <h5 className="pt-3"><b>{t('service_3')}</b></h5>
                <p className="pt-3">{t('service_3_explanation')}
                </p>

              </div>
            </div>
          </div>
        </section>
        <section className="pb-5">
          <div className="container">
            <div className="row col-md-12 ps-5">
              {i18n.language === "en" ? (
                  <img className="col-md-6 pt-5" src={home_image_3} alt="home-image-3" width="50%"/>

              ) : <img className="col-md-6 pt-5" src={home_image_3_tr} alt="home-image-3" width="50%"/>
              }
              <div className="col-md-6 ps-5">
                <h5 className="secondary-text py-3">{t("about_us")}</h5>
                <h1 className="py-3">
                  {t("carbon_emission")} <span
                    className="secondary-text">{t("measurement_innovation")}</span> <span>{t("in_residences")}</span>
                </h1>
                <p>{t("calculation_description")}</p>
                <div className="d-flex pt-3">
                  <img src={icon_4} alt="icon-4"/>
                  <b className="ps-3">{t("different_roles")}</b>
                </div>
                <p>{t("platform_roles")}</p>
                <div className="d-flex pt-3">
                  <img src={icon_5} alt="icon-5"/>
                  <b className="ps-3">{t("early_design")}</b>
                </div>
                <p>{t("early_design_description")}</p>
                <button style={{letterSpacing:"3px",fontSize:"12px"}} className="btn secondary-btn btn-lg rounded-0 mt-3 py-3 px-4 " onClick={() => navigate("/about")}>
                  {t("learn_more")} <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <InsideBanner/>
        </section>

        <section className="py-5">
          <div className="d-flex justify-content-center">
            <div>
              <h5 className="secondary-text text-center">{t("pricing")}</h5>

              {/*<div className="row d-flex justify-content-around pt-5">*/}
              {/*  <img className="col-md-6" src={home_image_4} width="40%" alt="home-image-4"/>*/}
              {/*  <div className="col-md-4">*/}
              {/*    <h2 className="pt-4">{t("architect_engineer")}</h2>*/}
              {/*    <p className="pt-4">{t("architect_description")}</p>*/}
              {/*    <div className="py-3">*/}
              {/*      <div className="clickable d-flex align-items-center" onClick={() => setShow1(!show1)}>*/}
              {/*        <h5><b>{t("services")}</b></h5>*/}
              {/*        <i className={`bi ${show1 ? 'bi-chevron-up' : 'bi-chevron-down'}`}/>*/}
              {/*      </div>*/}
              {/*      {show1 && (*/}
              {/*          <ul>*/}
              {/*            <li>{t("low_carbon_guidance")}</li>*/}
              {/*            <li>{t("right_material_selection")}</li>*/}
              {/*            <li>{t("energy_intensity")}</li>*/}
              {/*            <li>{t("modeling_integration")}</li>*/}
              {/*          </ul>*/}
              {/*      )}*/}
              {/*    </div>*/}
              {/*    <button className="secondary-btn p-3" onClick={() => navigate("/pricing/4")}>*/}
              {/*      {t("learn_more")} <i className="bi bi-arrow-right"/>*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="row d-flex justify-content-around pt-5">*/}
              {/*  <div className="col-md-4">*/}
              {/*    <h2 className="pt-4">{t("real_estate_developer")}</h2>*/}
              {/*    <p className="pt-4">{t("developer_description")}</p>*/}
              {/*    <div className="py-3">*/}
              {/*      <div className="clickable d-flex align-items-center" onClick={() => setShow2(!show2)}>*/}
              {/*        <h5><b>{t("services")}</b></h5>*/}
              {/*        <i className={`bi ${show2 ? 'bi-chevron-up' : 'bi-chevron-down'}`}/>*/}
              {/*      </div>*/}
              {/*      {show2 && (*/}
              {/*          <ul>*/}
              {/*            <li>{t("performance_modeling")}</li>*/}
              {/*            <li>{t("energy_consumption")}</li>*/}
              {/*            <li>{t("material_selection")}</li>*/}
              {/*            <li>{t("design_sustainability")}</li>*/}
              {/*            <li>{t("operational_cost")}</li>*/}
              {/*          </ul>*/}
              {/*      )}*/}
              {/*    </div>*/}
              {/*    <button className="secondary-btn p-3" onClick={() => navigate("/pricing/3")}>*/}
              {/*      {t("learn_more")} <i className="bi bi-arrow-right"/>*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*  <img className="col-md-6" src={home_image_5} width="40%" alt="home-image-5"/>*/}
              {/*</div>*/}

              {/*<div className="row d-flex justify-content-around pt-5">*/}
              {/*  <img className="col-md-6" src={home_image_6} width="40%" alt="home-image-6"/>*/}
              {/*  <div className="col-md-4">*/}
              {/*    <h2 className="pt-4">{t("researcher")}</h2>*/}
              {/*    <p className="pt-4">{t("researcher_description")}</p>*/}
              {/*    <div className="py-3">*/}
              {/*      <div className="clickable d-flex align-items-center" onClick={() => setShow3(!show3)}>*/}
              {/*        <h5><b>{t("services")}</b></h5>*/}
              {/*        <i className={`bi ${show3 ? 'bi-chevron-up' : 'bi-chevron-down'}`}/>*/}
              {/*      </div>*/}
              {/*      {show3 && (*/}
              {/*          <ul>*/}
              {/*            <li>{t("epd_access")}</li>*/}
              {/*            <li>{t("academic_resources")}</li>*/}
              {/*            <li>{t("analytical_reports")}</li>*/}
              {/*          </ul>*/}
              {/*      )}*/}
              {/*    </div>*/}
              {/*    <button className="secondary-btn p-3" onClick={() => navigate("/pricing/2")}>*/}
              {/*      {t("learn_more")} <i className="bi bi-arrow-right"/>*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className={"row"}>
                <h2 className="text-center mb-4">{t('pricingg.chooseSuitablePlans')}</h2>
                <p className="text-dark text-center">**{t('pricingg.pricesChange')}</p>
                <div className="row justify-content-center">
                  <div className="row d-flex justify-content-center mb-4">
                    <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title text-center">{t('pricingg.free')}</h5>
                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('pricingg.freeTrial')}</h6>
                        <ul className="list-unstyled mb-4 flex-grow-1">
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.readOnly')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.contact24_7')}
                          </li>
                          <li className="text-dark text-opacity-25 mb-2">
                            <i className="bi bi-check-circle-fill"/> {t('pricingg.carbonEmissionAnalysis')}
                          </li>
                          <li className="text-dark text-opacity-25 mb-2">
                            <i className="bi bi-check-circle-fill"/> {t('pricingg.nationalDatabaseAPI')}
                          </li>
                          <li className="text-dark text-opacity-25 mb-2">
                            <i className="bi bi-check-circle-fill"/> {t('pricingg.comparativeAnalysis')}
                          </li>
                        </ul>
                        <button
                            className="btn secondary-text rounded-0 fw-bold"
                            onClick={() => navigate("/sign-up")}
                        >
                          {t('pricingg.joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right"/>
                        </button>
                      </div>
                    </div>
                    <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title text-center">{t('pricingg.basic')}</h5>
                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('pricingg.basicPackage')}</h6>
                        <ul className="list-unstyled mb-4 flex-grow-1">
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.singleUser')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.contact24_7')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.carbonEmissionAnalysis')}
                          </li>
                          <li className="text-dark text-opacity-25 mb-2">
                            <i className="bi bi-check-circle-fill"/> {t('pricingg.nationalDatabaseAPI')}
                          </li>
                          <li className="text-dark text-opacity-25 mb-2">
                            <i className="bi bi-check-circle-fill"/> {t('pricingg.comparativeAnalysis')}
                          </li>
                        </ul>
                        <button
                            className="btn secondary-text rounded-0 fw-bold"
                            onClick={() => navigate("/sign-up")}
                        >
                          {t('pricingg.joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right"/>
                        </button>
                      </div>
                    </div>
                    <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title text-center">{t('pricingg.professional')}</h5>
                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('pricingg.professionalPackage')}</h6>
                        <ul className="list-unstyled mb-4 flex-grow-1">
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.multipleUser')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.contact24_7')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.carbonEmissionAnalysis')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.nationalDatabaseAPI')}
                          </li>
                          <li className="mb-2">
                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.comparativeAnalysis')}
                          </li>
                        </ul>
                        <button
                            className="btn secondary-text rounded-0 fw-bold"
                            onClick={() => navigate("/sign-up")}
                        >
                          {t('pricingg.joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <h5 className="secondary-text pb-2">{t("resources")}</h5>
            <div className="row d-flex justify-content-between py-3">
              <div className="col-md-4 mb-3">
                <h2>{t("blog_title")}</h2>
                <p className="py-5">{t("blog_description")}</p>
                <button className="secondary-btn my-3 p-3 ">{t("view_more")} <i className="bi bi-arrow-right"/>
                </button>
              </div>
              <div className="col-md-8 mb-3" style={{textAlign:"right"}}>
                <div className="image-container" style={{textAlign:"left"}}>
                  <a
                      target="_blank"
                      href="https://www.inanckabadayi.com.tr/Blog/sifir-karbon-emisyonu-icin-yeni-nesil-betondan-faydalanilabilir-mi"
                  >
                    <img className="card" src={home_image_7} width="100%" alt="home-image-7"/>
                    <div className="bottom-text">
                      <button style={{fontSize: "15px"}} className="secondary-btn my-3 p-2 px-4 ">KARBON EMİSYONU
                      </button>
                      <button style={{fontSize: "15px"}} className="secondary-btn my-3 p-2 px-4 mx-3">21 Nisan 2021
                      </button>


                      <br/>
                      Sıfır Karbon Emisyonu İçin Yeni Nesil Betondan Faydalanılabilir mi?
                      <br/>
                      <span className={"fs-5"}> Daha Fazla <i className="bi bi-arrow-right"/> </span>
                    </div>
                  </a>
                </div>


              </div>
            </div>

            <div className="row d-flex justify-content-between py-3">
              <div className="col-md-7">
                <div className="image-container">
                  <a
                      target="_blank"
                      href="https://www.inanckabadayi.com.tr/Blog/dunyanin-farkli-bolgelerinde-sifir-karbon-sehir-projeleri-yukseliyor"
                  >
                    <img className="card" src={home_image_8} width="100%" alt="home-image-7"/>
                    <div className="bottom-text">
                      <button style={{fontSize: "15px"}} className="secondary-btn my-3 p-2 px-4 ">KARBON EMİSYONU
                      </button>
                      <button style={{fontSize: "15px"}} className="secondary-btn my-3 p-2 px-4 mx-3">25 Mayıs 2024
                      </button>


                      <br/>
                      Dünyanın Farklı Bölgelerinde Sıfır Karbon Şehir Projeleri Yükseliyor
                      <br/>
                      <span className={"fs-5"}> Daha Fazla <i className="bi bi-arrow-right"/> </span>
                    </div>
                  </a>
                </div>


              </div>
              <div className="col-md-5" style={{textAlign:"right"}}>
                <div className="image-container" style={{textAlign:"left"}} >
                  <a
                      target="_blank"
                      href="https://www.inanckabadayi.com.tr/Blog/kentsel-donusum--mevcut-binalari-karbondan-arindirmanin-yollari-neler"
                  >
                    <img className="card" src={home_image_9} width="100%" alt="home-image-7"/>
                    <div className="bottom-text">
                      <button style={{fontSize: "15px"}} className="secondary-btn my-3 p-2 px-4 ">KARBON EMİSYONU
                      </button>
                      <button style={{fontSize: "15px"}} className="secondary-btn my-3 p-2 px-4 mx-3">25 Mayıs 2024
                      </button>


                      <br/>
Kentsel Dönüşüm: Mevcut Binaları Karbondan Arındırmanın Yolları Neler?
                      <br/>
                      <span className={"fs-5"}> Daha Fazla <i className="bi bi-arrow-right"/> </span>
                    </div>
                  </a>
                </div>


              </div>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <div className="bg-home-3 ms-3 pt-5 mb-5">
            <div className="py-5"></div>
            <div className="d-flex justify-content-center">
              <div className="text-center col-5">
                <h1 className="title"><span className={"fw-light"}>{t("take_action_title")} </span> <span className={"fw-bold"}>{t("take_action_title2")}</span> <span className={"fw-light"}> {t("take_action_title3")}</span></h1>
                <p className="p-5">{t("take_action_description")}</p>
                <div className="d-flex justify-content-center transparent-input-container p-5">
                  <input type="text" className="form-control transparent-input pe-3"
                         placeholder={t("subscribe_placeholder")}/>
                  <button className="btn btn-dark rounded-0 ms-5 p-3 px-5"><b>{t("subscribe")}</b></button>
                </div>
                <div className="py-5"></div>
              </div>
            </div>
          </div>
        </section>

      </>
  );
};

export default Home;
