import instance from "../baseInstance";

export async function getSubscriptionPlanInfoById(pathVariable) {
    const response = await instance.get("admin/subscription-plan-info/" + pathVariable);

    return response;
}

export async function updateSubscriptionPlanInfoById(pathVariable, jsonData) {
    const response = await instance.put("admin/subscription-plan-info/" + pathVariable, jsonData);

    return response;
}

export async function deleteSubscriptionPlanInfoById(pathVariable) {
    const response = await instance.delete("admin/subscription-plan-info/" + pathVariable);

    return response;
}

export async function addSubscriptionPlanById(jsonData) {
    const response = await instance.post("admin/subscription-plan-info", jsonData);

    return response;
}

export async function getAllSubscriptionPlanInfo() {
    const response = await instance.put("admin/subscription-plan-info/find-all");

    return response;
}

export async function getAllSubscriptionPlanInfoByRole(query) {
    const response = await instance.put("admin/subscription-plan-info/find-all-by-role?roleId=" + query);

    return response;
}