import instance from "./baseInstance";

export async function getProjectParticipations(pathVariable) {
    const response = await instance.get("project-participations/" + pathVariable);
    return response.data;
}

export async function putProjectParticipations(pathVariable, query) {
    await instance.put("project-participations/" + pathVariable + "?projectAccessType=" + query);
}

export async function getProjectMemberships(pathVariable) {
    const response = await instance.get("project-memberships/project/" + pathVariable + "/memberships");
    return response.data;
}

export async function getAllProjectMemberships() {
    const response = await instance.get("project-memberships/all");
    return response.data;
}

export async function postJoinProject(jsonData) {
    console.log("sdgdsgds")
    const response = await instance.post("project-memberships/join-project", jsonData);
    console.log(response);
    return response.data;
}

export async function postSendEmailInvitation(query1, query2, query3) {
    await instance.post("projects/send-email-to-invite-project?invitedUsersEmail=" + query1 + "&projectName=" + query2 + "&inviteLink=" + query3);
}

export async function putProjectMembership(pathVariable, jsonData) {
    await instance.put("project-memberships/" + pathVariable, jsonData);
}

export async function deleteProjectMembership(pathVariable,) {
    await instance.delete("project-memberships/" + pathVariable);
}
