import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "./logo.png";

const VerificationRequest = () => {
  const navigate = useNavigate();

  /*useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/login');
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate]);*/

  return (
    <div className="">
      <div className="d-flex justify-content-center">
        <img src={logo} alt="logo" width={"50%"} />
      </div>
      <div className="d-flex justify-content-center p-3">
        <h1 className="text-success">
          <i
            className="bi bi-envelope-arrow-down-fill"
            style={{ fontSize: "5rem" }}
          ></i>
        </h1>
      </div>
      <div className="d-flex justify-content-center p-3">
        <h2>Verify Your Email Address</h2>
      </div>
      <div className="d-flex justify-content-center pt-3">
        <h5>We have sent a verification link to your email address.</h5>
      </div>
      <div className="d-flex justify-content-center pb-3">
        <h5>You might need to check your spam folder.</h5>
      </div>
    </div>
  );
};

export default VerificationRequest;
