import { useEffect, useState } from "react";
import { getAllLogsByLogtype } from "../../../api/admin/admin";
import { ClipLoader } from "react-spinners";
import { NotificationService } from "../../../helper/notification";

const InfoLogs = () => {
    const [logs, setLogs] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        const getLogs = async () => {
            try {
                const logsData = await getAllLogsByLogtype("INFO", currentPage, pageSize);
                setLogs(logsData);
            } catch (error) {
                NotificationService.error("An error occured!");
            }
        }

        getLogs();
    }, [currentPage, pageSize]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(prevPage => prevPage + pageNumber);
    };

    const handlePageSizeChange = (itemCount) => {
        setPageSize(itemCount);
    }

    return (
        <body className="bg-body-tertiary">
            <section className="pt-5">
                <div className="d-flex justify-content-between mt-5 px-5 pt-5">
                    <h2 className="tertiary-text fw-bold mb-3">&nbsp;&nbsp;Info Logs</h2>
                </div>
            </section>
            <section>
                <div className="bg-white rounded-4 m-5">
                    <div className="d-flex justify-content-around pt-3">
                        <span className="text-secondary fw-bold col-2">ID</span>
                        <span className="text-secondary fw-bold col-2">DATE</span>
                        <span className="text-secondary fw-bold col-2">LEVEL</span>
                        <div className="col-4">MESSAGE</div>
                    </div>
                    {
                        logs === null ? (
                            <ClipLoader
                                loading={logs === null}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : logs.content.length === 0 ? (
                            <div className="alert alert-info">There is no info logs right now.</div>
                        ) : (
                            logs.content.map((log) => (<>
                                <hr className="text-secondary dashed-line" />
                                <div className="d-flex justify-content-around">
                                    <div className="col-2">{log.id}</div>
                                    <div className="col-2">{log.date}</div>
                                    <div className="col-2">{log.level}</div>
                                    <div className="col-4">{log.message}</div>
                                </div>
                            </>)
                            )
                        )
                    }
                </div>
            </section>
            <section>
                {logs === null ? (<></>) : (
                    <div className="pagination d-flex justify-content-center py-5">
                        <div className="px-3">
                            <div className="input-group">
                                <label className="px-2 py-2">Items per page:</label>
                                <select
                                    className="form-select border-0 border-2 border-bottom border-dark"
                                    value={pageSize}
                                    onChange={(event) => handlePageSizeChange(event.target.value)}
                                >
                                    <option value={5}>5</option>
                                    <option value={8}>8</option>
                                    <option value={11}>11</option>
                                    <option value={14}>14</option>
                                    <option value={17}>17</option>
                                    <option value={20}>20</option>
                                </select>
                                <hr />
                            </div>
                        </div>
                        <button
                            className="btn border-0 mx-3"
                            onClick={() => handlePageChange(-1)}
                            disabled={currentPage <= 1}>
                            <i className="bi bi-arrow-left"></i></button>
                        <button
                            className="btn border-0 mx-3"
                            onClick={() => handlePageChange(1)}
                            disabled={logs.last}>
                            <i className="bi bi-arrow-right"></i></button>
                    </div>
                )
                }
            </section>
        </body>
    );
}

export default InfoLogs;