import instance from "./baseInstance";

export async function getProjects() {
  const response = await instance.get("projects/my-projects");

  return response;
}

export async function getProject(pathVariable) {
  const response = await instance.get("projects/" + pathVariable);

  return response
}

export async function addProject(jsonData) {
  const response = await instance.post(
    "projects",
    jsonData
  );
  return response;
}

export async function updateProject(pathVariable, jsonData) {
  const response = await instance.put("projects/" + pathVariable, jsonData);
  return response.data;
}

export async function deleteProject(pathVariable) {
  const response = await instance.delete("projects/" + pathVariable);
  return response.data;
}
