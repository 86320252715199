import { useRef } from "react";
import { generateMaterialListFromCsv } from "../../api/materialData";

const ImportFromRevitButton = ({
    setFormData,
    setConstructionData,
    setFailedModal
}) => {
    const fileInputRef = useRef(null);

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleUpload = async (file) => {
        if (!file) return;
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await generateMaterialListFromCsv(formData);
            setFormData(response.convertedMaterialRequests);
            setConstructionData((prevConstructionData) => ({
                ...prevConstructionData,
                createMaterialRequests: response.convertedMaterialRequests,
            }));
            setFailedModal(response.conversionFailedMaterialRequests);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <div>
            <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(event) => handleUpload(event.target.files[0])}
            />
            <button className="btn secondary-btn" onClick={handleFileInputClick}>
                Import From Revit
            </button>
        </div>
    );
};

export default ImportFromRevitButton;
