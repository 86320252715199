import React, { useEffect, useState } from 'react';
import { NotificationService } from '../../../../helper/notification';
import PaymentForm from '../../../../components/PaymentForm/PaymentForm';
import { getUserDetailed } from '../../../../api/auth';
import ProfileBanner from '../../../../components/ProfileBanner/ProfileBanner';


const ChangePayment = () => {
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        companyName: ""
    });

    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const getInfo = async () => {
            try {
                const userData = await getUserDetailed();
                setUserInfo(userData);
                setSelectedRole(userData.role);
                setSelectedPlan(userData.subscriptionPlan.subscriptionPlanInfo)
            } catch (error) {
                NotificationService.error("An error occurred: " + error);
            } finally {
                setIsLoading(false);
            }
        };

        getInfo();
    }, []);

    return (
        <body>
            <ProfileBanner userInfo={userInfo} />
            {isLoading ? (<></>) : (

                <section className="px-5 pb-5">
                    <h2>Payment Change</h2>
                    <h5>Selected Role: {selectedRole.name.charAt(0) + selectedRole.name.slice(1).toLowerCase()}</h5>
                    <h5>Selected Plan: {selectedPlan.name.charAt(0) + selectedPlan.name.slice(1).toLowerCase()}</h5>
                    <h5>Price: {selectedPlan.price}</h5>
                    <div className="px-5">
                        <PaymentForm isTransaction={false} isAdd={false} />
                    </div>
                </section>
            )
            }
        </body>
    );
};

export default ChangePayment;
