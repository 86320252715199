import instance from "../baseInstance";

export const listOpenRequests = async () => {
  const response = await instance.get(
    "material-data-requests/find-all-open-requests"
  );

  return response.data;
};

export const listClosedRequests = async () => {
  const response = await instance.get(
    "material-data-requests/find-all-closed-requests"
  );

  return response.data;
};

export const listAcceptedRequests = async () => {
  const response = await instance.get(
    "material-data-requests/find-all-accepted-requests"
  );

  return response.data;
};

export const listRejectedRequests = async () => {
  const response = await instance.get(
    "material-data-requests/find-all-rejected-requests"
  );

  return response.data;
};

export const acceptRequest = async (requestId) => {
  const response = await instance.get(
    `material-data-requests/accept-request?id=${requestId}`
  );

  return response;
};

export const rejectRequest = async (requestId) => {
  const response = await instance.get(
    `material-data-requests/reject-request?id=${requestId}`
  );

  return response;
};
