import { useEffect, useState } from "react";
import { addCreditCard, getUserCreditCardInfo, updateCreditCard } from "../../api/auth";
import { NotificationService } from "../../helper/notification";
import "./PaymentForm.css";

const PaymentForm = ({ isTransaction, isAdd, onSuccess, previousStep }) => {
  const [error, setError] = useState(null);
  const [saveCard, setSaveCard] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state eklendi
  const [creditCard, setCreditCard] = useState({
    cardNumber: "",
    userName: "",
    lastUsageDate: "",
    csv: "",
  });

  const handleCardNumberChange = (event) => {
    const rawValue = event.target.value.replace(/[^0-9]/g, ""); // Sadece sayıları al
    setCreditCard({ ...creditCard, cardNumber: rawValue });
  };

  const handleExpiryDateChange = (event) => {
    const formattedValue = event.target.value
        .replace(/[^0-9]/g, "")
        .replace(/(.{2})/, "$1/")
        .trim();
    setCreditCard({ ...creditCard, lastUsageDate: formattedValue });
  };

  const handleCvvChange = (event) => {
    const formattedValue = event.target.value.replace(/[^0-9]/g, "");
    setCreditCard({ ...creditCard, csv: formattedValue });
  };

  const handleNameChange = (event) => {
    setCreditCard({ ...creditCard, userName: event.target.value });
  };

  const handleCheckboxChange = () => {
    setSaveCard(!saveCard);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Loading başlat
    try {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const [month, year] = creditCard.lastUsageDate.split("/");
      if (
          parseInt(year) + 2000 < currentYear ||
          (parseInt(year) + 2000 === currentYear && parseInt(month) <= currentMonth)
      ) {
        NotificationService.error("Incorrect expiryDate");
        setLoading(false);
        return;
      } else if (parseInt(month) > 12) {
        NotificationService.error("Incorrect expiryDate");
        setLoading(false);
        return;
      }

      setCreditCard({ ...creditCard, cardNumber: creditCard.cardNumber.replaceAll(" ", "") });
      if (isTransaction) {
        onSuccess({
          creditCardInfo: creditCard
        });
      }
      if (saveCard) {
        await addCreditCard(creditCard);
      }
      NotificationService.success("Payment successful!");
    } catch (e) {
      NotificationService.error(e.toString());
    }
      setLoading(false); // Loading durdur

  };

  return (
      <div className="container">
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center pe-5">
            <div className="credit-card p-3">
              <div className="card-chip mb-3"></div>
              <div className="credit-card__number">{creditCard.cardNumber || "•••• •••• •••• ••••"}</div>
              <div className="credit-card__name">{creditCard.userName || "NAME SURNAME"}</div>
              <div className="d-flex justify-content-between">
                <div className="credit-card__expiry">{creditCard.lastUsageDate || "MM/YY"}</div>
                <div className="credit-card__cvc">{creditCard.csv || "CVV"}</div>
              </div>
              <div className="card-logo mt-3">VISA</div>
            </div>
          </div>
          <div className="col-10">
            <form>
              <div className="form-group">
                <div className="mb-3">
                  <label htmlFor="cardNumber" className="form-label">Card Number</label>
                  <input
                      className="form-control shadow-none"
                      type="text"
                      value={creditCard.cardNumber}
                      onChange={handleCardNumberChange}
                      maxLength="19"
                      id="cardNumber"
                      placeholder="1234 5678 9012 3456"
                      required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="cardholderName" className="form-label">Cardholder Name</label>
                  <input
                      className="form-control shadow-none"
                      type="text"
                      value={creditCard.userName}
                      onChange={handleNameChange}
                      id="cardholderName"
                      placeholder="Name Surname"
                      required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="expiryDate" className="form-label">Expiry Date</label>
                  <input
                      className="form-control shadow-none"
                      type="text"
                      value={creditCard.lastUsageDate}
                      onChange={handleExpiryDateChange}
                      maxLength="5"
                      id="expiryDate"
                      placeholder="MM/YY"
                      required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="cvv" className="form-label">CVV</label>
                  <input
                      className="form-control shadow-none"
                      type="text"
                      value={creditCard.csv}
                      onChange={handleCvvChange}
                      maxLength="4"
                      id="cvv"
                      placeholder="CVV"
                      required
                  />
                </div>
                {isAdd &&
                    (
                        <div className="form-check mb-3">
                          <input
                              className="form-check-input"
                              type="checkbox"
                              checked={saveCard}
                              onChange={handleCheckboxChange}
                              id="saveCardCheckbox"
                          />
                          <label className="form-check-label" htmlFor="saveCardCheckbox">
                            Save this card for future payments
                          </label>
                        </div>
                    )}
                <div className="d-flex justify-content-between">
                  {isAdd &&
                      (
                          <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={previousStep}
                          >
                            Back
                          </button>
                      )}
                  <button
                      type="button"
                      className="btn secondary-btn"
                      onClick={handleSubmit}
                      disabled={loading} // Butonu kilitle
                  >
                    {loading ? "Loading..." : isTransaction ? "Make Transaction" : isAdd ? "Add Credit Card" : "Update Credit Card"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export default PaymentForm;
