import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../api/admin/allUserList";
import { NotificationService } from "../../../helper/notification";
import { ClipLoader } from "react-spinners";


const UserDetails = () => {
    const { userId } = useParams();

    const [user, setUser] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        const getUserInfoById = async () => {
            try {
                const userData = await getUserById(userId);
                setUser(userData);
            } catch (error) {
                NotificationService.error("An error occured!");
            }
        }

        getUserInfoById();
    }, []);

    const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    return (
        <body className="bg-body-tertiary">
            <section>
                <div className="py-5"></div>
                <div className="container mt-5 px-5">
                    <div className="d-flex justify-content-between mb-5">
                        <h2 className="tertiary-text fw-bold">USER DETAILS</h2>
                    </div>

                    <div className="row d-flex justify-content-between mb-5">
                        <div className="col-md-4 bg-white rounded-4 p-3 mb-3">
                            {user === null ? (
                                <div className="mt-5 d-flex justify-content-center align-items-center">
                                    <ClipLoader
                                        loading={user === null}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            ) : (
                                <>
                                    <h5>General Information</h5>
                                    <p>User Id: {user.id}</p>
                                    <p>First Name: {user.firstname}</p>
                                    <p>Last Name: {user.lastname}</p>
                                    <p>Email: {user.email}</p>
                                    <p>Company Name: {user.companyName}</p>
                                </>
                            )}
                        </div>
                        <div className="col-md-4 bg-white rounded-4 p-3 mb-3">
                            {user === null ? (
                                <div className="mt-5 d-flex justify-content-center align-items-center">
                                    <ClipLoader
                                        loading={user === null}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            ) : (
                                <>
                                    <h5>Plan Information</h5>
                                    <p>Role Name: {user.role?.name}</p>
                                    <p>Subscription Plan Name: {user.subscriptionPlan?.subscriptionPlanInfo.name}</p>
                                    <p>Plan Start Date: {user.subscriptionPlan?.startDate.split("T")[0]}</p>
                                    <p>Plan End Date: {user.subscriptionPlan?.endDate.split("T")[0]}</p>
                                    <p>Plan Remaining Duration: {user.subscriptionPlan?.remainingDuration}</p>
                                </>
                            )}

                        </div>
                        <div className="col-md-3 d-flex align-items-center mb-3">
                            <div>
                                <div className="bg-white rounded-4 p-3 mb-5">
                                    {user === null ? (
                                        <div className="mt-5 d-flex justify-content-center align-items-center">
                                            <ClipLoader
                                                loading={user === null}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <h6 className="text-center">Material Data Request Count</h6>
                                            <br />
                                            <h5 className="fw-bold text-center">{user.materialDataRequests.length}</h5>
                                        </>
                                    )}
                                </div>
                                <div className="bg-white rounded-4 p-3">
                                    {user === null ? (
                                        <div className="mt-5 d-flex justify-content-center align-items-center">
                                            <ClipLoader
                                                loading={user === null}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <h6 className="text-center">Project Memberships Count</h6>
                                            <br />
                                            <h5 className="fw-bold text-center">{user.projectMemberships.length}</h5>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="users bg-white rounded-4 p-5 mb-3">
                        {user === null ? (
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <ClipLoader
                                    loading={user === null}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        ) : (
                            <>
                                <h4 className="mb-5">Project Memberships</h4>
                                <div className="table-responsive">
                                    <div className="container">
                                        <div className="row mb-3">
                                            <div className="col-2">Id</div>
                                            <div className="col-2">Project Name</div>
                                            <div className="col-3">Address</div>
                                            <div className="col-2">Total Area</div>
                                            <div className="col-2">Access Type</div>
                                            <div className="col-1"></div>
                                        </div>
                                        {user.projectMemberships.map((projectMembership) => (
                                            <>
                                                <div className="row rounded-4 mb-3 py-3" style={{ backgroundColor: '#EEF4F3' }}>
                                                    <div className="col-2">{projectMembership.id}</div>
                                                    <div className="col-2">{projectMembership.project.projectName}</div>
                                                    <div className="col-3">{projectMembership.project.address}</div>
                                                    <div className="col-2">{projectMembership.project.totalArea}</div>
                                                    <div className="col-2">{projectMembership.projectAccessType}</div>
                                                    <div className="col-1"><i onClick={() => toggleRow(projectMembership.id)} className={`bi ${expandedRow === projectMembership.id ? 'bi-chevron-up' : 'bi-chevron-down'}`} /></div>
                                                </div>
                                                {expandedRow === projectMembership.id && (
                                                    <div>
                                                        <div className="row mb-3">
                                                            <div className="col-1"></div>
                                                            <div className="col-2">Id</div>
                                                            <div className="col-2">Construction Name</div>
                                                            <div className="col-2">Material Count</div>
                                                            <div className="col-3">Last Update Date</div>
                                                        </div>
                                                        {projectMembership.project.constructions.map((construction) => (
                                                            <>
                                                                <div className="row mb-3 py-3">
                                                                    <div className="col-1"></div>
                                                                    <div className="col-2">{construction.id}</div>
                                                                    <div className="col-2">{construction.name}</div>
                                                                    <div className="col-2">{construction.materials.length}</div>
                                                                    <div className="col-3">{construction.lastUpdateDate}</div>
                                                                </div>
                                                                <div>
                                                                    <ul>
                                                                        {construction.materials.map((material) => (
                                                                            <li className="row">
                                                                                <div className="col-2"></div>
                                                                                <div className="col-2">Id: {material.id}</div>
                                                                                <div className="col-3">Building Component: {material.buildingComponent}</div>
                                                                                <div className="col-3">Type: {material.type}</div>
                                                                                <div className="col-2">Schedule: {material.quantity}</div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ))}


                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </body>
    );
}

export default UserDetails;