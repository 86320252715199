import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ mostUsedMaterials }) => {
    const data = {
        labels: [mostUsedMaterials[0][0], mostUsedMaterials[1][0], mostUsedMaterials[2][0]],
        datasets: [
            {
                data: [mostUsedMaterials[0][1], mostUsedMaterials[1][1], mostUsedMaterials[2][1]],
                backgroundColor: ['#FDD77D', '#82CFCF', '#4BA3C3'],
                hoverBackgroundColor: ['#FFD24C', '#64B2B2', '#368EA3'],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        cutout: '70%',
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return <Doughnut data={data} options={options} />;
};

export default DoughnutChart;
