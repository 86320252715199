import { useState, useEffect } from "react";
import { getUser, updateUser } from "../../../../api/auth";
import { NotificationService } from "../../../../helper/notification";

import "../Background.css";
import ProfileBanner from "../../../../components/ProfileBanner/ProfileBanner";

const ProfileSettings = () => {
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyName: "",
    roleName: ""
  });

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await getUser();
        setUserInfo(response);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };
    getUserInfo();
  }, {});

  const handleSave = async () => {
    try {
      await updateUser({
        firstName: userInfo.firstname,
        lastName: userInfo.lastname,
        companyName: userInfo.companyName,
      });
      window.location.reload();
    } catch {
      NotificationService.error("An error occurred");
    }
  };

  return (
    <body className="bg-body-tertiary">
      <ProfileBanner userInfo={userInfo} />
      <section>
        <h2 className="tertiary-text fw-bold ps-5 mb-3">&nbsp;&nbsp;Profile Settings</h2>
        <div className="bg-white rounded-4 p-5 m-5">
          {error && (
            <div className="alert alert-danger mt-3">{error}</div>
          )}
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label>Name, Surname</label>
              <div className="d-flex justify-content-around w-75">
                <div className="w-100 mb-3 me-3">
                  <input
                    type="text"
                    className="form-control shadow-none"
                    id="name"
                    value={userInfo.firstname}
                    onChange={(event) =>
                      setUserInfo({
                        ...userInfo,
                        firstname: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 w-100">
                  <input
                    type="text"
                    className="form-control shadow-none"
                    id="surname"
                    value={userInfo.lastname}
                    onChange={(event) =>
                      setUserInfo({
                        ...userInfo,
                        lastname: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <label>Company</label>
              <div className="w-75 mb-3">
                <input
                  type="company"
                  className="form-control shadow-none"
                  id="company"
                  value={userInfo.companyName}
                  onChange={(event) => {
                    setUserInfo({ ...userInfo, companyName: event.target.value });
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn secondary-btn mt-3 mb-2"
                onClick={handleSave}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </section>
    </body>
  );
};

export default ProfileSettings;
