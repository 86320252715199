import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchGroupAnalyze,
  getProjectConstructionsByConstructionId,
} from "../../../api/construction";
import ClipLoader from "react-spinners/ClipLoader";
import GroupStackedBarChartMaterial from "../../../components/Charts/StackedBarChart/GroupStackedBarChartMaterial";
import GroupPieChart from "../../../components/Charts/PieChart/GroupPieChart";
import GroupStackedBarChartFamily from "../../../components/Charts/StackedBarChart/GroupStackedBarChartFamily";
import GroupHorizontalBarChart from "../../../components/Charts/HorizontalBarChart/GroupHorizontalBarChart";
import { getProject } from "../../../api/projects";

import icon_1 from "./group-analyze-icon-1.png";
import icon_2 from "./group-analyze-icon-2.png";
import icon_3 from "./group-analyze-icon-3.png";
import icon_4 from "./group-analyze-icon-4.png";
import GroupExportReport from "./GroupAnalyzeReport";
import { getUserDetailed } from "../../../api/auth";

const TabBar = ({ tabs, activeTab, setActiveTab }) => (
  <ul className="nav nav-tabs custom-tabbar">
    {tabs.map(({ id, label }) => (
      <li className="nav-item" key={id}>
        <button
          className={`nav-link custom-nav-link ${activeTab === id ? 'active' : ''}`}
          onClick={() => setActiveTab(id)}
        >
          {label}
        </button>
      </li>
    ))}
  </ul>
);

const Dropdown = ({ options, activeOption, setActiveOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setActiveOption(option);
    setIsOpen(false);
  };
  return (
    <div className="dropdown">
      <button
        className="btn btn-outline-dark dropdown-toggle"
        onClick={toggleDropdown}
      >
        {options.find(option => option.id === activeOption).label}
      </button>
      {isOpen && options.map(({ id, label }) => (
        <button
          className="dropdown-item"
          onClick={() => handleSelect(id)}
        >{label}</button>
      ))}
    </div>
  );
}

export const GroupAnalyze = () => {
  const [user, setUser] = useState(null);
  const { projectId, constructionIds } = useParams();
  const [constructionsData, setConstructionsData] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [groupAnalyzeResult, setGroupAnalyzeResult] = useState(null);
  const [activeInfoTab, setActiveInfoTab] = useState('building-structure');
  const [activeBuildingTab, setActiveBuildingTab] = useState(0);
  const [activeStackedBarChartTab, setActiveStackedBarChartTab] = useState('stacked-bar-material');
  const [activeSecondaryStackedBarChartTab, setActiveSecondaryStackedBarChartTab] = useState('stacked-bar-material');
  const tree = 22;

  useEffect(() => {
    const fetchData = async () => {
      const constructionIdList = constructionIds.split(",");
      try {
        const userData = await getUserDetailed();
        setUser(userData);
        const constructionDataPromises = constructionIdList.map((id) =>
          getProjectConstructionsByConstructionId(projectId, id)
        );
        const constructions = await Promise.all(constructionDataPromises);
        const mappedConstructions = constructions.map(
          (response) => response.data
        );
        setConstructionsData(mappedConstructions);

        const projectResponse = await getProject(projectId);
        setProjectData(projectResponse.data);

        const groupAnalyzeResponse = await fetchGroupAnalyze(
          projectId,
          constructionIdList
        );
        setGroupAnalyzeResult(groupAnalyzeResponse.data);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    fetchData();
  }, [projectId, constructionIds]);

  if (constructionsData === null || projectData === null || groupAnalyzeResult === null) {
    return (
      <div className="container secondary-bg p-5">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <ClipLoader
            loading={true}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  const constructionInfoSection = (
    <>
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-between">
            <span className="secondary-text fw-bold">Building {projectData.projectName}: Building Structure</span>
            <Dropdown
              options={
                constructionsData.map((construction, id) => ({
                  id: id,
                  label: construction.name
                }))}
              activeOption={activeBuildingTab}
              setActiveOption={setActiveBuildingTab}
            />
          </div>
          {constructionsData && (
            <div>
              <div className="tab-content mt-3">
                {constructionsData.map((construction, idx) => (
                  <div
                    className={`tab-pane fade ${activeBuildingTab === idx ? "show active" : ""}`}
                    key={idx}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Family of Material</th>
                          <th scope="col">Material Name</th>
                          <th scope="col">Schedule(Unit)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {construction.materials.map((material, index) => (
                          <tr key={index}>
                            <td>{material.buildingComponent}</td>
                            <td>{material.type}</td>
                            <td>
                              {material.quantity} (
                              {material.bestGwpMaterialData.unit})
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const co2AnalyzeSection = (
    <>
      {/* Dropdown for Stacked Bar Charts */}
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-between">
            <span className="secondary-text fw-bold">Stacked Bar Charts</span>
            <div className="px-3"></div>
            <Dropdown
              options={[
                { id: 'stacked-bar-material', label: 'Stacked Bar Chart (Material)' },
                { id: 'stacked-bar-family', label: 'Stacked Bar Chart (Family)' },
              ]}
              activeOption={activeStackedBarChartTab}
              setActiveOption={setActiveStackedBarChartTab}
            />
          </div>

          {activeStackedBarChartTab === 'stacked-bar-material' && (
            <GroupStackedBarChartMaterial
              header={"CO2 Group Analyze"}
              data={groupAnalyzeResult.responses}
              dataType={"Co2"}
            />
          )}

          {activeStackedBarChartTab === 'stacked-bar-family' && (
            <GroupStackedBarChartFamily
              header={"CO2 Group Analyze"}
              data={groupAnalyzeResult.responses}
              dataType={"Co2"}
            />
          )}
        </div>
      </div>

      <div className="py-5"></div>
      {/* Dropdown for Pie and Horizontal Bar Charts */}
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-start">
            <span className="secondary-text fw-bold">Pie Charts</span>
          </div>
          <GroupPieChart
            data={groupAnalyzeResult.responses}
          />
        </div>
      </div>

      <div className="py-5"></div>
      {/* Dropdown for Pie and Horizontal Bar Charts */}
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-start">
            <span className="secondary-text fw-bold">Horizontal Bar Charts</span>
          </div>
          <GroupHorizontalBarChart
            data={groupAnalyzeResult.responses}
          />
        </div>
      </div>
      <div className="py-5"></div>
      <div className="container px-5 pb-3">
        <div className="bg-white rounded-4 p-5">
          <span className="secondary-text fw-bold mb-3">
            CO<sub>2</sub> Table
          </span>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Building Name</th>
                  <th colSpan="2" scope="col">Best</th>
                  <th colSpan="2" scope="col">Median</th>
                  <th colSpan="2" scope="col">Worst</th>
                </tr>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">CO2 Emission</th>
                  <th scope="col">Energy Efficiency</th>
                  <th scope="col">CO2 Emission</th>
                  <th scope="col">Energy Efficiency</th>
                  <th scope="col">CO2 Emission</th>
                  <th scope="col">Energy Efficiency</th>
                </tr>
              </thead>
              <tbody>
                {groupAnalyzeResult.responses.map((analyzeResult) => (
                  <tr key={analyzeResult.constructionName}>
                    <td>{analyzeResult.constructionName}</td>
                    {analyzeResult.groupAnalyzeResponses
                      .filter((analyze) => analyze.name.includes("Co2"))
                      .map((analyze, index) => (
                        <React.Fragment key={index}>
                          <td>{analyze.totalCo2Emission}</td>
                          <td>{analyze.totalEnergyEfficiency}</td>
                        </React.Fragment>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

  const energyEfficiencyAnalyzeSection = (
    <>
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-between">
            <span className="secondary-text fw-bold mb-3">Energy Efficiency Analyze</span>
            <Dropdown
              options={[
                { id: 'stacked-bar-material', label: 'Stacked Bar Chart (Material)' },
                { id: 'stacked-bar-family', label: 'Stacked Bar Chart (Family)' },
              ]}
              activeOption={activeSecondaryStackedBarChartTab}
              setActiveOption={setActiveSecondaryStackedBarChartTab}
            />
          </div>

          {activeSecondaryStackedBarChartTab === 'stacked-bar-material' && (
            <GroupStackedBarChartMaterial
              header={"Energy Efficiency Analyze"}
              data={groupAnalyzeResult.responses}
              dataType={"Energy"}
            />
          )}

          {activeSecondaryStackedBarChartTab === 'stacked-bar-family' && (
            <GroupStackedBarChartFamily
              header={"Energy Efficiency Analyze"}
              data={groupAnalyzeResult.responses}
              dataType={"Energy"}
            />
          )}
        </div>
      </div>
      <div className="py-5"></div>
      <div className="container px-5 pb-3">
        <div className="bg-white rounded-4 p-5">
          <span className="secondary-text fw-bold mb-3">Energy Efficiency Table</span>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Building Name</th>
                  <th colSpan="2" scope="col">Best</th>
                  <th colSpan="2" scope="col">Median</th>
                  <th colSpan="2" scope="col">Worst</th>
                </tr>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">CO2 Emission</th>
                  <th scope="col">Energy Efficiency</th>
                  <th scope="col">CO2 Emission</th>
                  <th scope="col">Energy Efficiency</th>
                  <th scope="col">CO2 Emission</th>
                  <th scope="col">Energy Efficiency</th>
                </tr>
              </thead>
              <tbody>
                {groupAnalyzeResult.responses.map((analyzeResult) => (
                  <tr key={analyzeResult.constructionName}>
                    <td>{analyzeResult.constructionName}</td>
                    {analyzeResult.groupAnalyzeResponses
                      .filter((analyze) => analyze.name.includes("Energy"))
                      .map((analyze, index) => (
                        <React.Fragment key={index}>
                          <td>{analyze.totalCo2Emission}</td>
                          <td>{analyze.totalEnergyEfficiency}</td>
                        </React.Fragment>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <body className="bg-body-tertiary">
        <section className="py-5">
          <div className="container p-5">
            <div className="d-flex justify-content-between py-5">
              <h2 className="secondary-text">Group Project Analyze</h2>
              {user && (user?.role.permissions?.includes(permission => permission.id === 11)
                || user?.subscriptionPlan?.subscriptionPlanInfo.permissions?.some(permission => permission.id === 11)) ? (<GroupExportReport data={groupAnalyzeResult} />) : (<></>)}

            </div>
            <TabBar
              tabs={[
                { id: 'building-structure', label: 'Building Structure' },
                { id: 'co2-analyze', label: 'CO₂ Analyze' },
                { id: 'energy-efficiency-analyze', label: 'Energy Efficiency Analayze' }
              ]}
              activeTab={activeInfoTab}
              setActiveTab={setActiveInfoTab}
            />
            <div className="py-3"></div>
            <div className="border rounded-4 p-3">
              <span className="secondary-text fw-bold">Project {projectData.projectName} Overview</span>
              <div className="d-flex justify-content-between py-3">
                <div style={{ width: "5px", background: "#127E87" }}></div>
                <img className="d-flex align-self-center" src={icon_1} height={"5%"} width={"5%"} />
                <div className="">
                  <p>Building Count</p>
                  <h5 className="fw-bold">{projectData.constructions.length}</h5>
                </div>
                <img className="d-flex align-self-center" src={icon_2} height={"5%"} width={"5%"} />
                <div className="">
                  <p>Material Count</p>
                  <h5 className="fw-bold">{projectData.constructions.reduce((sum, construction) => {
                    return sum + construction.materials.length
                  }, 0)}</h5>
                </div>
                <img className="d-flex align-self-center" src={icon_3} height={"5%"} width={"5%"} />
                <div className="">
                  <p>Total Schedule</p>
                  <h5 className="fw-bold">{projectData.totalArea}</h5>
                </div>
                <img className="d-flex align-self-center" src={icon_4} height={"5%"} width={"5%"} />
                <div className="">
                  <p>Number of Trees Saved</p>
                  <h5 className="fw-bold"> ≈ {Math.round((groupAnalyzeResult.responses[0].groupAnalyzeResponses[2].totalCo2Emission - groupAnalyzeResult.responses[0].groupAnalyzeResponses[0].totalCo2Emission
                    + groupAnalyzeResult.responses[1].groupAnalyzeResponses[2].totalCo2Emission - groupAnalyzeResult.responses[1].groupAnalyzeResponses[0].totalCo2Emission)
                    / tree)}</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {activeInfoTab === 'building-structure' && constructionInfoSection}
          {activeInfoTab === 'co2-analyze' && co2AnalyzeSection}
          {activeInfoTab === 'energy-efficiency-analyze' && energyEfficiencyAnalyzeSection}
        </section>
      </body>
    </>
  );
};
