import { useState } from "react";
import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./MaterialList.css";
import {
  deleteMaterialData,
  getMaterials,
  createMaterialData,
  updateMaterialData,
} from "../../../api/materialData";
import { unitFinder } from "../../../helper/unit";
import { NotificationService } from "../../../helper/notification";

export const MaterialList = () => {
  const INITIAL_FORM_DATA = {
    structureElement: "",
    type: "",
    model: "",
    brand: "",
    unit: "",
    thickness: 0,
    gwpValue: 0,
    lambdaValue: 0,
  };
  const [materials, setMaterials] = useState(null);
  const [filters, setFilters] = useState({
    structureElement: "",
    type: "",
    brand: "",
    model: "",
    unit: "",
    minThickness: "",
    maxThickness: "",
    minGwpValue: "",
    maxGwpValue: ""
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState(0);

  const getMaterialsList = async () => {
    try {
      const materialsData = await getMaterials();
      const data = materialsData.data;
      setMaterials(data);
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[field] = value;
      return updatedFormData;
    });
  };

  useEffect(() => {
    getMaterialsList();
  }, []);

  const filteredMaterials = (materials || []).filter(item => {
    return (
      (filters.structureElement ? item.structureElement.toLowerCase().includes(filters.structureElement.toLowerCase()) : true) &&
      (filters.type ? item.type.toLowerCase().includes(filters.type.toLowerCase()) : true) &&
      (filters.brand ? item.brand.toLowerCase().includes(filters.brand.toLowerCase()) : true) &&
      (filters.model ? item.model.toLowerCase().includes(filters.model.toLowerCase()) : true) &&
      (filters.unit ? item.unit.toLowerCase().includes(filters.unit.toLowerCase()) : true) &&
      (filters.minThickness ? item.thickness >= filters.minThickness : true) &&
      (filters.maxThickness ? item.thickness <= filters.maxThickness : true) &&
      (filters.minGwpValue ? item.gwpValue >= filters.minGwpValue : true) &&
      (filters.maxGwpValue ? item.gwpValue <= filters.maxGwpValue : true) &&
      (filters.minLambdaValue ? item.lambdaValue >= filters.minLambdaValue : true) &&
      (filters.maxLambdaValue ? item.lambdaValue <= filters.maxLambdaValue : true)

    );
  });

  const sortedMaterials = [...filteredMaterials].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const openAddModal = () => {
    setIsAddModalOpen(true);
    setFormData({
      structureElement: "",
      type: "",
      model: "",
      brand: "",
      unit: "",
      thickness: 0,
      gwpValue: 0,
      lambdaValue: 0,
    });
  };
  const openDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setUpdateId(id);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const openUpdateModal = (id) => {
    setIsUpdateModalOpen(true);
    setUpdateId(id);
    setFormData(sortedMaterials[id]);
  };
  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
    setFormData(INITIAL_FORM_DATA);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createMaterialData(formData);
      NotificationService.success("You have successfully add the material");
      await getMaterialsList();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
    setIsAddModalOpen(false);
  };

  const handleUpdate = async (id, e) => {
    e.preventDefault();
    try {
      await updateMaterialData(id, formData);
      NotificationService.success("You have successfully edit the material");
      await getMaterialsList();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
    setIsUpdateModalOpen(false);
  };

  const handleDelete = async (id, e) => {
    e.preventDefault();

    try {
      await deleteMaterialData(id);
      NotificationService.success("You have successfully deleted the material");
      await getMaterialsList();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
    setIsDeleteModalOpen(false);
  };
  const isSaveButtonDisabled = () => {
    return Object.values(formData).includes("");
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <section>
      <div className="py-5"></div>
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h2 className="text-dark">Material List</h2>
        </div>
        <div className="projects">
          {materials == null ? (
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <ClipLoader
                loading={materials === null}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : materials.length === 0 ? (
            <div className="alert alert-info" role="alert">
              We do not have materials yet!
            </div>
          ) : (
            <div>
              <div className="d-flex flex-row align-items-center justify-content-end mb-4">
                <button
                  type="button"
                  className="btn secondary-btn fw-bold mx-1 my-3"
                  onClick={openAddModal}
                >
                  Add New Material
                </button>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex align-self-center pe-2">
                  <input
                    className="form-control"
                    type="text"
                    name="structureElement"
                    placeholder="Structure Element"
                    value={filters.structureElement}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="d-flex align-self-center pe-2">
                  <input
                    className="form-control"
                    type="text"
                    name="type"
                    placeholder="Type"
                    value={filters.type}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="d-flex align-self-center pe-2">
                  <input
                    className="form-control"
                    type="text"
                    name="model"
                    placeholder="Model"
                    value={filters.model}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="d-flex align-self-center pe-2">
                  <input
                    className="form-control"
                    type="text"
                    name="brand"
                    placeholder="Brand"
                    value={filters.brand}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="d-flex align-self-center pe-2">
                  <select
                    className="form-control"
                    type="text"
                    name="unit"
                    placeholder="Unit"
                    value={filters.unit}
                    onChange={handleFilterChange}
                  >
                    <option value="">Unit</option>
                    <option value="m2">㎡</option>
                    <option value="m3">㎥</option>
                    <option value="kg">kg</option>
                  </select>
                </div>
                <div className="pe-2">
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="minThickness"
                    placeholder="Min Thickness"
                    value={filters.minThickness}
                    onChange={handleFilterChange}
                  />
                  <input
                    className="form-control"
                    type="number"
                    name="maxThickness"
                    placeholder="Max Thickness"
                    value={filters.maxThickness}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="pe-2">
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="minGwpValue"
                    placeholder="Min GWP Value"
                    value={filters.minGwpValue}
                    onChange={handleFilterChange}
                  />
                  <input
                    className="form-control"
                    type="number"
                    name="maxGwpValue"
                    placeholder="Max GWP Value"
                    value={filters.maxGwpValue}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="pe-2">
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="minLambdaValue"
                    placeholder="Min Lambda Value"
                    value={filters.minLambdaValue}
                    onChange={handleFilterChange}
                  />
                  <input
                    className="form-control"
                    type="number"
                    name="maxLambdaValue"
                    placeholder="Max Lambda Value"
                    value={filters.maxLambdaValue}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th onClick={() => requestSort('structureElement')} scope="col">Type of Material</th>
                    <th onClick={() => requestSort('type')} scope="col">Material Name</th>
                    <th onClick={() => requestSort('model')} scope="col">Model</th>
                    <th onClick={() => requestSort('brand')} scope="col">Brand</th>
                    <th onClick={() => requestSort('unit')} scope="col">Unit</th>
                    <th onClick={() => requestSort('thickness')} scope="col">Thickness</th>
                    <th onClick={() => requestSort('gwpValue')} scope="col">Gwp Value</th>
                    <th onClick={() => requestSort('lambdaValue')} scope="col">Lambda value</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedMaterials.map((material, index) => (
                    <tr id={index}>
                      <th>{index + 1}</th>
                      <td>{material.structureElement}</td>
                      <td>{material.type}</td>
                      <td>{material.model}</td>
                      <td>{material.brand}</td>
                      {unitFinder(material.unit)}
                      <td>{material.thickness}</td>
                      <td>{material.gwpValue}</td>
                      <td>{material.lambdaValue}</td>
                      <td className="d-flex justify-content-between">
                        <i
                          onClick={() => openUpdateModal(index)}
                          className="bi bi-pencil-square text-success"
                        ></i>
                        <i
                          onClick={() => openDeleteModal(index)}
                          className="bi bi-trash text-danger"
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {isDeleteModalOpen && (
            <div className="modal fade show d-block" tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Delete{" "}
                      {`${sortedMaterials[updateId].type} (${sortedMaterials[updateId].model})`}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setIsDeleteModalOpen(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete this material?</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setIsDeleteModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={(e) =>
                        handleDelete(sortedMaterials[updateId].id, e)
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isAddModalOpen && (
            <div
              className="modal fade show d-block"
              id="addMaterialModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addMaterialModalLabel"
              aria-hidden="true"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: "900px" }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="addMaterialModalLabel">
                      Create Material
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={closeAddModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="container mt-2">
                      <div className="row mb-3">
                        <div>
                          <label
                            htmlFor="structureElement"
                            className="form-label"
                          >
                            Type Of Material
                          </label>
                          <input
                            id="structureElement"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.structureElement}
                            onChange={(event) => {
                              handleInputChange(
                                "structureElement",
                                event.target.value
                              );
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="type" className="form-label">
                            Material Name
                          </label>
                          <input
                            id="type"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.type}
                            onChange={(event) => {
                              handleInputChange("type", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="model" className="form-label">
                            Model
                          </label>
                          <input
                            id="model"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.model}
                            onChange={(event) => {
                              handleInputChange("model", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="brand" className="form-label">
                            Brand
                          </label>
                          <input
                            id="brand"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.brand}
                            onChange={(event) => {
                              handleInputChange("brand", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="unit" className="form-label">
                            Unit
                          </label>
                          <select
                            id="unit"
                            className="form-control shadow-none mb-1"
                            value={formData.unit}
                            onChange={(event) => {
                              handleInputChange("unit", event.target.value);
                            }}
                          >
                            <option value="kg">kg</option>
                            <option value="m2">m²</option>
                            <option value="m3">m³</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="thickness" className="form-label">
                            Thickness
                          </label>
                          <input
                            id="thickness"
                            type="number"
                            className="form-control shadow-none mb-1"
                            value={formData.thickness}
                            onChange={(event) => {
                              handleInputChange("thickness", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="gwpValue" className="form-label">
                            Gwp Value
                          </label>
                          <input
                            id="gwpValue"
                            type="number"
                            className="form-control shadow-none mb-1"
                            value={formData.gwpValue}
                            onChange={(event) => {
                              handleInputChange("gwpValue", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="lambdaValue" className="form-label">
                            Lambda value
                          </label>
                          <input
                            id="lambdaValue"
                            type="number"
                            className="form-control shadow-none"
                            value={formData.lambdaValue}
                            onChange={(event) => {
                              handleInputChange(
                                "lambdaValue",
                                event.target.value
                              );
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeAddModal}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn secondary-btn"
                        disabled={isSaveButtonDisabled()}
                        onClick={handleSubmit}
                      >
                        Create Material
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isUpdateModalOpen && (
            <div
              className="modal fade show d-block"
              id="updateMaterialModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="updateMaterialModalLabel"
              aria-hidden="true"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: "900px" }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5"
                      id="updateMaterialModalLabel"
                    >
                      Update{" "}
                      {`${sortedMaterials[updateId].type} (${sortedMaterials[updateId].model})`}
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={closeUpdateModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="container mt-2">
                      <div className="row mb-3">
                        <div>
                          <label
                            htmlFor="structureElement"
                            className="form-label"
                          >
                            Type Of Material
                          </label>
                          <input
                            id="structureElement"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.structureElement}
                            onChange={(event) => {
                              handleInputChange(
                                "structureElement",
                                event.target.value
                              );
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="type" className="form-label">
                            Material Name
                          </label>
                          <input
                            id="type"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.type}
                            onChange={(event) => {
                              handleInputChange("type", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="model" className="form-label">
                            Model
                          </label>
                          <input
                            id="model"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.model}
                            onChange={(event) => {
                              handleInputChange("model", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="brand" className="form-label">
                            Brand
                          </label>
                          <input
                            id="brand"
                            type="text"
                            className="form-control shadow-none mb-1"
                            value={formData.brand}
                            onChange={(event) => {
                              handleInputChange("brand", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="unit" className="form-label">
                            Unit
                          </label>
                          <select
                            id="unit"
                            className="form-control shadow-none mb-1"
                            value={formData.unit}
                            onChange={(event) => {
                              handleInputChange("unit", event.target.value);
                            }}
                          >
                            <option value="kg">kg</option>
                            <option value="m2">m²</option>
                            <option value="m3">m³</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="thickness" className="form-label">
                            Thickness
                          </label>
                          <input
                            id="thickness"
                            type="number"
                            className="form-control shadow-none mb-1"
                            value={formData.thickness}
                            onChange={(event) => {
                              handleInputChange("thickness", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="gwpValue" className="form-label">
                            Gwp Value
                          </label>
                          <input
                            id="gwpValue"
                            type="number"
                            className="form-control shadow-none mb-1"
                            value={formData.gwpValue}
                            onChange={(event) => {
                              handleInputChange("gwpValue", event.target.value);
                              event.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="lambdaValue" className="form-label">
                            Lambda value
                          </label>
                          <input
                            id="lambdaValue"
                            type="number"
                            className="form-control shadow-none"
                            value={formData.lambdaValue}
                            onChange={(event) => {
                              handleInputChange(
                                "lambdaValue",
                                event.target.value
                              );
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeUpdateModal}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn secondary-btn"
                        disabled={isSaveButtonDisabled()}
                        onClick={(e) =>
                          handleUpdate(sortedMaterials[updateId].id, e)
                        }
                      >
                        Update Material
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
