export const schemaBuilder = (typeOfMaterials) => ({
  "Type Of Material": {
    prop: "buildingComponent",
    type: String,
    required: true,
    oneOf: typeOfMaterials,
  },
  "Material Name": {
    prop: "type",
    type: String,
    required: true,
  },
  Schedule: {
    prop: "quantity",
    type: Number,
    required: true,
  },
});
