import React, { useRef, useEffect, useState } from "react";
import readXlsxFile from "read-excel-file";
import { schemaBuilder } from "./excelSchema";
import { NotificationService } from "../../helper/notification";
import { EXCEL_FILE_BASE_64 } from "./constants";
import FileSaver from "file-saver";

const SubmitDataButton = ({ materialData, setData }) => {
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const schema = schemaBuilder(Object.keys(materialData));

  const downloadExcelFile = () => {
    const sliceSize = 1024;
    const byteCharacters = atob(EXCEL_FILE_BASE_64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample-excel.xlsx"
    );
  };

  useEffect(() => {
    const input = document.getElementById("input");

    const handleFileChange = () => {
      if (input.files[0]) {
        setFilename(input.files[0].name);
        readXlsxFile(input.files[0], { schema }).then((rows) => {
          console.log(rows);
          if (rows.errors.length > 0) {
            NotificationService.error(
              "An error occurred while importing excel. Please check the format!"
            );
            return;
          } else {
            const data = rows.rows;
            setData(data);
          }
        });
      }
    };

    input.addEventListener("change", handleFileChange);

    return () => {
      input.removeEventListener("change", handleFileChange);
    };
  }, []);

  return (
    <div>
      <input
        type="file"
        id="input"
        style={{ display: "none" }}
        accept=".xlsx, .xls"
        ref={fileInputRef}
      />

      <div>
        <button
          type="button"
          className="btn btn-outline-dark me-2"
          onClick={handleFileInputClick}
        >
          Upload Data
          &nbsp;<i className="bi bi-file-earmark-arrow-up-fill" />
        </button>

        <button
          type="button"
          className="btn btn-outline-dark ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="auto"
          title={`You can download sample excel from here`}
          onClick={downloadExcelFile}
        >

          Download Sample Excel
          &nbsp;<i className="bi bi-file-earmark-arrow-down-fill" />
        </button>
      </div>
      {
        filename &&
        <div className="container d-flex justify-content-between align-items-center border rounded m-1 p-1 mt-3">
          <p className="mt-0 mb-0 ms-1">Uploaded file: {filename}</p>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => setFilename()}
          >
            <i className="bi bi-trash" />
          </button></ div>
      }
    </div >
  );
};

export default SubmitDataButton;
