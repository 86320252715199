import { useState } from "react";
import "./Background.css";
import { Banner } from "../../../components/LandingPageComponents/Banner/Banner";
import { InsideAction } from "../../../components/LandingPageComponents/InsideAction/InsideAction";
import {useTranslation} from "react-i18next";

const Contact = () => {
  const [error, setError] = useState(null);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleContact = () => {
    // contact logic
  };

  const {t, i18n} = useTranslation();


  return (
      <>
        <section>
          <Banner page={"contact"} />
        </section>
        <section className="py-5">
          <div>
            <div className="row w-100 d-flex justify-content-around">
              <div className="col-md-4 px-5 mb-5">
                <h4 className="secondary-text">{t('contactt.getInTouch')}</h4>
                <h2 className="pt-3">{t('contactt.sendUsMessage')}</h2>

                <div className="d-flex justify-content-between p-2 py-3" style={{ background: "#E9F1EE" }}>
                  <input type="text" className="border-0 pe-5" placeholder={t('contactt.yourEmail')} style={{ background: "transparent" }} />
                  <button className="secondary-btn ms-2 py-2">
              <span className="d-flex align-items-center px-3">
                {t('contactt.subscribe')} &nbsp; &nbsp;<i className="bi bi-arrow-right"></i>
              </span>
                  </button>
                </div>
              </div>
              <div className="col-md-5 px-5">
                <div>
                  {error && <div className="alert alert-danger">{error}</div>}

                  <div className="form-group">
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">{t('contactt.fullName')}</label>
                      <input
                          type="text"
                          className="form-control border-0 rounder-0 py-3"
                          style={{background: "#E9F1EE"}}
                          id="fullName"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">{t('contactt.yourJob')}</label>
                      <input
                          type="text"
                          className="form-control border-0 rounder-0 py-3"
                          style={{background: "#E9F1EE"}}
                          id="fullName"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">{t('contactt.email')}</label>
                      <input
                          type="email"
                          className="form-control border-0 rounder-0 py-3"
                          style={{background: "#E9F1EE"}}
                          id="email"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">{t('contactt.message')}</label>
                      <textarea
                          className="form-control border-0 rounder-0"
                          style={{background: "#E9F1EE"}}
                          id="message"
                          rows="5"
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check"/>
                        <label className="form-check-label" htmlFor="check">
                          {t('contactt.acceptTerms')}
                        </label>
                      </div>
                      <button className="secondary-btn ms-2 py-2">
                  <span className="d-flex align-items-center px-3">
                    {t('contactt.subscribe')} &nbsp; &nbsp;<i className="bi bi-arrow-right"></i>
                  </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="d-flex justify-content-center">
            <div className="col-9">
              <h2 className="text-center pb-5">{t('contactt.faqTitle')}</h2>
              {/*<div className="rounded-4 my-4 p-4" style={{background: "#F6F6F6"}}>*/}
              {/*  <div*/}
              {/*      className="clickable d-flex justify-content-between align-items-center"*/}
              {/*      onClick={() => setShow1(!show1)}*/}
              {/*  >*/}
              {/*    <h5>{t('contactt.faq.question1')}</h5>*/}
              {/*    <h5><i className={`bi text-dark ${show1 ? 'bi-dash-circle' : 'bi-plus-circle'}`} /></h5>*/}
              {/*  </div>*/}
              {/*  {show1 && (*/}
              {/*      <p className="pt-3">{t('contactt.faq.answer1')}</p>*/}
              {/*  )}*/}
              {/*</div>*/}
              {/*<div className="rounded-4 my-4 p-4" style={{ background: "#F6F6F6" }}>*/}
              {/*  <div*/}
              {/*      className="clickable d-flex justify-content-between align-items-center"*/}
              {/*      onClick={() => setShow2(!show2)}*/}
              {/*  >*/}
              {/*    <h5>{t('contactt.faq.question2')}</h5>*/}
              {/*    <h5><i className={`bi text-dark ${show2 ? 'bi-dash-circle' : 'bi-plus-circle'}`} /></h5>*/}
              {/*  </div>*/}
              {/*  {show2 && (*/}
              {/*      <p className="pt-3">{t('contactt.faq.answer2')}</p>*/}
              {/*  )}*/}
              {/*</div>*/}
              <div className="rounded-4 my-4 p-4" style={{ background: "#F6F6F6" }}>
                <div
                    className="clickable d-flex justify-content-between align-items-center"
                    onClick={() => setShow3(!show3)}
                >
                  <h5>{t('contactt.faq.question3')}</h5>
                  <h5><i className={`bi text-dark ${show3 ? 'bi-dash-circle' : 'bi-plus-circle'}`} /></h5>
                </div>
                {show3 && (
                    <p className="pt-3">{t('contactt.faq.answer3')}</p>
                )}
              </div>
              <div className="rounded-4 my-4 p-4" style={{ background: "#F6F6F6" }}>
                <div
                    className="clickable d-flex justify-content-between align-items-center"
                    onClick={() => setShow4(!show4)}
                >
                  <h5>{t('contactt.faq.question4')}</h5>
                  <h5><i className={`bi text-dark ${show4 ? 'bi-dash-circle' : 'bi-plus-circle'}`} /></h5>
                </div>
                {show4 && (
                    <p className="pt-3">{t('contactt.faq.answer4')}</p>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <InsideAction />
        </section>
      </>
  );
};

export default Contact;
