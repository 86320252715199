import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const GroupStackedBarChartMaterial = ({ data, header, dataType }) => {
    const chartRef = useRef(null);
    const groupOrder = ["best", "median", "worst"];

    const labels = groupOrder.flatMap(group =>
        data.flatMap(analyzeResult =>
            analyzeResult.groupAnalyzeResponses
                .filter(elem => elem.name.includes(dataType) && elem.name.toLowerCase().includes(group))
                .map(item => analyzeResult.constructionName + " " + item.name)
        )
    );

    const uniqueTypes = [
        ...new Set(
            data.flatMap((analyzeResult) =>
                analyzeResult.groupAnalyzeResponses.filter((elem) =>
                    elem.name.includes(dataType))).flatMap((group) =>
                        group.buildingComponentAnalyzeResponses.flatMap((se) =>
                            se.typeAnalyzeResponses.map((typeResp) => typeResp.type)
                        )
                    )
        ),
    ];

    const barDatasets = uniqueTypes.map((type, typeIndex) => {
        return {
            type: "bar",
            label: `${type} CO2 Emission (kgCO2-eq)`,
            data: groupOrder.flatMap(group =>
                data.flatMap(analyzeResult =>
                    analyzeResult.groupAnalyzeResponses
                        .filter(elem => elem.name.includes(dataType) && elem.name.toLowerCase().includes(group))
                        .map(group =>
                            group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
                                const typeData = se.typeAnalyzeResponses.find(
                                    (tr) => tr.type === type
                                );
                                return sum + (typeData ? typeData.totalCo2Emission : 0);
                            }, 0)
                        )
                )
            ),
            backgroundColor: `rgba(${(typeIndex * 50) % 255}, ${(typeIndex * 80) % 255}, ${(typeIndex * 100) % 255}, 0.5)`,
            yAxisID: "y1",
        };
    });

    const totalEnergyEfficiency = data.flatMap(analyzeResult =>
        analyzeResult.groupAnalyzeResponses
            .filter(elem => elem.name.includes(dataType))
            .map(group => group.totalEnergyEfficiency)
    );

    const lineDatasets = groupOrder.map((group, index) => {
        const groupData = data.flatMap(analyzeResult =>
            analyzeResult.groupAnalyzeResponses
                .filter(elem => elem.name.includes(dataType) && elem.name.toLowerCase().includes(group))
                .map(group => group.totalEnergyEfficiency)
        );

        const groupDataWithNulls = new Array(labels.length).fill(null);
        let labelIndex = 0;
        groupOrder.forEach((g, i) => {
            if (g === group) {
                for (let j = 0; j < data.length; j++) {
                    groupDataWithNulls[labelIndex + j] = groupData[j];
                }
            }
            labelIndex += data.length;
        });

        return {
            type: "line",
            label: `${group.charAt(0).toUpperCase() + group.slice(1)} Energy Efficiency (W/m2K)`,
            data: groupDataWithNulls,
            borderColor: "rgba(0, 0, 255, 1)",
            backgroundColor: "rgba(0, 0, 255, 0.5)",
            fill: false,
            yAxisID: "y2",
        };
    });

    const chartData = {
        labels,
        datasets: [...barDatasets, ...lineDatasets],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: header || "Combined Bar and Line Chart",
            },
            tooltip: {
                mode: "index",
                intersect: false,
            },
            legend: {
                position: "top",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y1: {
                type: "linear",
                display: true,
                position: "left",
                stacked: true,
                title: {
                    display: true,
                    text: "CO2 Emission (kgCO2-eq)",
                },
            },
            y2: {
                type: "linear",
                display: true,
                position: "right",
                stacked: false,
                title: {
                    display: true,
                    text: "Total Energy Efficiency (W/m2K)",
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    const handleExport = () => {
        const chart = chartRef.current;
        if (chart) {
            const url = chart.toBase64Image();
            const link = document.createElement('a');
            link.href = url;
            link.download = 'GroupStackedBarChartMaterial.png';
            link.click();
        }
    };

    return (
        <>
            <div className="d-flex justify-content-end pt-3">
                <button className="btn btn-outline-dark" onClick={handleExport}>
                    <i className="bi bi-box-arrow-right" /> &nbsp; Export Graph
                </button>
            </div>
            <Bar ref={chartRef} data={chartData} options={options} style={{ marginBottom: "50px" }} />
        </>
    );
};

export default GroupStackedBarChartMaterial;
