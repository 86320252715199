import instance from "./baseInstance";

export async function getMaterials() {
  const response = await instance.get("material-data/find-all");

  return response;
}

export async function getMaterialDataInfo() {
  const response = await instance.get("material-data/get-info");

  return response;
}

export async function createMaterialData(jsonData) {
  await instance.post(`material-data/create`, jsonData);
}

export async function createMaterialDataList(jsonData) {
  await instance.post(`material-data/create-list`, jsonData);
}

export async function updateMaterialData(id, jsonData) {
  await instance.put(`material-data/update?id=${id}`, jsonData);
}

export async function deleteMaterialData(id) {
  await instance.delete(`material-data/delete?id=${id}`);
}

export async function generateMaterialListFromCsv(jsonData) {
  const response = await instance.post("materials/generate-material-list-from-csv", jsonData);
  return response.data;
}
