import { useState } from "react";
import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationService } from "../../../helper/notification";
import {
  listAcceptedRequests,
  listClosedRequests,
  listOpenRequests,
  listRejectedRequests,
  acceptRequest,
  rejectRequest,
} from "../../../api/admin/dataRequest";
import { unitFinder } from "../../../helper/unit";

const DataRequest = () => {
  const [requests, setRequests] = useState(null);
  const [selectedBadge, setSelectedBadge] = useState("open");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const badgeData = [
    { name: "open", label: "Open" },
    { name: "closed", label: "Closed" },
    { name: "accepted", label: "Accepted" },
    { name: "rejected", label: "Rejected" },
  ];

  const getOpenedRequests = async () => {
    try {
      setRequests(await listOpenRequests());
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const getCloseRequests = async () => {
    try {
      setRequests(await listClosedRequests());
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const getAcceptedRequests = async () => {
    try {
      setRequests(await listAcceptedRequests());
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const getRejectedRequests = async () => {
    try {
      setRequests(await listRejectedRequests());
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const handleAcceptRequest = async (requestId) => {
    try {
      await acceptRequest(requestId);
      NotificationService.success("Request is accepted");
      handleBadgeClick(selectedBadge);
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };
  const handleRejectRequest = async (requestId) => {
    try {
      await rejectRequest(requestId);
      NotificationService.success("Request is rejected");
      handleBadgeClick(selectedBadge);
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const handleBadgeClick = (badge) => {
    setRequests(null);
    setSelectedBadge(badge);

    switch (badge) {
      case "open":
        getOpenedRequests();
        break;
      case "closed":
        getCloseRequests();
        break;
      case "accepted":
        getAcceptedRequests();
        break;
      case "rejected":
        getRejectedRequests();
        break;
      default:
        getOpenedRequests();
    }
  };

  useEffect(() => {
    getOpenedRequests();
  }, []);

  const handlePreview = (data) => {
    setPreviewData(data);
    setIsPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setPreviewData(null);
    setIsPreviewModalOpen(false);
  };

  return (
    <body className="bg-body-tertiary">
      <div className="py-5"></div>
      <div className="container mt-5">
        <h2 className="tertiary-text">Data Requests</h2>
        <div className="d-flex align-items-center mt-3 mb-3">
          {badgeData.map((badge) => (
            <span
              key={badge.name}
              className={`badge badge-pill ${selectedBadge === badge.name ? "secondary-btn" : "bg-secondary"
                }`}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => handleBadgeClick(badge.name)}
            >
              {badge.label}
            </span>
          ))}
        </div>

        <div className="container bg-white rounded-4 p-3 mb-3">
          {requests == null ? (
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <ClipLoader
                loading={requests === null}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : requests.length === 0 ? (
            <div className="alert alert-info mt-5" role="alert">
              There is no requests with the given condition!
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table mt-5">
                <thead>
                  <tr>
                    <th scope="col">Request Id</th>
                    <th scope="col">Username</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Data</th>
                    {selectedBadge === "open" && <th scope="col">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {requests.map((request) => (
                    <tr>
                      <th>{request.id}</th>
                      <td>{request.username}</td>
                      <td>{request.createdDate}</td>
                      <td
                        onClick={() =>
                          handlePreview(
                            request.createMaterialDataRequest.materialDataValue
                          )
                        }
                      >
                        <a href="#" className="secondary-text pe-auto">
                          Preview
                        </a>
                      </td>
                      {selectedBadge === "open" && (
                        <td className="d-flex justify-content-around">
                          <i
                            onClick={() => {
                              handleAcceptRequest(request.id);
                            }}
                            class="bi bi-check-lg"
                          ></i>

                          <i
                            onClick={() => {
                              handleRejectRequest(request.id);
                            }}
                            class="bi bi-dash-lg"
                          ></i>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {isPreviewModalOpen && (
            <div
              className="modal fade show d-block"
              id="previewDataModal"
              tabIndex="-1"
              aria-labelledby="previewDataModalLabel"
              aria-hidden="true"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="previewDataModalLabel">
                      Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={closePreviewModal}
                    ></button>
                  </div>

                  <div className="modal-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Type of Material</th>
                          <th scope="col">Material Name</th>
                          <th scope="col">Model</th>
                          <th scope="col">Brand</th>
                          <th scope="col">Unit</th>
                          <th scope="col">Thickness</th>
                          <th scope="col">Gwp Value</th>
                          <th scope="col">Lambda value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{previewData.structureElement}</td>
                          <td>{previewData.type}</td>
                          <td>{previewData.model}</td>
                          <td>{previewData.brand}</td>
                          {unitFinder(previewData.unit)}
                          <td>{previewData.thickness}</td>
                          <td>{previewData.gwpValue}</td>
                          <td>{previewData.lambdaValue}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </body>
  );
};

export default DataRequest;
