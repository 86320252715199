import instance from "../baseInstance";

export async function getRoleById(pathVariable) {
    const response = await instance.get("roles/find/" + pathVariable);

    return response.data;
}

export async function getAllRoles() {
    const response = await instance.get("roles/find-all");

    return response.data;
}

export async function updateRoleById(pathVariable, jsonData) {
    const response = await instance.put("roles/update/" + pathVariable, jsonData);

    return response;
}

export async function deleteRoleById(pathVariable) {
    const response = await instance.delete("roles/delete/" + pathVariable);

    return response;
}

export async function addRoleById(jsonData) {
    const response = await instance.post("roles/create", jsonData);

    return response;
}