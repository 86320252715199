import React, { useEffect, useState } from 'react';
import RoleSelection from './RoleSelection';
import Payment from './Payment';
import SubscriptionSelection from './SubscriptionSelection';
import { getUserDetailed, setMyRole } from '../../api/auth';
import { NotificationService } from '../../helper/notification';
import { purchaseSubscriptionPlan } from '../../api/purchase';
import ClipLoader from 'react-spinners/ClipLoader';

const MultiStepForm = ({ onCompletion, onProcess }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        role: null,
        plan: null,
        creditCardInfo: {
            cardNumber: "",
            userName: "",
            lastUsageDate: "",
            csv: ""
        }
    });
    const [role, setRole] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const info = await getUserDetailed();
                setRole(info.role !== null ? info.role.id : null);
                setSubscriptionPlan(info.subscriptionPlan !== null ? info.subscriptionPlan.subscriptionPlanInfo : null);
            } catch (error) {
                NotificationService.error("An error occurred");
            } finally {
                setIsLoading(false);
                onProcess()
            }
        };

        getUserInfo();
    }, []);

    const nextStep = (data) => {
        setFormData({ ...formData, ...data });
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const handlePaymentSuccess = async (data) => {
        setFormData({ ...formData, ...data });
        if (role === null) {
            await setMyRole(formData.role);
        }
        if (subscriptionPlan === null) {
            await purchaseSubscriptionPlan(formData.plan.id, formData.creditCardInfo);
        }

        onCompletion();
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <ClipLoader aria-label="Loading Spinner" data-testid="loader" isLoading={isLoading} />
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <div className="shadow-sm p-4">
                {step === 1 && <RoleSelection hasRole={role} nextStep={nextStep} />}
                {step === 2 && (
                    <SubscriptionSelection hasPlan={subscriptionPlan} roleSelection={formData.role} nextStep={nextStep} previousStep={previousStep} />
                )}
                {step === 3 && (
                    <Payment
                        role={formData.role}
                        plan={formData.plan.id}
                        isFree={formData.plan.name === "FREE" || subscriptionPlan !== null}
                        isAdd={true}
                        onSuccess={handlePaymentSuccess}
                        previousStep={previousStep}
                    />
                )}
            </div>
        </div>
    );
};

export default MultiStepForm;
