//Created by Arda Kabadayı
//This method provides checking auth token safely to find out user is logged-in.
export const isAuthenticated = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
};

//Created by Halit Kaan Kaçar
//For getting user email from local storage safely. Afterward removing it.
export const getEmail = () => {
  if (localStorage.getItem("email") != null) {
    return localStorage.getItem("email");
  }
}

//Created by Halit Kaan Kaçar
//For getting user password from local storage safely. Afterward removing it.
export const getPassword = () => {
  if (localStorage.getItem("password") != null) {
    return localStorage.getItem("password");
  }
}

//Created by Halit Kaan Kaçar
//For getting user simple information from local storage safely. Afterward removing it.
export const getUserInfo = () => {
  if (localStorage.getItem("userInfo") != null) {
    return JSON.parse(localStorage.getItem("userInfo"));
  }
}

//Created by Halit Kaan Kaçar
//For getting user role selection before login from local storage safely. Afterward removing it.
export const getRoleSelection = () => {
  if (localStorage.getItem("roleSelection") != null) {
    return localStorage.getItem("roleSelection");
  }
}

//Created by Halit Kaan Kaçar 
//For getting user subscription plan selection before login from local storage safely. Afterward removing it.
export const getSubscriptionPlanSelection = () => {
  if (localStorage.getItem("subscriptionPlanSelection") != null) {
    return localStorage.getItem("subscriptionPlanSelection");
  }
}
