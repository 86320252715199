import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChartFamily = ({ data, header }) => {
  const chartRef = useRef(null);
  const labels = data.map((item) => item.name);

  const uniqueTypes = [
    ...new Set(
      data.flatMap((group) =>
        group.buildingComponentAnalyzeResponses.flatMap((se) =>
          se.buildingComponent
        )
      )
    ),
  ];

  const barDatasets = uniqueTypes.map((type, typeIndex) => {
    return {
      type: "bar",
      label: `${type} CO2 Emission (kgCO2-eq)`,
      data: data.map((group) =>
        group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
          const typeData = se.buildingComponent;
          return sum + (typeData ? se.totalCo2Emission : 0);
        }, 0)
      ),
      backgroundColor: `rgba(${(typeIndex * 50) % 255}, ${(typeIndex * 80) % 255}, ${(typeIndex * 100) % 255}, 0.5)`,
      yAxisID: "y1",
    };
  });

  const totalEnergyEfficiency = data.map((group) => {
    const totalEfficiency = group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
      return (
        sum +
        se.typeAnalyzeResponses.reduce((typeSum, tr) => {
          return typeSum + (1 / (tr.totalEnergyEfficiency) || 0);
        }, 0)
      );
    }, 0);
    return totalEfficiency !== 0 ? 1 / totalEfficiency : 0;
  });


  const lineDataset = {
    type: "line",
    label: "Total Energy Efficiency (W/m2K)",
    data: totalEnergyEfficiency,
    borderColor: "rgba(0, 0, 255, 1)",
    backgroundColor: "rgba(0, 0, 255, 0.5)",
    fill: false,
    yAxisID: "y2",
  };

  const chartData = {
    labels,
    datasets: [...barDatasets, lineDataset],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: header || "Combined Bar and Line Chart",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        position: "top",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y1: {
        type: "linear",
        display: true,
        position: "left",
        stacked: true,
        title: {
          display: true,
          text: "CO2 Emission (kgCO2-eq)",
        },
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        stacked: false,
        title: {
          display: true,
          text: "Total Energy Efficiency (W/m2K)",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const handleExport = () => {
    const chart = chartRef.current;
    if (chart) {
      const url = chart.toBase64Image();
      const link = document.createElement('a');
      link.href = url;
      link.download = 'StackedBarChartFamily.png';
      link.click();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end pt-3">
        <button className="btn btn-outline-dark" onClick={handleExport}>
          <i className="bi bi-box-arrow-right" /> &nbsp; Export Graph
        </button>
      </div>
      <Bar ref={chartRef} data={chartData} options={options} style={{ marginBottom: "50px" }} />
    </>
  );
};

export default StackedBarChartFamily;
