import instance from "../baseInstance";

export async function getAllSupportRequests(query1, query2) {
    const response = await instance.get("/admin/get-all-support-requests?currentPage=" + query1 + "&pageSize=" + query2);
    return response.data;
}

export async function getAllOpenSupportRequests(query1, query2) {
    const response = await instance.get("/admin/get-all-open-support-requests?currentPage=" + query1 + "&pageSize=" + query2);
    return response.data;
}

export async function getAllInporgressSupportRequests(query1, query2) {
    const response = await instance.get("/admin/get-all-inprogress-support-requests?currentPage=" + query1 + "&pageSize=" + query2);
    return response.data;
}

export async function getAllClosedSupportRequests(query1, query2) {
    const response = await instance.get("/admin/get-all-closed-support-requests?currentPage=" + query1 + "&pageSize=" + query2);
    return response.data;
}

export async function putCloseCustomerServiceEmail(query) {
    await instance.put("/admin/close-customer-service-email?customerServiceId=" + query);
}

export async function postCreateSupportRequest(query1, query2) {
    await instance.post("/admin/create-support-request?subject=" + query1 + "&emailBody=" + query2);
}

export async function postReplySupportRequest(query1, query2) {
    await instance.post("/admin/reply-support-request-as-admin?customerServiceId=" + query1 + "&message=" + query2);
}