import React, { useEffect, useState } from "react";
import { getAllUserSimple, updateUserById } from "../../../api/admin/allUserList";
import { NotificationService } from "../../../helper/notification";
import { ClipLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';

const UserList = () => {
  const [userList, setUserList] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState(0);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    nameFilter: "",
    lastnameFilter: "",
    companyNameFilter: "",
    roleFilter: "",
    emailFilter: ""
  });
  const [nameFilter, setNameFilter] = useState("");
  const [lastnameFilter, setLastnameFilter] = useState("");
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getUserList = async () => {
      try {
        const response = await getAllUserSimple(currentPage, pageSize, filter.nameFilter, filter.lastnameFilter, filter.companyNameFilter, filter.roleFilter, filter.emailFilter);
        setUserList(response);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };

    getUserList();
  }, [currentPage, pageSize, filter]);

  const handleUpdateUser = async () => {
    try {
      await updateUserById({
        id: userList[updateId].id,
        firstname: userList[updateId].firstname,
        lastname: userList[updateId].lastname,
        companyName: userList[updateId].companyName,
        email: userList[updateId].email,
      });

      window.location.reload();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const goToUserDetailsPage = (id) => {
    navigate("/dashboard/user-list/" + id);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(prevPage => prevPage + pageNumber);
  };

  const handlePageSizeChange = (itemCount) => {
    setPageSize(itemCount);
  }

  const handleChangeFilter = () => {
    setFilter({
      nameFilter: nameFilter,
      lastnameFilter: lastnameFilter,
      companyNameFilter: companyNameFilter,
      roleFilter: roleFilter,
      emailFilter: emailFilter
    });
    closeFilterModal();
  }

  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  }

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  }

  const openUpdateModal = (index) => {
    setUpdateId(index);
    setFirstname(userList.content[index].firstname);
    setLastname(userList.content[index].lastname);
    setCompanyName(userList.content[index].companyName);
    setEmail(userList.content[index].email);
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setFirstname("");
    setLastname("");
    setCompanyName("");
    setEmail("");
    setIsUpdateModalOpen(false);
  };

  return (
    <body className="bg-body-tertiary">
      <section>
        <div className="py-5"></div>
        <div className="container mt-5 px-5">
          <div className="d-flex justify-content-between mb-5">
            <h2 className="tertiary-text fw-bold">USER LIST</h2>
            {userList && (<button
              className="btn btn-secondary"
              onClick={openFilterModal}>
              <i className="bi bi-funnel" /> Filter
            </button>)
            }
          </div>
          <div className="users bg-white rounded-4 p-5 mb-3">
            {userList == null ? (
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <ClipLoader
                  loading={userList === null}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : userList.content.length === 0 ? (
              <div className="alert alert-info" role="alert">
                You do not have any users yet! You can create one by clicking Add
                New user button.
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Surname</th>
                      <th scope="col">Company Name</th>
                      <th scope="col">Mail Address</th>
                      <th scope="col">Role</th>
                      <th scope="col">Subscription Plan</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.content.map((user, index) => (
                      <tr key={user.id}>
                        <th>{user.id}</th>
                        <td>{user.firstname}</td>
                        <td>{user.lastname}</td>
                        <td>{user.companyName}</td>
                        <td>{user.email}</td>
                        <td>{user.roleName}</td>
                        <td>{user.subscriptionPlanName}</td>
                        <i
                          onClick={() => openUpdateModal(index)}
                          className="bi bi-pencil-square text-success"
                        ></i>
                        <i
                          onClick={() => goToUserDetailsPage(user.id)}
                          className="bi bi-box-arrow-in-right text-primary"
                        ></i>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <section>
        {userList === null ? (<></>) : (
          <div className="pagination d-flex justify-content-center py-5">
            <div className="px-3">
              <div className="input-group">
                <label className="px-2 py-2">Items per page:</label>
                <select
                  className="form-select border-0 border-2 border-bottom border-dark"
                  value={pageSize}
                  onChange={(event) => handlePageSizeChange(event.target.value)}
                >
                  <option value={5}>5</option>
                  <option value={8}>8</option>
                  <option value={11}>11</option>
                  <option value={14}>14</option>
                  <option value={17}>17</option>
                  <option value={20}>20</option>
                </select>
                <hr />
              </div>
            </div>
            <button
              className="btn border-0 mx-3"
              onClick={() => handlePageChange(-1)}
              disabled={currentPage <= 1}>
              <i className="bi bi-arrow-left"></i></button>
            <button
              className="btn border-0 mx-3"
              onClick={() => handlePageChange(1)}
              disabled={userList.last}>
              <i className="bi bi-arrow-right"></i></button>
          </div>
        )
        }
      </section>
      {isFilterModalOpen && (
        <div
          className="modal fade show d-block"
          id="filterModal"
          tabIndex="-1"
          aria-labelledby="addNewProjectModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="addNewProjectModalLabel">
                  Filter
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeFilterModal}
                ></button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="firstName"
                      placeholder="First Name"
                      value={nameFilter}
                      onChange={(event) => setNameFilter(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="lastName"
                      placeholder="Last Name"
                      value={lastnameFilter}
                      onChange={(event) => setLastnameFilter(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="companyName" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="companyName"
                      placeholder="Company Name"
                      value={companyNameFilter}
                      onChange={(event) => setCompanyNameFilter(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mail" className="form-label">
                      Mail Address
                    </label>
                    <input
                      type="email"
                      className="form-control shadow-none"
                      id="mail"
                      placeholder="Mail Address"
                      value={emailFilter}
                      onChange={(event) => setEmailFilter(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mail" className="form-label">
                      Mail Address
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="role"
                      placeholder="Role"
                      value={roleFilter}
                      onChange={(event) => setRoleFilter(event.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={closeFilterModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn secondary-btn"
                  data-bs-dismiss="modal"
                  disabled={userList.content[updateId].firstname === firstname
                    || userList.content[updateId].lastname === lastname
                    || userList.content[updateId].companyName === companyName
                    || userList.content[updateId].email === email}
                  onClick={handleChangeFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isUpdateModalOpen && (
        <div
          className="modal fade show d-block"
          id="updateProjectModal"
          tabIndex="-1"
          aria-labelledby="addNewProjectModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="addNewProjectModalLabel">
                  Update
                  {userList.content[updateId].firstname +
                    " " +
                    userList.content[updateId].lastname}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeUpdateModal}
                ></button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="firstName"
                      placeholder="First Name"
                      value={userList.content[updateId].firstname}
                      onChange={(event) => setFirstname(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="lastName"
                      placeholder="Last Name"
                      value={userList.content[updateId].lastname}
                      onChange={(event) => setLastname(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="companyName" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      id="companyName"
                      placeholder="Company Name"
                      value={userList.content[updateId].companyName}
                      onChange={(event) => setCompanyName(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mail" className="form-label">
                      Mail Address
                    </label>
                    <input
                      type="email"
                      className="form-control shadow-none"
                      id="mail"
                      placeholder="Mail Address"
                      value={userList.content[updateId].email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={closeUpdateModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn secondary-btn"
                  data-bs-dismiss="modal"
                  disabled={userList.content[updateId].firstname === firstname
                    || userList.content[updateId].lastname === lastname
                    || userList.content[updateId].companyName === companyName
                    || userList.content[updateId].email === email}
                  onClick={handleUpdateUser}
                >
                  Update User
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </body>
  );
};

export default UserList;
