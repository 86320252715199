import React from "react";
import "./Footer.css";
import logo from "../Navbar/logo.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import {useTranslation} from "react-i18next";

const handleFooter = () => {
  // submit message logic
};

const handleRedirect = (url) => {
  window.open(url, "_blank");
};


const Footer = () => {

  const {t, i18n} = useTranslation();

  return (
      <section className="">
        <div className="w-100 footer-bg text-dark rounded-top-5 py-5">
          <div className="container">
            <div className="row d-flex justify-content-around pb-5">
              <div className="col-md-2 flex-column">
                <h5 className="py-3 fw-bold">{t('footer.followUs')}</h5>
                <div className="row">
                  <i
                      className="col-2 bi bi-facebook text-success icon-pointer"
                      onClick={() => handleRedirect("https://www.facebook.com")}
                  ></i>
                  <i
                      className="col-2 bi bi-instagram text-success icon-pointer"
                      onClick={() => handleRedirect("https://www.instagram.com")}
                  ></i>
                  <i
                      className="col-2 bi bi-twitter-x text-success icon-pointer"
                      onClick={() => handleRedirect("https://twitter.com")}
                  ></i>
                </div>
              </div>
              <div className="col-md-2 flex-column">
                <h5 className="py-3 fw-bold">{t('footer.company')}</h5>
                <a href={"/"} style={{color:"black",textDecoration:"none"}}><p>{t('footer.homepage')}</p></a>
                <a href={"/about"} style={{color:"black",textDecoration:"none"}}><p>{t('footer.about')}</p></a>
                <a href={"/resources"} style={{color:"black",textDecoration:"none"}}><p>{t('footer.resources')}</p></a>
              </div>
              <div className="col-md-2 flex-column">
                <h5 className="py-3 fw-bold">{t('footer.roles')}</h5>
                <a href={"/pricing/architect-and-engineer"} style={{color: "black", textDecoration: "none"}}><p>{t('footer.architectEngineer')}</p>
                </a>
                <a href={"/pricing/real-estate-developer"} style={{color: "black", textDecoration: "none"}}><p>{t('footer.realEstateDeveloper')}</p>
                </a>
                <a href={"/pricing/researcher"} style={{color: "black", textDecoration: "none"}}><p>{t('footer.researcher')}</p>
                </a>

              </div>
              <div className="col-md-2 flex-column">
                <img className="py-3" src={logo} alt="logo" width={"60%"}/>
                <p className="pt-4">{t('footer.newsletterMessage')}</p>
              </div>
            </div>
            <hr/>
            <div className="row pt-3">
              <p className="col-md-8">© 2024 ROR360. {t('footer.allRightsReserved')}</p>
              <div className="col-md-4 d-flex justify-content-around">
                <p className="ps-5">{t('footer.privacyPolicy')}</p>
                <p className="ps-5">{t('footer.termsOfService')}</p>
                <p className="ps-5">{t('footer.cookiesSettings')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Footer;
