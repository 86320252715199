import { useState, useEffect } from "react";
import { NotificationService } from "../../../helper/notification";
import { getAllClosedSupportRequests, getAllInporgressSupportRequests, getAllOpenSupportRequests, getAllSupportRequests, postCreateSupportRequest, postReplySupportRequest, putCloseCustomerServiceEmail } from "../../../api/admin/supportRequest";
import { getUserSupportRequestsWithConversations } from "../../../api/auth";
import { ClipLoader } from "react-spinners";

const SupportRequest = () => {

    const [supportRequests, setSupportRequests] = useState(null);
    const [updateId, setUpdateId] = useState(0);
    const [detailedSupportRequest, setDetailedSupportRequest] = useState(null);

    const [supportRequestSubject, setSupportRequestSubject] = useState("");
    const [supportRequestBody, setSupportRequestBody] = useState("");

    const [replyRequestBody, setReplyRequestBody] = useState("");

    const [isCreateSupportOpen, setIsCreateSupportOpen] = useState(false);
    const [isReplySupportOpen, setIsReplySupportOpen] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [status, setStatus] = useState("ALL");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);


    useEffect(() => {
        filterRequests(status);
    }, [currentPage, pageSize]);

    const openCreateSupportScreen = () => {
        setIsCreateSupportOpen(true);
    }

    const closeCreateSupportScreen = () => {
        setIsCreateSupportOpen(false);
    }

    const handleCreateRequest = async () => {
        await postCreateSupportRequest(supportRequestSubject, supportRequestBody);
        closeCreateSupportScreen();
    }

    const handleReplyRequest = async () => {
        await postReplySupportRequest(updateId, replyRequestBody);
        closeRequestDetails();
    }

    const closeSupportRequest = async (id) => {
        await putCloseCustomerServiceEmail(id);
        window.location.reload();
    }

    const openRequestDetails = async (id) => {
        setUpdateId(id);
        const detailedRequestData = await getUserSupportRequestsWithConversations(id);
        setDetailedSupportRequest(detailedRequestData);
        setIsReplySupportOpen(true);
    }

    const closeRequestDetails = async () => {
        setDetailedSupportRequest(null);
        setReplyRequestBody("")
        setUpdateId(0);
        setIsReplySupportOpen(false);
    }

    const filterRequests = async (status) => {
        try {
            setStatus(status);
            let supportData;
            switch (status) {
                case "ALL":
                    supportData = await getAllSupportRequests(currentPage, pageSize);
                    setSupportRequests(supportData);
                    break;
                case "OPEN":
                    supportData = await getAllOpenSupportRequests(currentPage, pageSize);
                    setSupportRequests(supportData);
                    break
                case "IN_PROGRESS":
                    supportData = await getAllInporgressSupportRequests(currentPage, pageSize);
                    setSupportRequests(supportData);
                    break;
                case "CLOSED":
                    supportData = await getAllClosedSupportRequests(currentPage, pageSize);
                    setSupportRequests(supportData);
                    break;
                default:
                    supportData = await getAllSupportRequests(currentPage, pageSize);
                    setSupportRequests(supportData);
                    break;
            }
            setIsDropdownOpen(false);
        } catch (error) {
            NotificationService.error("An error occured!");
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(prevPage => prevPage + pageNumber);
    };

    const handlePageSizeChange = (itemCount) => {
        setPageSize(itemCount);
    }

    return (
        <body className="bg-body-tertiary">
            <section className="pt-5">
                <div className="row w-100 d-flex justify-content-between mt-5 px-5 pt-5">
                    <h2 className="col-md-9 tertiary-text fw-bold mb-3">&nbsp;&nbsp;Support Requests</h2>
                    <div className="col-md-3 position-relative">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            <i className="bi bi-funnel" />&nbsp;Filter
                        </button>
                        {isDropdownOpen && (
                            <ul className="dropdown-menu show position-absolute" style={{ zIndex: 1000 }}>
                                <li><button className="dropdown-item" onClick={() => filterRequests("ALL")}>All</button></li>
                                <li><button className="dropdown-item" style={{ backgroundColor: "#CFF1E6", color: "#11B981" }} onClick={() => filterRequests("OPEN")}>Open</button></li>
                                <li><button className="dropdown-item" style={{ backgroundColor: "#FFF1D4", color: "#FFBB2A" }} onClick={() => filterRequests("IN_PROGRESS")}>Inprogress</button></li>
                                <li><button className="dropdown-item" style={{ backgroundColor: "#F9D2DA", color: "#E11D48" }} onClick={() => filterRequests("CLOSED")}>Closed</button></li>
                            </ul>
                        )}
                        <button className="btn secondary-btn ms-3" onClick={openCreateSupportScreen}>New Ticket</button>
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-white rounded-4 m-5">
                    {isCreateSupportOpen ? (
                        <div className="form-group p-5">
                            <div className="d-flex justify-content-between pb-5">
                                <label>Subject <span className="text-danger">*</span><small> <i class="text-secondary bi bi-exclamation-circle" /></small></label>
                                <input
                                    type="text"
                                    className="form-control shadow-none w-75"
                                    id="subject"
                                    value={supportRequestSubject}
                                    onChange={(event) => setSupportRequestSubject(event.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-between pb-5">
                                <label>Description <span className="text-danger">*</span><small> <i class="text-secondary bi bi-exclamation-circle" /></small></label>
                                <textarea
                                    type="text"
                                    rows="4"
                                    className="form-control shadow-none w-75"
                                    id="description"
                                    placeholder="Type your description..."
                                    value={supportRequestBody}
                                    onChange={(event) => setSupportRequestBody(event.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-secondary me-3" onClick={closeCreateSupportScreen}>Discard</button>
                                <button className="btn secondary-btn" disabled={supportRequestSubject === "" || supportRequestBody === ""} onClick={handleCreateRequest}>Create Request</button>
                            </div>
                        </div>
                    ) : isReplySupportOpen ? (
                        <div className="p-5">
                            <h5>Customer Support Request</h5>
                            {detailedSupportRequest.map((supportRequest) => (
                                <div className="border rounded-3 mb-3 p-3">
                                    <p>{supportRequest.subject}</p>
                                    <hr />
                                    <div className="d-flex justify-content-around">
                                        <small className="text-secondary">Sent From: {supportRequest.setFrom}</small>
                                        <small className="text-secondary">Sent To: {supportRequest.setTo}</small>
                                    </div>
                                    <hr />
                                    <p>{supportRequest.message}</p>

                                </div>
                            ))}
                            <div className="form-group p-5">
                                <div className="d-flex justify-content-between pb-5">
                                    <label>Description <span className="text-danger">*</span><small> <i class="text-secondary bi bi-exclamation-circle" /></small></label>
                                    <textarea
                                        type="text"
                                        rows="4"
                                        className="form-control shadow-none w-75"
                                        id="description"
                                        placeholder="Type your description..."
                                        value={replyRequestBody}
                                        onChange={(event) => setReplyRequestBody(event.target.value)}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-secondary me-3" onClick={closeRequestDetails}>Discard</button>
                                    <button className="btn secondary-btn" disabled={replyRequestBody === ""} onClick={handleReplyRequest}>Reply Request</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="d-flex justify-content-between px-5 pt-5">
                                <input
                                    className="form-control w-25"
                                    placeholder="Search..."
                                />
                                <div className="d-flex">
                                    <button className="btn btn-outline-dark"><i class="bi bi-three-dots" /></button>
                                </div>
                            </div>
                            <hr className="text-secondary" />
                            <div className="d-flex justify-content-around">
                                <span className="text-secondary fw-bold col-3">TITLE</span>
                                <span className="text-secondary fw-bold col-3">DATE</span>
                                <span className="text-secondary fw-bold col-3">STATUS</span>
                                <div className="col-1"></div>
                            </div>
                            {
                                supportRequests === null ? (
                                    <ClipLoader
                                        loading={supportRequests === null}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                ) : supportRequests.content.length === 0 ? (
                                    <div className="alert alert-info">There is no support request right now.</div>
                                ) : (
                                    <div className="table-responsive">
                                        {supportRequests.content.map((supportRequest) => (<>
                                            <hr className="text-secondary dashed-line" />
                                            <div className="d-flex justify-content-around">
                                                <div className="col-3">{supportRequest.subject}</div>
                                                <div className="col-3">
                                                    {supportRequest.lastUpdatedDate.split("T")[0]}
                                                    <br />
                                                    <small className="text-secondary">{supportRequest.lastUpdatedDate.split("T")[1].slice(0, 5)}</small>
                                                </div>
                                                <div className="col-3 d-flex align-items-center">
                                                    {supportRequest.customerServiceStatus === "OPEN" ? (<>
                                                        <div className="rounded-4 px-2" style={{ backgroundColor: "#CFF1E6" }}><small style={{ color: "#11B981" }}>Open</small></div>
                                                    </>) : supportRequest.customerServiceStatus === "CLOSED" ? (<>
                                                        <div className="rounded-4 px-2" style={{ backgroundColor: "#F9D2DA" }}><small style={{ color: "#E11D48" }}>Closed</small></div>
                                                    </>) : (<>
                                                        <div className="rounded-4 px-2" style={{ backgroundColor: "#FFF1D4" }}><small style={{ color: "#FFBB2A" }}>Inprogress</small></div>
                                                    </>)}
                                                </div>
                                                <div className="col-1">
                                                    <button className="btn btn-outline-dark rounded-circle me-2" onClick={() => openRequestDetails(supportRequest.id)}><i class="bi bi-reply"></i></button>
                                                    <button className="btn btn-outline-danger rounded-circle" onClick={() => closeSupportRequest(supportRequest.id)}><i class="bi bi-x-lg"></i></button>
                                                </div>
                                            </div>
                                        </>)
                                        )}
                                    </div>
                                )
                            }
                        </>
                    )}
                </div>
            </section>
            <section>
                {supportRequests === null ? (<></>) : (
                    <div className="pagination d-flex justify-content-center py-5">
                        <div className="px-3">
                            <div className="input-group">
                                <label className="px-2 py-2">Items per page:</label>
                                <select
                                    className="form-select border-0 border-2 border-bottom border-dark"
                                    value={pageSize}
                                    onChange={(event) => handlePageSizeChange(event.target.value)}
                                >
                                    <option value={5}>5</option>
                                    <option value={8}>8</option>
                                    <option value={11}>11</option>
                                    <option value={14}>14</option>
                                    <option value={17}>17</option>
                                    <option value={20}>20</option>
                                </select>
                                <hr />
                            </div>
                        </div>
                        <button
                            className="btn border-0 mx-3"
                            onClick={() => handlePageChange(-1)}
                            disabled={currentPage <= 1}>
                            <i className="bi bi-arrow-left"></i></button>
                        <button
                            className="btn border-0 mx-3"
                            onClick={() => handlePageChange(1)}
                            disabled={supportRequests.last}>
                            <i className="bi bi-arrow-right"></i></button>
                    </div>
                )
                }
            </section>
        </body>
    );
};

export default SupportRequest;
