export const schemaBuilder = (typeOfMaterials) => ({
  "Type Of Material": {
    prop: "structureElement",
    type: String,
    required: true,
    oneOf: typeOfMaterials,
  },
  "Material Name": {
    prop: "type",
    type: String,
    required: true,
  },
  Model: {
    prop: "model",
    type: String,
    required: true,
  },
  Brand: {
    prop: "brand",
    type: String,
    required: true,
  },
  Unit: {
    prop: "unit",
    type: String,
    required: true,
  },
  Thickness: {
    prop: "thickness",
    type: String,
    required: true,
  },
  "Gwp Value": {
    prop: "gwpValue",
    type: String,
    required: true,
  },
  "Lambda Value": {
    prop: "lambdaValue",
    type: String,
    required: true,
  },
});
