import instance from "../baseInstance";

export async function getExpiringUsers() {
    const response = await instance.get("/admin/get-users-expiring-within-a-week");
    return response.data;
}

export async function getTotalUsers() {
    const response = await instance.get("/admin/get-total-number-of-users");
    return response.data;
}

export async function getTotalOpenRequests() {
    const response = await instance.get("/admin/get-total-number-of-open-requests");
    return response.data;
}

export async function getTotalInProgressRequests() {
    const response = await instance.get("/admin/get-total-number-of-in-progress-requests");
    return response.data;
}

export async function getTotalClosedRequests() {
    const response = await instance.get("/admin/get-total-number-of-closed-requests");
    return response.data;
}

export async function getTotalNumberOfOpenMaterialDataRequest() {
    const response = await instance.get("/admin/get-total-number-of-open-material-data-requests");
    return response.data;
}

export async function getTotalMaterialDataRequests() {
    const response = await instance.get("/admin/get-total-number-of-material-data-requests");
    return response.data;
}

export async function getTotalInfoLogs() {
    const response = await instance.get("/admin/get-total-number-of-info-logs");
    return response.data;
}

export async function getTotalErrorLogs() {
    const response = await instance.get("/admin/get-total-number-of-error-logs");
    return response.data;
}

export async function getProjectsCount() {
    const response = await instance.get("/admin/get-projects-count");
    return response.data;
}

export async function getMostUsedMaterials() {
    const response = await instance.get("/admin/get-most-used-data");
    return response.data;
}

export async function getAllLogsByLogtype(query1, query2, query3) {
    const response = await instance.get("/admin/get-all-logs-by-logtype?logType=" + query1 + "&currentPage=" + query2 + "&pageSize=" + query3);
    return response.data;
}

export async function getAccountsByAccounttype() {
    const response = await instance.get("/admin/get-accounts-by-accounttype");
    return response.data;
}

export async function postSendCustomEmail(query1, query2, query3) {
    await instance.post("/admin/send-custom-email?userId=" + query1 + "&subject=" + query2 + "&message=" + query3);
}

export async function postSendEmailForAd(query) {
    await instance.post("/admin/send-email-for-ad?userId=" + query);
}