import { useState, useEffect } from "react";
import { getUser, updateUser } from "../../../../api/auth";
import { NotificationService } from "../../../../helper/notification";

import "../Background.css";
import ProfileBanner from "../../../../components/ProfileBanner/ProfileBanner";
import ChangePassword from "../ChangePassword/ChangePassword";
import ChangeEmail from "../ChangeEmail/ChangeEmail";

const Security = () => {
    const [error, setError] = useState(null);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        companyName: ""
    });

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await getUser();
                setUserInfo(response);
            } catch (error) {
                NotificationService.error("An error occurred");
            }
        };
        getUserInfo();
    }, {});

    const openEmailModal = () => {
        setIsEmailModalOpen(true);
    }

    const openPasswordModal = () => {
        setIsPasswordModalOpen(true);
    }

    const closeEmailModal = () => {
        setIsEmailModalOpen(false);
    }

    const closePasswordModal = () => {
        setIsPasswordModalOpen(false);
    }
    return (
        <body className="bg-body-tertiary">
            <ProfileBanner userInfo={userInfo} />
            <section className="pb-5">
                <h2 className="tertiary-text fw-bold ps-5 mb-3">&nbsp;&nbsp;Profile Settings</h2>
                <div className="bg-white rounded-4 p-5 m-5">
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            Email Address
                            <br />
                            <small className="text-secondary">{userInfo.email}</small>
                        </div>
                        <div><i class="bi bi-patch-check" style={{ color: "#11B981" }} />&nbsp;Verified</div>
                        <button className="btn btn-secondary" onClick={openEmailModal}>Change Email</button>
                    </div>
                    <hr className="dashed-line" />
                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            Password
                            <br />
                            <small className="text-secondary">********</small>
                        </div>
                        <div><i class="bi bi-patch-check" style={{ color: "#11B981" }} />&nbsp;Secure</div>
                        <button className="btn btn-secondary" onClick={openPasswordModal}>Change Password</button>
                    </div>
                </div>
            </section>
            {isEmailModalOpen && (
                <div class="modal fade show d-block">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Change Email</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeEmailModal}></button>
                            </div>
                            <div class="modal-body">
                                <ChangeEmail userInfo={userInfo} close={closeEmailModal} />
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
            {isPasswordModalOpen && (
                <div class="modal fade show d-block">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">ChangePassword</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closePasswordModal}></button>
                            </div>
                            <div class="modal-body">
                                <ChangePassword close={closePasswordModal} />
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </body>
    );
};

export default Security;
