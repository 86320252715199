import React, { useRef, useEffect } from "react";
import readXlsxFile from "read-excel-file";
import { schemaBuilder } from "./excelSchema";
import { NotificationService } from "../../helper/notification";

const ImportFromExcelButton = ({
  setFormData,
  setConstructionData,
  materialData,
}) => {
  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const schema = schemaBuilder(Object.keys(materialData));

  useEffect(() => {
    const input = document.getElementById("input");
    const handleFileChange = () => {
      if (input.files[0]) {
        readXlsxFile(input.files[0], { schema }).then((rows) => {
          if (rows.errors.length > 0) {
            NotificationService.error(
              "An error occurred while importing excel. Please check the format!"
            );
            return;
          } else {
            const data = rows.rows;
            for (const elem of data) {
              const type = elem.type;
              const buildingComponent = elem.buildingComponent;
              if (!materialData[buildingComponent].some(component => component.name === type)) {
                NotificationService.error(
                  "An error occurred while importing excel. Please check the format!"
                );
                return;
              }
            }
            setFormData([]);

            setFormData(data);
            setConstructionData((prevConstructionData) => ({
              ...prevConstructionData,
              createMaterialRequests: data,
            }));
          }
        });
      }
    };

    input.addEventListener("change", handleFileChange);

    return () => {
      input.removeEventListener("change", handleFileChange);
    };
  }, []);

  return (
    <div>
      <input
        type="file"
        id="input"
        style={{ display: "none" }}
        accept=".xlsx, .xls"
        ref={fileInputRef}
      />

      <button className="btn btn-success" onClick={handleFileInputClick}>
        <i class="bi bi-file-earmark-spreadsheet"></i>
      </button>
    </div>
  );
};

export default ImportFromExcelButton;
