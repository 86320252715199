/*
 This page connects authentication and user endpoints with the front-end
*/

import instance from "./baseInstance";


// Created By Arda Kabadayı
// This method sends the required information for signing-up a user
export async function signUpProcess(jsonData) {
  await instance.post("auth/register", jsonData);
}

// Created By Arda Kabadayı
// This method sends the required information for logging-in a user
export async function signInProcess(jsonData) {
  const response = await instance.post("auth/authenticate", jsonData);
  localStorage.setItem("token", response.data.body.access_token);
}

// Created By Halit Kaan Kaçar
// This method is needed for getting and saving the user role inside local storage to define user autherization after log-in process
export async function saveUserInfo() {
  const response = await getUser();
  localStorage.setItem("userInfo", JSON.stringify(response));
}

// Created By Halit Kaan Kaçar
// This method used for changing user role after transaction process (if user choose free plan still this method used)
export async function setMyRole(pathVariable) {
  await instance.post("user/set-my-role/" + pathVariable);
}

// Created by Arda Kabadayı
// This method is used for changed user password after logged-in
export async function changePassword(jsonData) {
  await instance.put("user/reset-password", jsonData);
}

// Created by Halit Kaan Kaçar
// This method is used for getting user information to open profile page
export async function getUser() {
  const response = await instance.get("user/my-info-simple");
  return response.data;
}

// Created by Halit Kaan Kaçar
// This method is used for getting detailed user information
export async function getUserDetailed() {
  const response = await instance.get("user/my-info-detailed");
  return response.data;
}

// Created by Halit Kaan Kaçar
// This method used for getting user's subscription plan 
export async function getMySubscriptionPlan() {
  const response = await instance.get("subscription-plans/find-my-subscription-plan");
  return response.data;
}

// Created by Halit Kaan Kaçar
// This method is for to get credit card information
export async function getUserCreditCardInfo() {
  const response = await instance.get("user/get-my-credit-card-info");
  return response.data;
}

// Created by Halit Kaan Kaçar
// This method makes possible to see user support requests on get support screen
export async function getUserSupportRequests() {
  const response = await instance.get("user/get-my-support-requests");
  return response.data;
}

// Created by Halit Kaan Kaçar
// This method makes possible to see details of supports request when click on it
export async function getUserSupportRequestsWithConversations(query) {
  const response = await instance.get("user/get-support-request-with-conversations?customerServiceId=" + query);
  return response.data;
}

// Created by Halit Kaan Kaçar
// This method is used for chaged user information from profile page
export async function updateUser(jsonData) {
  await instance.put("user/update", jsonData);
}

// Created by Halit Kaan Kaçar
// This method is used for update user email in profile security page change email modal
export async function updateUserEmail(jsonData) {
  await instance.put("user/update-my-email", jsonData);
}

// Created by Halit Kaan Kaçar
// This method used in transaction process after selected save my credit card checkbox
export async function addCreditCard(jsonData) {
  await instance.post("user/add-credit-card", jsonData);
}

// Created by Halit Kaan Kaçar
// This method used in profile page to change or add credit card
export async function updateCreditCard(jsonData) {
  await instance.put("user/update-credit-card", jsonData);
}

// Created by Halit Kaan Kaçar
// This method is used for send the information that user is forgot the password to API
export async function forgotPasswordRequest(pathVariable) {
  await instance.post("user/forgot-password/" + pathVariable);
}

// Created by Halit Kaan Kaçar
// This method is used for change forgotten password without logging-in
export async function resetPassword(pathVariable, jsonData) {
  await instance.put("forgot-password-reset/" + pathVariable, jsonData);
}

// Created by Halit Kaan Kaçar
// This method is used for create a new support request as user
export async function createSupportRequest(query1, query2) {
  await instance.post("user/create-support-request?subject=" + query1 + "&emailBody=" + query2);
}

// Created by Halit Kaan Kaçar
// This method is used for reply for already created supporrt request as user
export async function replySupportRequest(query1, query2) {
  await instance.post("user/reply-support-request-as-customer?customerServiceId=" + query1 + "&message=" + query2);
}
