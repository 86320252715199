import instance from "../baseInstance";

export async function getUserById(pathVariable) {
    const response = await instance.get("user/" + pathVariable);
    return response.data;
}

export async function getAllUserSimple(query1, query2, query3, query4, query5, query6, query7) {
    const response = await instance.get("user/get-all-simple?currentPage=" + query1 + "&pageSize=" + query2 + "&name=" + query3 + "&lastname=" + query4 + "&companyName=" + query5 + "&role=" + query6 + "&email=" + query7);
    return response.data;
}

export async function getAllUserDetailed() {
    const response = await instance.get("user/get-all-detailed");
    return response.data;
}

export async function updateUserById(jsonData) {
    await instance.put("user/update", jsonData);
}