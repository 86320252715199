import { useNavigate } from "react-router-dom";
import "./Pricing.css";
import "./Background.css";
import { Banner } from "../../../components/LandingPageComponents/Banner/Banner";
import image_1 from "./pricing-image-2.png";
import card_1 from "./benefit-card-1.png";
import card_2 from "./benefit-card-2.png";
import card_3 from "./benefit-card-3.png";
import { useState } from "react";
import { InsideAction } from "../../../components/LandingPageComponents/InsideAction/InsideAction";
import { useTranslation } from "react-i18next";


const Pricing = () => {
    const { t } = useTranslation(); // Move this up here

    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedImage, setSelectedImage] = useState(image_1);
    const [selectedText, setSelectedText] = useState(t('text_1'));
    const [currentIndex, setCurrentIndex] = useState(0);


    const handleSelectRole = (role) => {
        setSelectedRole(role);
        switch (role) {
            case "architect-and-engineer":
                setSelectedImage(image_2);
                setSelectedText(text_2);
                break;
            case "real-estate-developer":
                setSelectedImage(image_3);
                setSelectedText(text_3);
                break;
            case "researcher":
                setSelectedImage(image_4);
                setSelectedText(text_4);
                break;
            default:
                setSelectedImage(image_1);
                setSelectedText(text_1);
        }
    };

    const handlePrevClick = () => {
        setCurrentIndex(
            currentIndex === 0 ? testimonialsData.length - 1 : currentIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex(
            currentIndex === testimonialsData.length - 1 ? 0 : currentIndex + 1
        );
    };

    const getPrevIndex = () => {
        return currentIndex === 0
            ? testimonialsData.length - 1
            : currentIndex - 1;
    };

    const getNextIndex = () => {
        return currentIndex === testimonialsData.length - 1
            ? 0
            : currentIndex + 1;
    };

    const text_1 = t("text_1");
    const text_2 = t("text_2");
    const text_3 = t("text_3");
    const text_4 = t("text_4");

    const image_2 = image_1;
    const image_3 = image_1;
    const image_4 = image_1;

    const testimonialsData = [
        {
            name: "John Doe",
            role: "Software Engineer",
            rating: 4.9,
            review: t("testimonial_john"),
        },
        {
            name: "Jane Smith",
            role: "UI/UX Designer",
            rating: 4.7,
            review: t("testimonial_jane"),
        },
        {
            name: "Michael Johnson",
            role: "Project Manager",
            rating: 4.8,
            review: t("testimonial_michael"),
        },
        {
            name: "Sarah Thompson",
            role: "Architect/Engineer",
            rating: 4.8,
            review: t("testimonial_sarah"),
        },
        {
            name: "David Lee",
            role: "Real Estate Developer",
            rating: 4.7,
            review: t("testimonial_david"),
        },
        {
            name: "Emily Rodriguez",
            role: "Researcher",
            rating: 4.9,
            review: t("testimonial_emily"),
        }
    ];

    return (


        <>
            <section>
                <Banner page={"pricing"} />
            </section>

            <section>
                <div className="container">
                    <div className="col-6">
                        <h2 className="pb-4">{t('pricingg.title')}</h2>
                        <p>{t('pricingg.description')}</p>
                    </div>
                </div>
            </section>
            <section className="py-3">
                <div>
                    <h3 className="secondary-text text-center">{t('pricingg.plans')}</h3>
                    <h2 className="text-center mb-4">{t('pricingg.chooseSuitablePlans')}</h2>
                    <p className="text-dark text-center">**{t('pricingg.pricesChange')}</p>
                    <div className="row justify-content-center">
                        <div className="row d-flex justify-content-center mb-4">
                            <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title text-center">{t('pricingg.free')}</h5>
                                    <h6 className="card-subtitle text-center mb-4 text-muted">{t('pricingg.freeTrial')}</h6>
                                    <ul className="list-unstyled mb-4 flex-grow-1">
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.readOnly')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.contact24_7')}
                                        </li>
                                        <li className="text-dark text-opacity-25 mb-2">
                                            <i className="bi bi-check-circle-fill"/> {t('pricingg.carbonEmissionAnalysis')}
                                        </li>
                                        <li className="text-dark text-opacity-25 mb-2">
                                            <i className="bi bi-check-circle-fill"/> {t('pricingg.materialAnalyze')}
                                        </li>
                                        <li className="text-dark text-opacity-25 mb-2">
                                            <i className="bi bi-check-circle-fill"/> {t('pricingg.nationalDatabaseAPI')}
                                        </li>
                                        <li className="text-dark text-opacity-25 mb-2">
                                            <i className="bi bi-check-circle-fill"/> {t('pricingg.comparativeAnalysis')}
                                        </li>
                                    </ul>
                                    <button
                                        className="btn secondary-text rounded-0 fw-bold"
                                        onClick={() => navigate("/sign-up")}
                                    >
                                        {t('pricingg.joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />
                                    </button>
                                </div>
                            </div>
                            <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title text-center">{t('pricingg.basic')}</h5>
                                    <h6 className="card-subtitle text-center mb-4 text-muted">{t('pricingg.basicPackage')}</h6>
                                    <ul className="list-unstyled mb-4 flex-grow-1">
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.singleUser')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.contact24_7')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.carbonEmissionAnalysis')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.materialAnalyze')}
                                        </li>
                                        <li className="text-dark text-opacity-25 mb-2">
                                            <i className="bi bi-check-circle-fill"/> {t('pricingg.nationalDatabaseAPI')}
                                        </li>
                                        <li className="text-dark text-opacity-25 mb-2">
                                            <i className="bi bi-check-circle-fill"/> {t('pricingg.comparativeAnalysis')}
                                        </li>
                                    </ul>
                                    <button
                                        className="btn secondary-text rounded-0 fw-bold"
                                        onClick={() => navigate("/sign-up")}
                                    >
                                        {t('pricingg.joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />
                                    </button>
                                </div>
                            </div>
                            <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title text-center">{t('pricingg.professional')}</h5>
                                    <h6 className="card-subtitle text-center mb-4 text-muted">{t('pricingg.professionalPackage')}</h6>
                                    <ul className="list-unstyled mb-4 flex-grow-1">
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.multipleUser')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.contact24_7')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.carbonEmissionAnalysis')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.materialAnalyze')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.nationalDatabaseAPI')}
                                        </li>
                                        <li className="mb-2">
                                            <i className="bi bi-check-circle-fill secondary-text"/> {t('pricingg.comparativeAnalysis')}
                                        </li>
                                    </ul>
                                    <button
                                        className="btn secondary-text rounded-0 fw-bold"
                                        onClick={() => navigate("/sign-up")}
                                    >
                                        {t('pricingg.joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container text-center mt-5">
                    <h2 className="text-uppercase">{t('pricingg.testimonials')}</h2>
                    <h4>{t('pricingg.reviewFromClients')}</h4>

                    <div className="row justify-content-center align-items-center mt-4 position-relative">
                        <div className="d-flex justify-content-between col-md-3 testimonial-card shadow-sm">
                            <button className="btn secondary-btn rounded-0 my-3" onClick={handlePrevClick}>
                                &#8592;
                            </button>
                            <div className="testimonial prev">
                                <h5>{testimonialsData[getPrevIndex()].name}</h5>
                                <p>{testimonialsData[getPrevIndex()].role}</p>
                            </div>
                        </div>
                        <div className="col-md-6 bg-light p-4 rounded testimonial-card">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="fw-bold">{testimonialsData[currentIndex].name}</h4>
                                    <p className="text-muted">{testimonialsData[currentIndex].role}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span style={{color: "gold"}}>★</span>
                                    <span className="ms-2">
                                        {testimonialsData[currentIndex].rating}
                                    </span>
                                </div>
                            </div>
                            <p className="text-muted">
                                "{testimonialsData[currentIndex].review}"
                            </p>
                        </div>
                        <div className="d-flex justify-content-between col-md-3 testimonial-card shadow-sm">
                            <div className="testimonial next">
                                <h5>{testimonialsData[getNextIndex()].name}</h5>
                                <p>{testimonialsData[getNextIndex()].role}</p>
                            </div>
                            <button className="btn secondary-btn rounded-0 my-3" onClick={handleNextClick}>
                                &#8594;
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Pricing;
