import { toast } from "react-toastify";

export class NotificationService {
  static error(message) {
    NotificationService.notify(message, "error");
  }

  static success(message) {
    NotificationService.notify(message, "success");
  }

  static warn(message) {
    NotificationService.notify(message, "warning");
  }

  static notify(message, type) {
    toast[type](message);
  }
}
