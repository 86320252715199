import React, { useEffect, useState } from 'react';
import './SideNavbar.css';
import { getUserInfo } from '../../helper/auth';
import { useLocation } from 'react-router-dom';

const SideNavbar = () => {
    const [userInfo, setUserInfo] = useState(getUserInfo());
    const location = useLocation();

    useEffect(() => {
        const handleStorageChange = () => {
            let userInfoResponse = getUserInfo();

            if (userInfoResponse.role === null) {
                userInfoResponse.role = { name: "" };
            }

            if (userInfoResponse.subscriptionPlan == null) {
                userInfoResponse.subscriptionPlan = { name: "" };
            }

            setUserInfo(userInfoResponse);
        };

        handleStorageChange();
    }, []);

    return (
        <div className="sidebar mt-5">
            {location.pathname.startsWith('/profile') ? (<>
                <div className="sidebar-section mt-5">
                    <h3 className="d-none d-xxl-inline">ACCOUNT SETTINGS</h3>
                    <ul className="pt-3">
                        <li>
                            <a className={`link-underline link-underline-opacity-0 ${location.pathname === '/profile/profile-settings' ? 'active' : ''} custom-link`}
                                href='/profile/profile-settings'
                            >
                                <i class="bi bi-sliders"></i><span className="d-none d-xxl-inline"> Profile Settings</span>
                            </a>
                        </li>
                        <li>
                            <a className={`link-underline link-underline-opacity-0 ${location.pathname === '/profile/security' ? 'active' : ''} custom-link`}
                                href='/profile/security'
                            >
                                <i class="bi bi-shield-fill-check"></i><span className="d-none d-xxl-inline"> Security</span>
                            </a>
                        </li>
                        {
                            userInfo.roleName !== "ADMIN" ? (
                                <li>
                                    <a className={`link-underline link-underline-opacity-0 ${location.pathname === '/profile/payment' ? 'active' : ''} custom-link`}
                                        href='/profile/payment'
                                    >
                                        <i class="bi bi-credit-card-fill"></i><span className="d-none d-xxl-inline"> Payment Method</span>
                                    </a>
                                </li>
                            ) : (<></>)
                        }
                        <li>
                            <a className={`link-underline link-underline-opacity-0 ${location.pathname === '/profile/notifications' ? 'active' : ''} custom-link`}
                                href='/profile/notification'
                            >
                                <i class="bi bi-bell-fill"></i><span className="d-none d-xxl-inline"> Notifications</span>
                            </a>
                        </li>
                    </ul>
                    {
                        userInfo.roleName !== "ADMIN" ? (
                            <>
                                <h3>CONTACT US</h3>
                                <ul>
                                    <li>
                                        <a className={`link-underline link-underline-opacity-0 ${location.pathname === '/profile/get-support' ? 'active' : ''} custom-link`}
                                            href='/profile/get-support'
                                        >
                                            <i class="bi bi-patch-question-fill"></i><span className="d-none d-xxl-inline"> Support Center</span>
                                        </a>
                                    </li>
                                </ul>
                            </>
                        ) : (<></>)
                    }
                </div>
            </>) : (
                <>
                    <div className="sidebar-section mt-5">
                        <h3>MENU</h3>
                        <ul>
                            <li>
                                <a
                                    className={`link-underline link-underline-opacity-0 ${location.pathname === '/dashboard' ? 'active' : ''} custom-link`}
                                    href="/dashboard"
                                >
                                    <i className="bi bi-grid-fill"></i><span className="d-none d-xxl-inline"> Dashboard</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={`link-underline link-underline-opacity-0 ${location.pathname === '/dashboard/support-requests' ? 'active' : ''}  custom-link`}
                                    href="/dashboard/support-requests"
                                >
                                    <i className="bi bi-headset"></i><span className="d-none d-xxl-inline"> Support Requests</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={`link-underline link-underline-opacity-0 ${location.pathname === '/dashboard/edit-role-permission' ? 'active' : ''}  custom-link`}
                                    href="/dashboard/edit-role-permission"
                                >
                                    <i class="bi bi-person-fill"></i><span className="d-none d-xxl-inline"> Edit Role Permission</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={`link-underline link-underline-opacity-0 ${location.pathname === '/dashboard/edit-plan-permission' ? 'active' : ''}  custom-link`}
                                    href="/dashboard/edit-plan-permission"
                                >
                                    <i class="bi bi-card-checklist"></i><span className="d-none d-xxl-inline"> Edit Plan Permission</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={`link-underline link-underline-opacity-0 ${location.pathname === '/dashboard/building-component-scheme' ? 'active' : ''}  custom-link`}
                                    href="/dashboard/building-component-scheme"
                                >
                                    <i class="bi bi-building-fill-gear"></i><span className="d-none d-xxl-inline"> Building Component Scheme</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar-section">
                        <h3>UTILITIES</h3>
                        <ul>
                            <li>
                                <a>
                                    <i className="bi bi-gear-fill"></i><span className="d-none d-xxl-inline"> Settings</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            )}

        </div>
    );
};

export default SideNavbar;
