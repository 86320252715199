import "./InsideBanner.css";
import icon_1 from "./inside-banner-icon-1.png";
import icon_2 from "./inside-banner-icon-2.png";
import icon_3 from "./inside-banner-icon-3.png";


export const InsideBanner = () => {
    return (
        <div className="inside-banner-background px-5">
            <div className="row d-flex justify-content-center">
                <div className="col-md-3 d-flex align-items-center"><img src={icon_1} height={"25%"} /><div className="ps-4"><h2 className="text-center">3.425,188</h2><p className="text-center">Enegy Savings<br />MWh/year</p></div></div>
                <div className="col-md-3 d-flex align-items-center"><img src={icon_2} height={"25%"} /><div className="ps-4"><h2 className="text-center">1.854,237</h2><p className="text-center">Oxygen Savings<br />O2/year</p></div></div>
                <div className="col-md-3 d-flex align-items-center"><img src={icon_3} height={"25%"} /><div className="ps-4"><h2 className="text-center">129.059,744</h2><p className="text-center">Enegy in Materials Savings<br />GJ</p></div></div>
            </div>
        </div>
    );
}