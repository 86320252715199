import { useTranslation } from "react-i18next";

export const Banner = ({ startPage, page }) => {
    const { t } = useTranslation();

    // Değerleri çevirmek için i18n kullanımı
    const translatedStartPage = startPage ? t(startPage.replaceAll("-", " ").toLowerCase()) : t('homepage');
    const translatedPage = t(page.replaceAll("-", " ").toLowerCase());

    return (
        <div className="pb-5 px-3">
            <div className={`bg-${page} ms-2 rounded-5 pt-5 mb-5`}>
                <div className="pt-5 ps-5 pb-4">
                    <h4 className="secondary-text pt-5">
                        {translatedStartPage} <i className="bi bi-caret-right-fill"></i>
                        <span className="text-light">{translatedPage}</span>
                    </h4>
                    <h1 className="text-light">{translatedPage}</h1>
                </div>
            </div>
        </div>
    );
};
