import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword, forgotPasswordRequest } from "../../../api/auth";
import { NotificationService } from "../../../helper/notification";
import SixDigitInput from "./SixDigitInput";

const ResetPassword = () => {
    const { email } = useParams();
    const [forgotPasswordCode, setForgotPasswordCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [reenterNewPassword, setReEnterNewPassword] = useState("");
    const [error, setError] = useState({
        forgotPasswordCode: "",
        newPassword: "",
        reenterNewPassword: ""
    });
    const [timer, setTimer] = useState(180);
    const [isCodeRequested, setIsCodeRequested] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [timer]);

    const handlePasswordChange = async () => {
        if (newPassword !== reenterNewPassword) {
            setError((prev) => ({
                ...prev,
                reenterNewPassword: "Şifreler uyuşmuyor."
            }));
            return;
        }

        if (!validatePassword(newPassword)) {
            setError((prev) => ({
                ...prev,
                newPassword: "Şifre en az 8 karakter uzunluğunda, bir küçük harf, bir büyük harf, bir rakam ve bir özel karakter içermelidir."
            }));
            return;
        }

        try {
            await resetPassword(
                forgotPasswordCode,
                { password: newPassword }
            );
            NotificationService.success("Your password changed successfully!");
            navigate("/profile");
        } catch (error) {
            setError((prev) => ({
                ...prev,
                forgotPasswordCode: "Şifre değiştirme sırasında bir hata oluştu."
            }));
        }
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
        return regex.test(password);
    };

    const handleForgotPasswordCodeChange = (code) => {
        setForgotPasswordCode(code);
    };

    const handleNewPasswordChange = (event) => {
        const value = event.target.value;
        setNewPassword(value);
        setError((prev) => ({
            ...prev,
            newPassword: validatePassword(value) ? "" : "Şifre en az 8 karakter uzunluğunda, bir küçük harf, bir büyük harf, bir rakam ve bir özel karakter içermelidir."
        }));
    };

    const handleReEnterNewPasswordChange = (event) => {
        const value = event.target.value;
        setReEnterNewPassword(value);
        setError((prev) => ({
            ...prev,
            reenterNewPassword: value === newPassword ? "" : "Şifreler uyuşmuyor."
        }));
    };

    const handleRequestNewCode = async () => {
        setTimer(180);
        setIsCodeRequested(true);
        try {
            await forgotPasswordRequest(email);
            NotificationService.success("Please enter the code come from your mail address before time run out!");
        } catch (error) {
            console.log("hata oluştu");
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <section className="pb-5">
            <div className="bg-auth">
                <div className="w-50 p-5">
                    <div className="p-5">
                        <div className="py-5"></div>
                        <div>
                            <h2 className="text-dark mb-3">Reset Password</h2>
                            {Object.values(error).some((errMsg) => errMsg) && (
                                <div className="alert alert-danger">
                                    {Object.values(error).map((errMsg, idx) => (
                                        errMsg && <div key={idx}>{errMsg}</div>
                                    ))}
                                </div>
                            )}

                            <div className="form-group">
                                <div className="mb-3">
                                    <SixDigitInput
                                        value={forgotPasswordCode}
                                        onChange={handleForgotPasswordCodeChange}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">
                                        Enter password code from your mail. Time remaining: {formatTime(timer)}
                                    </small>
                                    <br />
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={handleRequestNewCode}
                                        disabled={timer > 0}
                                    >
                                        {timer > 0 ? `Request new code in ${formatTime(timer)}` : "Request new code"}
                                    </button>
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        onChange={handleNewPasswordChange}
                                        className="form-control shadow-none"
                                        id="newPassword"
                                        value={newPassword}
                                        placeholder="New Password"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        onChange={handleReEnterNewPasswordChange}
                                        className="form-control shadow-none"
                                        id="reenterNewPassword"
                                        value={reenterNewPassword}
                                        placeholder="Re-enter New Password"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn secondary-btn w-100 mt-3 mb-2"
                                    onClick={handlePasswordChange}
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section>
    );
};

export default ResetPassword;
