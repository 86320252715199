import instance from "../baseInstance";

export async function getBuildingComponentScheme(pathVariable) {
    const response = await instance.get("/admin/building-component-scheme/" + pathVariable);
    return response.data;
}

export async function getAllBuildingComponentSchemes() {
    const response = await instance.get("/admin/building-component-scheme/find-all");
    return response.data;
}

export async function putBuildingComponentScheme(pathVariable, jsonData) {
    await instance.put("/admin/building-component-scheme/" + pathVariable, jsonData);
}

export async function deleteBuildingComponentScheme(pathVariable) {
    await instance.delete("/admin/building-component-scheme/" + pathVariable);
}

export async function postBuildingComponentScheme(jsonData) {
    await instance.post("/admin/building-component-scheme", jsonData);
}