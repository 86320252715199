import React, { useEffect, useState } from 'react';
import PaymentForm from "../PaymentForm/PaymentForm";
import { getRoleById } from '../../api/admin/role';
import { NotificationService } from '../../helper/notification';


const Payment = ({ role, plan, isFree, isAdd, onSuccess, previousStep }) => {
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const getInfo = async () => {
            try {
                const roleData = await getRoleById(role);
                setSelectedRole(roleData);
                for (let i = 0; i < roleData.subscriptionPlanInfoList.length; i++) {
                    if (roleData.subscriptionPlanInfoList[i].id === plan) {
                        setSelectedPlan(roleData.subscriptionPlanInfoList[i]);
                    }
                }
            } catch (error) {
                NotificationService.error("An error occurred: " + error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isFree) {
            onSuccess();
        } else {
            getInfo();
        }
    }, []);

    return isLoading ? (<></>) : (
        <div className="outerContainer pe-5 me-5">
            <div className="row g-0">
                <div className="col-12 p-4">
                    <h2 className="text-center text-success">Payment Details</h2>
                    <hr />
                    <h5>Selected Role: {selectedRole.name.charAt(0) + selectedRole.name.slice(1).toLowerCase()}</h5>
                    <h5>Selected Plan: {selectedPlan.name.charAt(0) + selectedPlan.name.slice(1).toLowerCase()}</h5>
                    <h5>Price: {selectedPlan.price}</h5>
                    <PaymentForm isTransaction={true} isAdd={isAdd} onSuccess={onSuccess} previousStep={previousStep} />
                </div>
            </div>
        </div>
    );
};

export default Payment;
