import React from 'react';
import { Bar, Line, Radar, Scatter, Bubble } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler,
    ArcElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler,
    ArcElement
);

const MaterialAnalyzeCharts = ({ data }) => {
    const buildingComponents = data.map(component => component.buildingComponent);
    const co2Data = data.map(component =>
        component.groupAnalyzeResponses.map(group => group.totalCo2Emission)
    );

    // Bar Chart Data
    const barChartData = {
        labels: data.map(component => component.buildingComponent + "," + component.type),
        datasets: [
            {
                label: 'Best CO2 Group Analyze',
                data: co2Data.map(group => group[0]), // 0: Best CO2
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
                label: 'Median CO2 Group Analyze',
                data: co2Data.map(group => group[1]), // 1: Median CO2
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
            {
                label: 'Worst CO2 Group Analyze',
                data: co2Data.map(group => group[2]), // 2: Worst CO2
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };



    return (
        <div>
            <h2>Bar Chart</h2>
            <Bar data={barChartData} />


        </div>
    );
};

export default MaterialAnalyzeCharts;
