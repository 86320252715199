import instance from "./baseInstance";

export async function getProjectConstructionsByConstructionId(
  projectId,
  constructionId
) {
  const response = await instance.get(
    "projects/" + projectId + "/constructions/" + constructionId
  );
  return response;
}

export async function getProjectConstructions(projectId) {
  const response = await instance.get(
    "projects/" + projectId + "/constructions"
  );
  return response;
}

export async function addProjectConstruction(pathVariable, jsonData) {
  const response = await instance.post(
    "projects/" + pathVariable + "/constructions",
    jsonData
  );
  return response;
}

export async function updateProjectConstructions(
  pathVariable,
  pathVariable2,
  jsonData
) {
  const response = await instance.put(
    "projects/" + pathVariable + "/constructions/" + pathVariable2,
    jsonData
  );
  return response;
}

export async function deleteProjectConstruction(pathVariable, pathVariable2) {
  const response = await instance.delete("projects/" + pathVariable + "/constructions/" + pathVariable2);
}

export async function fetchAnalyze(projectId, constructionId) {
  const response = await instance.get(
    "projects/" + projectId + "/constructions/" + constructionId + "/analyze"
  );

  return response;
}

export async function fetchGroupAnalyze(projectId, constructionList) {
  const response = await instance.get(
    "projects/" + projectId + "/constructions/analyze-constructions?constructionIdList=" + constructionList
  );

  return response;
}
