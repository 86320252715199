import { useState } from "react";
import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationService } from "../../../helper/notification";
import { getAllPermissions } from "../../../api/admin/permission";
import { getAllRoles, updateRoleById } from "../../../api/admin/role";

const EditRolePermission = () => {
    const [permissions, setPermissions] = useState(null);
    const [roles, setRoles] = useState(null);
    const [selectedRole, setSelectedRole] = useState("-1");
    const [assignedPermissionList, setAssignedPermissionList] = useState([]);
    const [addCheckList, setAddCheckList] = useState([]);
    const [removeCheckList, setRemoveCheckList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const roleData = await getAllRoles();
                setRoles(roleData.slice(1));
                const permissionData = await getAllPermissions();
                setPermissions(permissionData);
            } catch (error) {
                NotificationService.error("An error occurred");
            }
        };
        getData();
    }, []);

    const handleChangeSelectedRole = (id) => {
        setSelectedRole(id);
        if (id === "-1") {
            setAssignedPermissionList([]);
        } else {
            setAssignedPermissionList([...roles[id].permissions].sort((a, b) => a.id - b.id));
            setPermissions(prevItems => prevItems.filter(permission1 => {
                if (roles[id].permissions.length !== 0) {
                    return !roles[id].permissions.some(permission2 => permission1.id === permission2.id)
                } else {
                    return permission1;
                }
            }));
        }
    }

    const toggleAddCheckList = (value) => {
        const selectedItem = permissions[value];
        setAddCheckList((prevItems) => {
            if (prevItems.some(item => item.id === selectedItem.id)) {
                return prevItems.filter(item => item.id !== selectedItem.id);
            } else {
                return [...prevItems, selectedItem];
            }
        })
    }

    const toggleRemoveCheckList = (value) => {
        const selectedItem = assignedPermissionList[value];
        setRemoveCheckList((prevItems) => {
            if (prevItems.some(item => item.id === selectedItem.id)) {
                return prevItems.filter(item => item.id !== selectedItem.id);
            } else {
                return [...prevItems, selectedItem];
            }
        })
    }

    const handleAddButton = () => {
        setAssignedPermissionList([...assignedPermissionList, ...addCheckList]);
        setPermissions((prevItems) => prevItems.filter(item => !addCheckList.find(selected => selected.id === item.id)));
        setAddCheckList([]);
    }

    const handleRemoveButton = () => {
        setAssignedPermissionList((prevItems) => prevItems.filter(item => !removeCheckList.find(selected => selected.id === item.id)));
        setPermissions([...permissions, ...removeCheckList]);
        setRemoveCheckList([]);
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleUpdate = async () => {
        try {
            const role = roles[selectedRole];
            await updateRoleById(role.id, {
                name: role.name,
                description: role.description,
                features: role.features,
                permissionIdList: assignedPermissionList.map(assignedPermission => assignedPermission.id),
                subscriptionPlanInfoList: role.subscriptionPlanInfoList
            });
            NotificationService.success("Selected" + roles[selectedRole].name + "'s is updated successfully!");
            window.location.reload();
        } catch (error) {
            NotificationService.error(error);
        }
    }

    return (
        <body className="bg-body-tertiary">
            <div className="py-5"></div>
            <div className="container mt-5 px-5">
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <h2 className="tertiary-text fw-bold">Permissions</h2>
                </div>
                <div className="bg-white rounded-4 p-5 mb-3">
                    {roles == null ? (
                        <div className="mt-5 d-flex justify-content-center align-items-top pt-5">
                            <ClipLoader
                                loading={roles === null}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    ) : roles.length === 0 ? (
                        <div className="alert alert-info" role="alert">
                            No Roles are found!
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-4">
                                <div className="row mb-3">
                                    <div className="secondary-btn rounded-5 col-1 ps-2 py-1 pe-4 d-flex align-self-center">{selectedRole === "-1" ? <i class="bi bi-circle-fill"></i> : <i class="bi bi-check"></i>}</div>
                                    <h2 className="col-2">01</h2>
                                    <span className="col-7 d-flex align-self-center">Chosen Role</span>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <select className="form-select mb-4" onChange={(event) => handleChangeSelectedRole(event.target.value)}>
                                    <option selected value={"-1"}>Choose a Role</option>
                                    {roles.map((role, index) => (<option value={index}>{role.name}</option>))}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row mb-3">
                    <div className="col-md-5">
                        <h5 className="fw-bold mb-3" style={{ color: "#A9B9C6" }}>Assigned Permissions</h5>
                        <div className="bg-white rounded-4 p-2 mb-3">
                            {roles === null ? (
                                <div className="mt-5 d-flex justify-content-center align-items-center">
                                    <ClipLoader
                                        loading={roles === null}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            ) : assignedPermissionList.length === 0 ? (
                                <div className="alert alert-info" role="alert">
                                    Please select a Subscription Plan
                                </div>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Permission Id</th>
                                                <th scope="col">Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assignedPermissionList.map((permission, index) => (
                                                <tr>
                                                    <th>
                                                        <td><input onChange={(event) => toggleRemoveCheckList(event.target.value)} value={index} checked={removeCheckList.some(item => item.id === permission.id)} type="checkbox" className="form-check" /></td>
                                                        <td>{permission.id}</td>
                                                    </th>
                                                    <td>{permission.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                        <div>
                            <div className="d-flex justify-content-center">
                                <button disabled={addCheckList.length === 0} className="btn secondary-btn mb-3" onClick={handleAddButton}><i class="bi bi-arrow-left" /> Add</button>
                            </div>
                            <button disabled={removeCheckList.length === 0} className="btn btn-danger mb-3" onClick={handleRemoveButton}>Remove <i class="bi bi-arrow-right" /></button>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <h5 className="fw-bold mb-3" style={{ color: "#A9B9C6" }}>Unassigned Permissions</h5>
                        <div className="permissions bg-white rounded-4 p-2">
                            {permissions == null ? (
                                <div className="mt-5 d-flex justify-content-center align-items-center">
                                    <ClipLoader
                                        loading={permissions === null}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            ) : permissions.length === 0 ? (
                                <div className="alert alert-info" role="alert">
                                    No permissions are found!
                                </div>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Permission Id</th>
                                                <th scope="col">Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {permissions.map((permission, index) => (
                                                <tr>
                                                    <th>
                                                        <td><input onChange={(event) => toggleAddCheckList(event.target.value)} value={index} checked={addCheckList.some(item => item.id === permission.id)} type="checkbox" className="form-check" /></td>
                                                        <td>{permission.id}</td>
                                                    </th>
                                                    <td>{permission.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mb-3">
                    <button disabled={assignedPermissionList.length === (selectedRole !== "-1" ? roles[selectedRole]?.permission?.length : 0)} className="btn secondary-btn" onClick={openModal}>Update</button>
                </div>
                {isModalOpen && (
                    <div class="modal show d-block" tabindex="-1">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Update Permissions <br />Role: {roles[selectedRole].name}</h5>
                                    <button type="button" class="btn-close" aria-label="Close" onClick={closeModal}></button>
                                </div>
                                <div class="modal-body">
                                    <p>Are you sure to upload permissions of selected plan of selected role?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" onClick={closeModal}>Close</button>
                                    <button type="button" class="btn btn-primary" onClick={handleUpdate}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </body >
    );
};

export default EditRolePermission;
