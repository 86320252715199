import { useState, useEffect } from "react";
import SubmitDataButton from "../../components/SubmitDataButton/SubmitDataButton";
import {
  createMaterialDataList,
  getMaterialDataInfo,
} from "../../api/materialData";
import { NotificationService } from "../../helper/notification";

const DataSubmission = () => {
  const [materialData, setMaterialData] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const getMaterialData = async () => {
      try {
        const response = await getMaterialDataInfo();

        setMaterialData(response.data.materialDataInfoMap);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };

    getMaterialData();
  }, []);
  const handleSubmit = async () => {
    try {
      await createMaterialDataList(data);
      NotificationService.success(
        "You successfully make the request. We will review your request as soon as possible!"
      );
    } catch (error) {
      NotificationService.error("An error occurred!");
    }
  };

  return (
    <section>
      <div className="py-5"></div>
      <div className="container col-md-6 mt-5">
        <h3 className="py-4">Data Submission</h3>
        <div className="alert alert-info pb-4">
          Submitting accurate and timely data is crucial to our success. Your
          commitment to providing this information enables us to make informed
          decisions, enhance service quality, and drive innovation. By sharing
          your data, you help us strengthen partnerships and ensure compliance,
          ultimately contributing to mutual growth and success. We greatly
          appreciate your dedication to this process and look forward to our
          continued collaboration. Thank you for your cooperation and partnership.
        </div>
        <div>
          {materialData && (
            <SubmitDataButton materialData={materialData} setData={setData} />
          )}
          <button
            className="btn secondary-btn w-100 mt-5 mb-2 fw-bold mb-5"
            onClick={handleSubmit}
          >
            Submit Data
          </button>
        </div>
      </div>
    </section>
  );
};

export default DataSubmission;
