const ProfileBanner = ({ userInfo }) => {

    return (
        <section className="pb-5">
            <div className="bg-profile"></div>
            <div className="px-5 pt-5">
                <div className="d-flex">
                    <h5>{userInfo.firstname} {userInfo.lastname}</h5>
                    {userInfo.roleName && <div className="rounded-4 primary-bg secondary-text ms-5 px-3">{userInfo.roleName[0] + userInfo.roleName.slice(1).toLowerCase()}</div>}
                </div>
                <small className="text-secondary">{userInfo.email}</small>
            </div>
        </section>
    );
}

export default ProfileBanner;