import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../../helper/auth";
import ClipLoader from "react-spinners/ClipLoader";
import logo from './logo.png';

const Invited = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const projectInviteToken = queryParams.get("");
    const [isAuth, setIsAuth] = useState(isAuthenticated());
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("projectInviteToken", projectInviteToken);
        setIsAuth(isAuthenticated());
        const timer = setTimeout(() => {
            if (isAuthenticated) {
                navigate("/projects");
            }
            else {
                navigate("/login");
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-5">
            <div className="d-flex justify-content-center p-5">
                <img src={logo} alt="logo" width={"25%"} />
            </div>
            <h3 className="secondary-text text-center"><i className="bi bi-check-circle-fill" style={{ fontSize: '5rem' }}></i></h3>
            <h2 className="text-center">Project Invitation</h2>
            <h5 className="text-center py-3">Your invitation link is checking.</h5>
            <h5 className="text-center pb-3">Now you are being redirected to the login page.</h5>
            <h5 className="text-center pb-5">After login/sign-up process you can access the project.</h5>
            <div className="d-flex justify-content-center pb-3">
                <ClipLoader
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div >
    );
}

export default Invited;