import React from 'react';
import { Chart, registerables } from 'chart.js';
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Ensure you have this package for autoTable

Chart.register(...registerables);

const ExportReport = ({ data }) => {
    const { constructionName, groupAnalyzeResponses } = data;

    const generatePDF = async () => {
        const doc = new jsPDF();

        // Add Title
        doc.setFontSize(20);
        doc.text(`Analysis Report for ${constructionName}`, 20, 20);
        doc.addPage(); // Start with a new page for the title

        for (let index = 0; index < groupAnalyzeResponses.length; index++) {
            const group = groupAnalyzeResponses[index];
            const chartData = {
                labels: group.buildingComponentAnalyzeResponses.map(component => component.buildingComponent),
                datasets: [
                    {
                        label: 'Total CO2 Emission',
                        data: group.buildingComponentAnalyzeResponses.map(component => component.totalCo2Emission),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    },
                    {
                        label: 'Total Energy Efficiency',
                        data: group.buildingComponentAnalyzeResponses.map(component => component.totalEnergyEfficiency),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    },
                ],
            };

            // Create and render the chart
            const canvas = document.createElement('canvas');
            canvas.width = 600; // Set canvas width
            canvas.height = 400; // Set canvas height
            const context = canvas.getContext('2d');

            if (!context) {
                console.error('Failed to get canvas context');
                return;
            }

            // Create the chart using Chart.js
            new Chart(context, {
                type: 'bar',
                data: chartData,
                options: {
                    responsive: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });

            // Wait for a short delay to ensure the chart is rendered
            await new Promise((resolve) => {
                setTimeout(() => {
                    const imgData = canvas.toDataURL('image/png');
                    console.log(imgData); // Log the image data URL for debugging

                    // Ensure the image data is valid before adding to the PDF
                    if (imgData) {
                        doc.addImage(imgData, 'PNG', 20, 40, 180, 80);
                        doc.addPage(); // Add a new page after each chart
                    } else {
                        console.error('Image data is invalid');
                    }
                    resolve();
                }, 100); // Delay to ensure the chart is rendered
            });
        }

        // Add a new page for the table
        doc.addPage();

        // Add table of group analyze responses
        const tableColumn = ["Group Name", "Total CO2 Emission", "Total Energy Efficiency"];
        const tableRows = groupAnalyzeResponses.map(group => [
            group.name,
            group.totalCo2Emission,
            group.totalEnergyEfficiency,
        ]);

        // Add table to PDF
        doc.autoTable(tableColumn, tableRows, { startY: 20 });

        // Save the PDF
        doc.save(`${constructionName}_report.pdf`);
    };

    return (
        <div>
            <button className="btn btn-outline-dark rounded-4" onClick={generatePDF}>
                <i className="bi bi-file-earmark-arrow-down" /> Export Report
            </button>
        </div>
    );
};

export default ExportReport;
