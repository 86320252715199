import React, { useEffect, useState } from "react";
import { deleteBuildingComponentScheme, getAllBuildingComponentSchemes, postBuildingComponentScheme, putBuildingComponentScheme } from "../../../api/admin/buildingComponentScheme";
import { NotificationService } from "../../../helper/notification";
import { ClipLoader } from "react-spinners";

const BuildingComponentScheme = () => {
    const [buildingComponentSchemes, setBuildingComponentSchemes] = useState(null);

    const [name, setName] = useState('');
    const [typeNames, setTypeNames] = useState([]);

    const [updateId, setUpdateId] = useState(0);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        const getBuildingComponentSchemes = async () => {
            try {
                const response = await getAllBuildingComponentSchemes();
                setBuildingComponentSchemes(response);
            } catch (error) {
                NotificationService.error("An error occured!");
            }
        }

        getBuildingComponentSchemes();
    }, []);

    const createBuildingComponentScheme = async () => {
        try {
            await postBuildingComponentScheme({
                name: name,
                typeNames: typeNames
            });
            window.location.reload();
        } catch (error) {
            NotificationService.error("An error occured!");
        }

    }

    const updateBuildingComponentScheme = async () => {
        await putBuildingComponentScheme(buildingComponentSchemes[updateId].id, {
            name: name,
            typeNames: typeNames
        });
        window.location.reload();
    }

    const removeBuildingComponentScheme = async () => {
        await deleteBuildingComponentScheme(buildingComponentSchemes[updateId].id);
        window.location.reload();
    }


    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleTypeNameChange = (index, value) => {
        const updatedTypeNames = [...typeNames];
        updatedTypeNames[index] = value;
        setTypeNames(updatedTypeNames);
    };

    const addTypeName = () => {
        setTypeNames([...typeNames, '']);
    };

    const removeTypeName = (index) => {
        setTypeNames(typeNames.filter((_, i) => i !== index));
    };



    const openCreateModal = () => {
        setIsCreateModalOpen(true);
        setName("");
        setTypeNames([""]);
    }

    const closeCreateModal = () => {
        setIsCreateModalOpen(false);
    }

    const openUpdateModal = (id) => {
        setUpdateId(id);
        setName(buildingComponentSchemes[id].name);
        setTypeNames(buildingComponentSchemes[id].typeNames);
        setIsUpdateModalOpen(true);

    }

    const closeUpdateModal = () => {
        setUpdateId(0);
        setIsUpdateModalOpen(false);
    }

    const openDeleteModal = (id) => {
        setUpdateId(id);
        setIsDeleteModalOpen(true);
    }

    const closeDeleteModal = () => {
        setUpdateId(0);
        setIsDeleteModalOpen(false);
    }

    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    return (
        <body className="bg-body-tertiary">
            <div className="py-5"></div>
            <div className="container mt-5 px-5">
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <h2 className="tertiary-text fw-bold">Building Component Scheme</h2>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn secondary-btn fw-bold mx-1 my-3"
                            onClick={openCreateModal}
                        >
                            Add New Building Component Scheme
                        </button>
                    </div>
                </div>
                <div className="bg-white rounded-4 p-5">
                    {buildingComponentSchemes === null ? (
                        <ClipLoader
                            loading={buildingComponentSchemes === null}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    ) : (
                        <div>
                            <div className=" d-flex mb-3">
                                <div className="col-2">Id</div>
                                <div className="col-7">Name</div>
                                <div className="col-2">Actions</div>
                                <div className="col-1"></div>
                            </div>
                            <div className="">
                                {buildingComponentSchemes.map((buildingComponentScheme, index) => (
                                    <>
                                        <div className="row rounded-4 mb-3 py-3" style={{ backgroundColor: '#EEF4F3' }}>
                                            <div className="col-2">{buildingComponentScheme.id}</div>
                                            <div className="col-7">{buildingComponentScheme.name}</div>
                                            <div className="col-2 d-flex">
                                                <i onClick={() => openUpdateModal(index)} className="bi bi-pencil-square" />
                                                <i onClick={() => openDeleteModal(index)} className="bi bi-trash ps-3" />
                                            </div>
                                            <div className="col-1"><i onClick={() => toggleRow(buildingComponentScheme.id)} className={`bi ${expandedRow === buildingComponentScheme.id ? 'bi-chevron-up' : 'bi-chevron-down'}`} /></div>
                                        </div>
                                        {expandedRow === buildingComponentScheme.id && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul>
                                                        {buildingComponentScheme.typeNames.map((typeName, index) => (
                                                            <li key={index}>{typeName}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>)
                    }
                </div>

            </div>
            {
                isCreateModalOpen &&
                <div class="modal show d-block">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Create Building Component Scheme</h5>
                                <button type="button" class="btn-close" onClick={closeCreateModal} aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <form>
                                    <div className="d-flex pb-3">
                                        <label className="d-flex align-self-center" htmlFor="name">Name:</label>
                                        <input
                                            className="form-control ms-5"
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder="Building Component Name"
                                        />
                                    </div>

                                    <div>
                                        <label className="pb-3">Type Names:</label>
                                        {typeNames.map((typeName, index) => (
                                            <div key={index} style={{ display: 'flex', marginBottom: '8px' }}>
                                                <input
                                                    className="form-control me-5"
                                                    type="text"
                                                    value={typeName}
                                                    onChange={(e) => handleTypeNameChange(index, e.target.value)}
                                                    placeholder={"Building Component Type Name"}
                                                />
                                                <button className="btn btn-danger" type="button" onClick={() => removeTypeName(index)}><i class="bi bi-trash" /></button>
                                            </div>
                                        ))}
                                        <div className="d-flex justify-content-end">
                                            <button className="btn secondary-btn" type="button" onClick={addTypeName}><i class="bi bi-plus-circle" /></button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" onClick={closeCreateModal}>Close</button>
                                <button
                                    type="button"
                                    class="btn secondary-btn"
                                    disabled={(name === "" || typeNames.length === 0)}
                                    onClick={createBuildingComponentScheme}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isUpdateModalOpen &&
                <div class="modal show d-block">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Update {buildingComponentSchemes[updateId].name}</h5>
                                <button type="button" class="btn-close" onClick={closeUpdateModal} aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <form>
                                    <div className="d-flex pb-3">
                                        <label className="d-flex align-self-center" htmlFor="name">Name:</label>
                                        <input
                                            className="form-control ms-5"
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder="Building Component Name"
                                        />
                                    </div>

                                    <div>
                                        <label className="pb-3">Type Names:</label>
                                        {typeNames.map((typeName, index) => (
                                            <div key={index} style={{ display: 'flex', marginBottom: '8px' }}>
                                                <input
                                                    className="form-control me-5"
                                                    type="text"
                                                    value={typeName}
                                                    onChange={(e) => handleTypeNameChange(index, e.target.value)}
                                                    placeholder={"Building Component Type Name"}
                                                />
                                                <button className="btn btn-danger" type="button" onClick={() => removeTypeName(index)}><i class="bi bi-trash" /></button>
                                            </div>
                                        ))}
                                        <div className="d-flex justify-content-end">
                                            <button className="btn secondary-btn" type="button" onClick={addTypeName}><i class="bi bi-plus-circle" /></button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" onClick={closeUpdateModal}>Close</button>
                                <button
                                    type="button"
                                    class="btn secondary-btn"
                                    disabled={(name === "" || typeNames.length === 0)}
                                    onClick={updateBuildingComponentScheme}>Update {buildingComponentSchemes[updateId].name}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isDeleteModalOpen &&
                <div class="modal show d-block">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Delete {buildingComponentSchemes[updateId].name}</h5>
                                <button type="button" class="btn-close" onClick={closeDeleteModal} aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                Are you sure you want to delete {buildingComponentSchemes[updateId].name}?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" onClick={closeDeleteModal}>Close</button>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    onClick={removeBuildingComponentScheme}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </body >
    );
}

export default BuildingComponentScheme;