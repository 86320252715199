import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const DashboardCard = ({ title, destination, content }) => {

    const navigate = useNavigate();

    return (
        <div className="bg-white border border-2 shadow-sm rounded-4 p-3 mb-3">
            <div className="d-flex justify-content-between">
                <span className="fw-bold pe-3">{title}</span>
                {destination !== null ?
                    (<button
                        className="btn secondary-btn rounded-4 ms-3"
                        onClick={() => navigate(destination)}
                    >See All</button>
                    ) : (<></>)
                }
            </div>
            {content}
        </div>
    );
}

export default DashboardCard;