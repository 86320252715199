import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const StackedBarChartMaterial = ({ data, header }) => {
  const chartRef = useRef(null);
  const labels = data.map((item) => item.name);

  const predefinedColors = [
    "#127E87",  // Teal
    "#A5A5A5",  // Gray
    "#223E54",  // Dark Blue
    "#F05D23",  // Orange
    "#6A4C93",  // Purple
    "#2E8B57",  // Green
    "#D9534F",  // Red
    "#F0AD4E",  // Yellow
  ];

  // Create a color array with variations based on predefined colors
  const generateColorPalette = () => {
    const colors = [];
    predefinedColors.forEach((color, index) => {
      const lighterColor = `${color}80`; // Lighten the color by adding transparency
      const darkerColor = `${color}CC`; // Darken the color slightly
      colors.push(darkerColor);
    });
    return colors;
  };

  const colorPalette = generateColorPalette();

  const uniqueTypes = [
    ...new Set(
        data.flatMap((group) =>
            group.buildingComponentAnalyzeResponses.flatMap((se) =>
                se.typeAnalyzeResponses.map((typeResp) => typeResp.type)
            )
        )
    ),
  ];

  const barDatasets = uniqueTypes.map((type, typeIndex) => {
    return {
      type: "bar",
      label: `${type} CO2 Emission (kgCO2-eq)`,
      data: data.map((group) =>
          group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
            const typeData = se.typeAnalyzeResponses.find(
                (tr) => tr.type === type
            );
            return sum + (typeData ? typeData.totalCo2Emission : 0);
          }, 0)
      ),
      backgroundColor: colorPalette[typeIndex % colorPalette.length], // Use the color palette
      yAxisID: "y1",
    };
  });

  const totalEnergyEfficiency = data.map((group) => {
    const totalEfficiency = group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
      return (
          sum +
          se.typeAnalyzeResponses.reduce((typeSum, tr) => {
            return typeSum + (1 / (tr.totalEnergyEfficiency) || 0);
          }, 0)
      );
    }, 0);
    return totalEfficiency !== 0 ? 1 / totalEfficiency : 0;
  });

  const lineDataset = {
    type: "line",
    label: "Total Energy Efficiency (W/m2K)",
    data: totalEnergyEfficiency,
    borderColor: "#0000FF", // Blue line color
    backgroundColor: "rgba(0, 0, 255, 0.5)", // Blue fill color
    fill: false,
    yAxisID: "y2",
  };

  const chartData = {
    labels,
    datasets: [...barDatasets, lineDataset],
  };

  const options = {
    aspectRatio: 3, // Set aspect ratio to control width/height ratio
    plugins: {
      title: {
        display: true,
        text: header || "Combined Bar and Line Chart",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        position: "top",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y1: {
        type: "linear",
        display: true,
        position: "left",
        stacked: true,
        title: {
          display: true,
          text: "CO2 Emission (kgCO2-eq)",
        },
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        stacked: false,
        title: {
          display: true,
          text: "Total Energy Efficiency (W/m2K)",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const handleExport = () => {
    const chart = chartRef.current;
    if (chart) {
      const url = chart.toBase64Image();
      const link = document.createElement("a");
      link.href = url;
      link.download = "StackedBarChartMaterial.png";
      link.click();
    }
  };

  return (
      <>
        <div className="d-flex justify-content-end pt-3">
          <button className="btn btn-outline-dark" onClick={handleExport}>
            <i className="bi bi-box-arrow-right" /> &nbsp; Export Graph
          </button>
        </div>
        <Bar ref={chartRef} data={chartData} options={options} style={{ marginBottom: "5px", width: '10%', height: '100px' }} />
      </>
  );
};

export default StackedBarChartMaterial;
