import React, { useState, useEffect } from "react";

const SixDigitInput = ({ value, onChange }) => {
    const [inputValues, setInputValues] = useState(Array(6).fill(""));

    useEffect(() => {
        onChange(inputValues.join(""));
    }, [inputValues]);

    const handleChange = (e, index) => {
        const newValue = e.target.value;
        if (/^\d$/.test(newValue) || newValue === "") {
            const newInputValues = [...inputValues];
            newInputValues[index] = newValue;
            setInputValues(newInputValues);

            if (newValue !== "" && index < 5) {
                document.getElementById(`digit-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && inputValues[index] === "" && index > 0) {
            document.getElementById(`digit-${index - 1}`).focus();
        }
    };

    return (
        <div className="six-digit-input">
            {inputValues.map((digit, index) => (
                <input
                    key={index}
                    id={`digit-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="six-digit-input-field"
                    style={{ width: "2rem", marginRight: "0.5rem", textAlign: "center" }}
                />
            ))}
        </div>
    );
};

export default SixDigitInput;
