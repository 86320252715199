import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import SideNavbar from "./components/SideNavbar/SideNavbar";
import Footer from "./components/Footer/Footer";
import { getUserInfo, isAuthenticated } from "./helper/auth";

const MainLayout = () => {
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  const userInfoResponse = getUserInfo();

  const showSideNavbar =
      location.pathname.startsWith("/dashboard") ||
      location.pathname.startsWith("/profile");
  const showFooter = true;

  useEffect(() => {
    setIsAuth(isAuthenticated());

    if (location.pathname === "/") {
      if (isAuth) {
        if (userInfoResponse.roleName === "ADMIN") {
          navigate("/dashboard");
        } else {
          navigate("/projects");
        }
      } else {
        navigate("/");
      }
    }
  }, [isAuth, location.pathname, navigate]);

  return (
      <div className="app-container">
        {/* Navbar üstte */}
        <Navbar />

        {/* İçerik ve SideNavbar alanı */}
        <div className="main-content d-flex flex-grow-1">
          {showSideNavbar && (
              <div className="side-navbar col-2">
                <SideNavbar />
              </div>
          )}
          <div className={`content ${showSideNavbar ? "col-10" : "col-12"}`}>
            <Outlet />
          </div>
        </div>

        {/* Footer */}
        {showFooter && <Footer />}
      </div>
  );
};

export default MainLayout;
