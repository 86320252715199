import { useState } from "react";
import "./Background.css";
import about_image_2 from "./about-image-2.png";
import about_image_4 from "./about-image-4.png";
import about_image_5 from "./about-image-5.png";
import about_icon_1 from "./about-icon-1.png";
import { useNavigate } from "react-router-dom";
import { InsideBanner } from "../../../components/LandingPageComponents/InsideBanner/InsideBanner";
import { InsideAction } from "../../../components/LandingPageComponents/InsideAction/InsideAction";
import { Banner } from "../../../components/LandingPageComponents/Banner/Banner";
import {useTranslation} from "react-i18next";



const About = () => {
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const {t, i18n} = useTranslation();


    return (
        <>
            <section>
                <Banner page={"about-us"} />
            </section>
            <section className="px-3">
                <div className="container">

                    <div className="row d-flex justify-content-center align-items-center mb-3">
                        <div className={"col-md-6"}>
                            <h2 className="fw-bold">{t('ROR360 Vision')} <span className={"secondary-text"}>{t('ROR360 Vision2')}</span></h2>
                            <div className=" mb-3">
                                <p>{t('At ROR360, our vision is to lead the construction industry towards a sustainable future by harnessing innovative data and actionable insights, ensuring every project contributes to a greener planet.')}</p>
                            </div>

                            <h2 className="fw-bold">{t('ROR360 Mission')} <span className={"secondary-text"}>{t('ROR360 Mission2')}</span></h2>
                            <div className="">
                                <p>{t('Our mission is to lead the construction industry towards a sustainable future by equipping professionals with carbon emission analysis and material comparison.')}</p>
                                <div className="pt-3 ps-2">

                                    <div>
                                        <div className={"row"}>
                                            <div
                                                className="clickable col-md-12 d-flex justify-content-between align-items-center"
                                                onClick={() => setShow1(!show1)}>
                                                <div className="secondary-btn  py-2 col-md-1"><h2>1</h2></div>
                                                <div className={"col-md-10 mx-4"}>
                                                    <h4 className="fw-bold"
                                                        style={{letterSpacing: "1px"}}>{t('Choose sustainable Materials')}</h4>

                                                </div>
                                                <div className={"col-md-1"}>
                                                    <i className={`bi ${show1 ? 'bi-chevron-up' : 'bi-chevron-down'}`}/>

                                                </div>
                                            </div>
                                            {show1 && (<>
                                                    <div className="py-2 col-md-1"></div>
                                                    <div className={"col-md-10 mx-4"}>

                                                        <div className="">
                                                            <p className="">{t('Utilize advanced data analytics tools to accurately measure, monitor, and reduce carbon emissions throughout the construction process.')}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-1"}>

                                                    </div>
                                                </>
                                            )}

                                        </div>

                                        <hr/>
                                        <div className={"row"}>
                                            <div
                                                className="clickable col-md-12 d-flex justify-content-between align-items-center"
                                                onClick={() => setShow2(!show2)}>
                                                <div className="secondary-btn  py-2 col-md-1"><h2>2</h2></div>
                                                <div className={"col-md-10 mx-4"}>
                                                    <h4 className="fw-bold"
                                                        style={{letterSpacing: "1px"}}>{t('Leverage Data Analytics')}</h4>

                                                </div>
                                                <div className={"col-md-1"}>
                                                    <i className={`bi ${show2 ? 'bi-chevron-up' : 'bi-chevron-down'}`}/>

                                                </div>
                                            </div>
                                            {show2 && (<>
                                                    <div className="py-2 col-md-1"></div>
                                                    <div className={"col-md-10 mx-4"}>

                                                        <div className="">
                                                            <p className="">{t('Utilize advanced data analytics tools to accurately measure, monitor, and reduce carbon emissions throughout the construction process.')}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-1"}>

                                                    </div>
                                                </>
                                            )}

                                        </div>
                                        <hr/>
                                        <div className={"row"}>
                                            <div
                                                className="clickable col-md-12 d-flex justify-content-between align-items-center"
                                                onClick={() => setShow3(!show3)}>
                                                <div className="secondary-btn  py-2 col-md-1"><h2>3</h2></div>
                                                <div className={"col-md-10 mx-4"}>
                                                    <h4 className="fw-bold"
                                                        style={{letterSpacing: "1px"}}>{t('Prioritize Energy Efficiency')}</h4>

                                                </div>
                                                <div className={"col-md-1"}>
                                                    <i className={`bi ${show3 ? 'bi-chevron-up' : 'bi-chevron-down'}`}/>

                                                </div>
                                            </div>
                                            {show3 && (<>
                                                    <div className="py-2 col-md-1"></div>
                                                    <div className={"col-md-10 mx-4"}>

                                                        <div className="">
                                                            <p className="">{t('Utilize advanced data analytics tools to accurately measure, monitor, and reduce carbon emissions throughout the construction process.')}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-1"}>

                                                    </div>
                                                </>
                                            )}

                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-6 px-5"}>
                            <img className="d-flex align-items-center justify-content-center"
                                 style={{borderRadius: "10px"}}
                                 src={about_image_2}
                                 width={"100%"}/>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-4">
                <InsideAction/>
            </section>
        </>
    );
}

export default About;
