import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import "./BarChart.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const HorizontalBarChart = ({ data }) => {
    const chartRefs = useRef([]);

    // Predefined base colors for the chart (feel free to modify these)
    const predefinedColors = [
        "#127E87",  // Teal
        "#A5A5A5",  // Gray
        "#223E54",  // Dark Blue
        "#F05D23",  // Orange
        "#6A4C93",  // Purple
        "#2E8B57",  // Green
        "#D9534F",  // Red
        "#F0AD4E",  // Yellow
    ];

    // Create a color palette with variations based on the predefined colors
    const generateColorPalette = () => {
        const colors = [];
        predefinedColors.forEach((color) => {
            const lighterColor = `${color}80`; // Lighten the color by adding transparency
            const darkerColor = `${color}CC`; // Darken the color slightly
            colors.push(darkerColor); // Add both lighter and darker versions
        });
        return colors;
    };

    const colorPalette = generateColorPalette();

    // Handle export to PNG
    const handleExport = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef) {
                const url = chartRef.toBase64Image();
                const link = document.createElement('a');
                link.href = url;
                link.download = `HorizontalBarChart-${index + 1}.png`; // Customize the file name
                link.click();
            }
        });
    };

    return (
        <>
            <div className="d-flex justify-content-end pt-3">
                <button className="btn btn-outline-dark" onClick={handleExport}>
                    <i className="bi bi-box-arrow-right" /> &nbsp; Export Graph
                </button>
            </div>

            <div className="bar-charts-container">
                {data.map((group, groupIndex) => {
                    const uniqueTypes = [
                        ...new Set(
                            group.buildingComponentAnalyzeResponses.flatMap((se) =>
                                se.buildingComponent
                            )
                        ),
                    ];

                    const totalEmissionsByType = uniqueTypes.map((type) => {
                        return group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
                            const typeData = se.buildingComponent === type;
                            return sum + (typeData ? se.totalCo2Emission : 0);
                        }, 0);
                    });

                    const barChartData = {
                        labels: uniqueTypes,
                        datasets: [
                            {
                                label: "CO2 Emission (kgCO2-eq)",
                                data: totalEmissionsByType,
                                backgroundColor: uniqueTypes.map(
                                    (_, index) => colorPalette[index % colorPalette.length] // Apply color palette
                                ),
                                borderColor: uniqueTypes.map(
                                    (_, index) => `${colorPalette[index % colorPalette.length]}CC` // Apply color palette border
                                ),
                                borderWidth: 1,
                            },
                        ],
                    };

                    const options = {
                        indexAxis: 'y',
                        plugins: {
                            title: {
                                display: true,
                                text: `${group.name} - CO2 Emissions by Type`,
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)} kgCO2-eq`;
                                    },
                                },
                            },
                            legend: {
                                position: "top",
                            },
                        },
                        responsive: true,
                    };

                    return (
                        <div key={groupIndex} className="bar-chart-item">
                            <Bar ref={(el) => chartRefs.current[groupIndex] = el} data={barChartData} options={options} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default HorizontalBarChart;
