import React, { useRef } from "react";
import { Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import "./PieChart.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const GroupPieChart = ({ data }) => {
    const chartRefs = useRef([]);

    const handleExport = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef) {
                const url = chartRef.toBase64Image();
                const link = document.createElement('a');
                link.href = url;
                link.download = `GroupPieChart-${index + 1}.png`; // Customize the file name
                link.click();
            }
        });
    };

    // Helper function to aggregate CO2 emissions by constructionName and groupName
    const aggregateData = (groupName) => {
        const aggregatedData = {};

        data.forEach((item) => {
            const constructionName = item.constructionName;
            const group = item.groupAnalyzeResponses.find((g) => g.name === groupName);

            if (group) {
                // Directly use totalCo2Emission from groupAnalyzeResponses
                const label = constructionName;
                if (!aggregatedData[label]) {
                    aggregatedData[label] = 0;
                }
                aggregatedData[label] += group.totalCo2Emission;
            }
        });

        // Convert aggregatedData to chart-friendly format
        const labels = Object.keys(aggregatedData);
        const dataValues = labels.map((label) => aggregatedData[label]);

        return {
            labels,
            datasets: [
                {
                    label: "CO2 Emission (kgCO2-eq)",
                    data: dataValues,
                    backgroundColor: labels.map(
                        (_, index) =>
                            `rgba(${(index * 50) % 255}, ${(index * 80) % 255}, ${(index * 100) % 255}, 0.5)`
                    ),
                    borderColor: labels.map(
                        (_, index) =>
                            `rgba(${(index * 50) % 255}, ${(index * 80) % 255}, ${(index * 100) % 255}, 1)`
                    ),
                    borderWidth: 1,
                },
            ],
        };
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "CO2 Emissions by Construction Name",
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)} kgCO2-eq`;
                    },
                },
            },
            legend: {
                position: "top",
            },
        },
        responsive: true,
    };

    // Group names for which pie charts will be generated
    const pieChartNames = [
        "Best Co2 Group Analyze",
        "Median Co2 Group Analyze",
        "Worst Co2 Group Analyze",
    ];

    return (
        <>
            <div className="d-flex justify-content-end pt-3">
                <button className="btn btn-outline-dark" onClick={handleExport}>
                    <i className="bi bi-box-arrow-right" /> &nbsp; Export Graph
                </button>
            </div>
            <div className="pie-charts-container">
                {pieChartNames.map((name, index) => {
                    const pieChartData = aggregateData(name);
                    return (
                        <div ref={(el) => (chartRefs.current[index] = el)} key={index} className="pie-chart-item">
                            <Pie data={pieChartData} options={options} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default GroupPieChart;
