import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import logo from './logo.png';
import { saveUserInfo, signInProcess } from "../../../api/auth";
import { getEmail, getPassword } from "../../../helper/auth";
import { NotificationService } from "../../../helper/notification";

const Verificated = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogin = async () => {
            const email = getEmail();
            const password = getPassword();
            try {
                await signInProcess({
                    email,
                    password,
                });
                localStorage.removeItem("email");
                localStorage.removeItem("password");

                await saveUserInfo();
                window.dispatchEvent(new Event("storage"));
                navigate("/projects");
                NotificationService.success("You logged in successfully!");
            } catch (error) {
                NotificationService.error("An error occurred");
            }
        };

        const timer = setTimeout(() => {
            handleLogin();
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-5">
            <div className="d-flex justify-content-center p-5">
                <img src={logo} alt="logo" width={"25%"} />
            </div>
            <div className="d-flex justify-content-center">
                <h3 className="text-success"><i className="bi bi-check-circle-fill" style={{ fontSize: '5rem' }}></i></h3>
            </div>
            <div className="d-flex justify-content-center p-3">
                <h2>Email Verification</h2>
            </div>
            <div className="d-flex justify-content-center pt-3">
                <h5>Your email verified. </h5>
            </div>
            <div className="d-flex justify-content-center pb-3">
                <h5>Now you are being redirected to the projects page.</h5>
            </div>
            <div className="d-flex justify-content-center pb-3">
                <ClipLoader
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div >
    );
}

export default Verificated;