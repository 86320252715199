import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = ({ accountData }) => {

    const getPlanColor = (planName) => {
        switch (planName) {
            case 'FREE':
                return '#127E87';
            case 'BASIC':
                return '#223E54';
            case 'PROFESSIONAL':
                return '#FFE084';
            default:
                return '#ccc';
        }
    };

    const roles = accountData.map(role => role.roleName);
    const planCounts = {};

    accountData.forEach(role => {
        role.getAccountsByAccountTypeDTO.forEach(plan => {
            if (!planCounts[plan.planName]) {
                planCounts[plan.planName] = Array(roles.length).fill(0);
            }
            const roleIndex = roles.indexOf(role.roleName);
            planCounts[plan.planName][roleIndex] += plan.count;
        });
    });

    const data = {
        labels: roles,
        datasets: Object.keys(planCounts).map(planName => ({
            label: planName,
            data: planCounts[planName],
            backgroundColor: getPlanColor(planName),
        })),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        const planName = tooltipItem.dataset.label;
                        const count = tooltipItem.raw;
                        return `${planName}: ${count}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;