import { useState } from "react";
import { changePassword } from "../../../../api/auth";
import { NotificationService } from "../../../../helper/notification";

const ChangePassword = ({ close }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reenterNewPassword, setReEnterNewPassword] = useState("");
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    reenterNewPassword: ""
  });

  const handlePasswordChange = async () => {
    if (newPassword !== reenterNewPassword) {
      setError((prev) => ({
        ...prev,
        reenterNewPassword: "Şifreler uyuşmuyor."
      }));
      return;
    }

    if (!validatePassword(newPassword)) {
      setError((prev) => ({
        ...prev,
        newPassword: "Şifre en az 8 karakter uzunluğunda, bir küçük harf, bir büyük harf, bir rakam ve bir özel karakter içermelidir."
      }));
      return;
    }

    try {
      await changePassword({
        currentPassword: oldPassword,
        newPassword,
        confirmationPassword: reenterNewPassword,
      });

      NotificationService.success("Password changed successfully!");
      close();
    } catch (error) {
      setError((prev) => ({
        ...prev,
        oldPassword: "Şifre değiştirme sırasında bir hata oluştu."
      }));
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
    return regex.test(password);
  };

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setError((prev) => ({
      ...prev,
      oldPassword: event.target.value ? "" : "Eski şifre boş bırakılamaz."
    }));
  };

  const handleNewPasswordChange = (event) => {
    const value = event.target.value;
    setNewPassword(value);
    setError((prev) => ({
      ...prev,
      newPassword: validatePassword(value) ? "" : "Şifre en az 8 karakter uzunluğunda, bir küçük harf, bir büyük harf, bir rakam ve bir özel karakter içermelidir."
    }));
  };

  const handleReEnterNewPasswordChange = (event) => {
    const value = event.target.value;
    setReEnterNewPassword(value);
    setError((prev) => ({
      ...prev,
      reenterNewPassword: value === newPassword ? "" : "Şifreler uyuşmuyor."
    }));
  };

  return (
    <section className="pb-5">
      <div>
        {Object.values(error).some((errMsg) => errMsg) && (
          <div className="alert alert-danger">
            {Object.values(error).map((errMsg, idx) => (
              errMsg && <div key={idx}>{errMsg}</div>
            ))}
          </div>
        )}

        <div className="form-group">
          <div className="mb-3">
            <input
              type="password"
              onChange={handleOldPasswordChange}
              className="form-control shadow-none w-100"
              id="oldPassword"
              value={oldPassword}
              placeholder="Old Password"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              onChange={handleNewPasswordChange}
              className="form-control shadow-none"
              id="newPassword"
              value={newPassword}
              placeholder="New Password"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              onChange={handleReEnterNewPasswordChange}
              className="form-control shadow-none"
              id="reenterNewPassword"
              value={reenterNewPassword}
              placeholder="Re-enter New Password"
            />
          </div>
          <button
            type="submit"
            className="btn secondary-btn w-100 mt-3 mb-2"
            onClick={handlePasswordChange}
          >
            Change Password
          </button>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
