/*
  This file is a connection file created to generally connect to the API created on the backend side of the project. 
  It provides general information for connecting to other files. If there is a change in the API's location, 
  the required location can be entered here to re-establish the connection to the API for all other operations. 
  It also checks the location from which the token was obtained and general information.
*/

// Created By Halit Kaan Kaçar
// Axios request is used for this project
import axios from "axios";
// History is collected to control user permission to get pages
import { history } from "../helper/navigate";

// Created By Halit Kaan Kaçar
// The place of saving the general location of project API
const instance = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "http://localhost:8080/api/v1/"
            : "https://ror360-backend.azurewebsites.net/api/v1/",
    timeout: 10000,
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error);
        if (error.response && error.response.status === 403) {
            localStorage.clear();
            history.push("/login");
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default instance;
