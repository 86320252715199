import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";

import Home from "./pages/LandingPages/Home/Home";
import About from "./pages/LandingPages/About/About";
import Pricing from "./pages/LandingPages/Pricing/Pricing";
import Resources from "./pages/LandingPages/Resources/Resources";
import Contact from "./pages/LandingPages/Contact/Contact";
import DataSubmission from "./pages/DataSubmission/DataSubmission";
import SignUp from "./pages/Auth/SignUp/SignUp";
import Login from "./pages/Auth/Login/Login";
import Verificated from "./pages/Auth/Verification/Verificated";
import VerificationRequest from "./pages/Auth/Verification/VerificationRequest";
import ForgotPasswordRequest from "./pages/Auth/ForgotPassword/ForgotPasswordRequest";
import ResetPassword from "./pages/Auth/ForgotPassword/ResetPassword";
import ProfileSettings from "./pages/Auth/Profile/ProfileSettings/ProfileSettings.jsx";
import Security from "./pages/Auth/Profile/Security/Security.jsx";
import Notification from "./pages/Auth/Profile/Notification/Notification.jsx";
import GetSupport from "./pages/Auth/Profile/SupportRequest/GetSupport.jsx";
import './i18n'

import "./styles/colors.scss";
import "./styles/css/container.css";
import "./styles/css/button.css";

import { ProjectList } from "./pages/Project/ProjectList/ProjectList";
import { ProjectDetails } from "./pages/Project/ProjectDetails/ProjectDetails";
import { Analyze } from "./pages/Project/Analyze/Analyze.js";
import { GroupAnalyze } from "./pages/Project/GroupAnalyze/GroupAnalyze";
import MainLayout from "./MainLayout"; // Import the MainLayout component
import ChangePassword from "./pages/Auth/Profile/ChangePassword/ChangePassword.jsx";
import { MaterialList } from "./pages/Project/MaterialList/MaterialList";
import { ToastContainer } from "react-toastify";
import { history } from "./helper/navigate";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import UserList from "./pages/Admin/UserList/UserList";
import DataRequest from "./pages/Admin/DataRequest/DataRequest";
import SupportRequest from "./pages/Admin/SupportRequest/SupportRequest.jsx";
import EditRolePermission from "./pages/Admin/EditPermission/EditRolePermission.js";
import EditPlanPermission from "./pages/Admin/EditPermission/EditPlanPermission.js";
import ChangePayment from "./pages/Auth/Profile/ChangePayment/ChangePayment.jsx";
import Invited from "./pages/Project/ProjectInvitation/Invited.jsx";
import Partnership from "./pages/LandingPages/Partnership/Partnership.jsx";
import ArchitectAndEngineer from "./pages/LandingPages/Roles/ArchitectAndEngineer/ArchitectAndEngineer.jsx";
import RealEstateDeveloper from "./pages/LandingPages/Roles/RealEstateDeveloper/RealEstateDeveloper.jsx";
import Researcher from "./pages/LandingPages/Roles/Researcher/Researcher.jsx";
import BuildingComponentScheme from "./pages/Admin/BuildingComponentScheme/BuildingComponentScheme.jsx";
import InfoLogs from "./pages/Admin/Logs/InfoLogs.js";
import ErrorLogs from "./pages/Admin/Logs/ErrorLogs.js";
import UserDetails from "./pages/Admin/UserList/UserDetails.jsx";
import MaterialAnalyze from "./pages/MaterialAnalyze/MaterialAnalyze.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  //<React.StrictMode>
  <BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme="light"
    />
    <Routes history={history}>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/partnership" element={<Partnership />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/pricing/architect-and-engineer" element={<ArchitectAndEngineer />} />
        <Route path="/pricing/real-estate-developer" element={<RealEstateDeveloper />} />
        <Route path="/pricing/researcher" element={<Researcher />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password-request" element={<ForgotPasswordRequest />} />
        <Route path="/reset-password/:email" element={<ResetPassword />} />
        <Route path="/profile/profile-settings" element={<ProfileSettings />} />
        <Route path="/profile/security" element={<Security />} />
        <Route path="/profile/payment" element={<ChangePayment />} />
        <Route path="/profile/notification" element={<Notification />} />
        <Route path="/profile/get-support" element={<GetSupport />} />
        <Route path="/data-submission" element={<DataSubmission />} />
        <Route path="/projects" element={<ProjectList />} />
        <Route path="/projects/:projectId" element={<ProjectDetails />} />

        <Route
          path="/projects/:projectId/construction/:constructionId"
          element={<Analyze />}
        />
        <Route
          path="/projects/:projectId/constructions/:constructionIds"
          element={<GroupAnalyze />}
        />
        <Route path="/material-analyze" element={<MaterialAnalyze />} />
        <Route path="/material-list" element={<MaterialList />} />
        <Route path="/submit-data" element={<DataSubmission />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/user-list" element={<UserList />} />
        <Route path="/dashboard/user-list/:userId" element={<UserDetails />} />
        <Route path="/dashboard/data-requests" element={<DataRequest />} />
        <Route path="/dashboard/support-requests" element={<SupportRequest />} />
        <Route path="/dashboard/edit-role-permission" element={<EditRolePermission />} />
        <Route path="/dashboard/edit-plan-permission" element={<EditPlanPermission />} />
        <Route path="/dashboard/building-component-scheme" element={<BuildingComponentScheme />} />
        <Route path="/dashboard/info-logs" element={<InfoLogs />} />
        <Route path="/dashboard/error-logs" element={<ErrorLogs />} />
      </Route>
      <Route path="/verificated" element={<Verificated />} />
      <Route path="/project-invitation/:projectInviteToken" element={<Invited />} />
      <Route path="/verification-request" element={<VerificationRequest />} />
    </Routes>
  </BrowserRouter>
  //</React.StrictMode>
);
