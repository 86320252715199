import React from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const GroupExportReport = ({ data }) => {
    const generatePDF = () => {
        const doc = new jsPDF();

        // PDF Title
        doc.setFontSize(20);
        doc.text('Comprehensive Analysis Report', 14, 22);

        // Adding Data
        let yOffset = 30; // Initial Y offset

        // Kontrol: data.responses var mı?
        if (!data.responses || data.responses.length === 0) {
            doc.text('No data available for the report.', 14, yOffset);
            doc.save('comprehensive_analysis_report.pdf');
            return;
        }

        data.responses.forEach((response) => {
            doc.setFontSize(16);
            doc.text(`Construction Name: ${response.constructionName || 'N/A'}`, 14, yOffset);
            yOffset += 10;

            // Group Analyses
            if (response.groupAnalyzeResponses && response.groupAnalyzeResponses.length > 0) {
                // Create a table for group analysis
                const tableData = response.groupAnalyzeResponses.map(group => ({
                    Group: group.name || 'N/A',
                    'Total CO2 Emission (kg)': group.totalCo2Emission || 0,
                    'Total Energy Efficiency (kWh)': group.totalEnergyEfficiency || 0,
                }));

                autoTable(doc, {
                    head: [['Group', 'Total CO2 Emission (kg)', 'Total Energy Efficiency (kWh)']],
                    body: tableData.map(item => [item.Group, item['Total CO2 Emission (kg)'], item['Total Energy Efficiency (kWh)']]),
                    startY: yOffset,
                });

                // Y offset after table
                yOffset = doc.lastAutoTable.finalY + 10;

                // Grafiği ekle
                const chartData = response.groupAnalyzeResponses.map(group => ({
                    name: group.name || 'N/A',
                    totalCO2: group.totalCo2Emission || 0,
                }));

                // Basit bir çubuk grafik için örnek kod
                doc.setFontSize(12);
                doc.text('CO2 Emission by Group', 14, yOffset);
                yOffset += 10;

                // Çizim alanı
                const startX = 14;
                const barHeight = 8;
                const maxBarWidth = 150;
                const barSpacing = 20;

                chartData.forEach((group, index) => {
                    const barWidth = (group.totalCO2 / Math.max(...chartData.map(g => g.totalCO2))) * maxBarWidth;
                    doc.rect(startX, yOffset, barWidth, barHeight, 'F');
                    doc.text(group.name, startX + barWidth + 5, yOffset + 5);
                    yOffset += barSpacing;
                });

                // Page Break Check
                if (yOffset > 270) {
                    doc.addPage();
                    yOffset = 10; // Reset Y offset on new page
                }
            } else {
                doc.text('No group analysis data available.', 14, yOffset);
                yOffset += 10;
            }

            // Page Break Check
            if (yOffset > 270) {
                doc.addPage();
                yOffset = 10; // Reset Y offset on new page
            }
        });

        // Save the PDF
        doc.save('comprehensive_analysis_report.pdf');
    };

    return (
        <div>
            <button className="btn btn-outline-dark rounded-4" onClick={generatePDF}>
                <i className="bi bi-file-earmark-arrow-down" /> Export Report
            </button>
        </div>
    );
};

export default GroupExportReport;
