import { useState, useEffect } from "react";
import { getUser, updateUser } from "../../../../api/auth";
import { NotificationService } from "../../../../helper/notification";

import "../Background.css";
import ProfileBanner from "../../../../components/ProfileBanner/ProfileBanner";

const Notification = () => {
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        companyName: ""
    });

    const [isOn1, setIsOn1] = useState(true);
    const [isOn2, setIsOn2] = useState(false);
    const [isOn3, setIsOn3] = useState(false);

    const toggleSwitch1 = () => {
        setIsOn1((prevState) => !prevState);
    };

    const toggleSwitch2 = () => {
        setIsOn2((prevState) => !prevState);
    };

    const toggleSwitch3 = () => {
        setIsOn3((prevState) => !prevState);
    };

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await getUser();
                setUserInfo(response);
            } catch (error) {
                NotificationService.error("An error occurred");
            }
        };
        getUserInfo();
    }, {});

    return (
        <body className="bg-body-tertiary">
            <ProfileBanner userInfo={userInfo} />
            <section>
                <h2 className="tertiary-text fw-bold ps-5 mb-3">&nbsp;&nbsp;Profile Settings</h2>
                <div className="bg-white rounded-4 p-5 m-5">
                    <div className="pb-3">Notifications</div>
                    <div className="d-flex justify-content-between">
                        <span className="text-secondary">TYPE</span>
                        <span className="text-secondary">EMAIL</span>
                    </div>
                    <hr className="dashed-line" />
                    <div className="d-flex justify-content-between">
                        <span>Billing Notifications &nbsp;<small><i class="text-secondary bi bi-exclamation-circle" /></small></span>
                        <div className={`switch ${isOn1 ? 'secondary-btn' : 'bg-dark'}`} onClick={toggleSwitch1}>
                            <div className={`switch-handle ${isOn1 ? 'on' : 'off'}`}></div>
                        </div>
                    </div>
                    <hr className="dashed-line" />
                    <div className="d-flex justify-content-between">
                        <span>Site Messaging &nbsp;<small><i class="text-secondary bi bi-exclamation-circle" /></small></span>
                        <div className={`switch ${isOn2 ? 'secondary-btn' : 'bg-dark'}`} onClick={toggleSwitch2}>
                            <div className={`switch-handle ${isOn2 ? 'on' : 'off'}`}></div>
                        </div>
                    </div>
                    <hr className="dashed-line" />
                    <div className="d-flex justify-content-between">
                        <span>Expiring Account &nbsp;<small><i class="text-secondary bi bi-exclamation-circle" /></small></span>
                        <div className={`switch ${isOn3 ? 'secondary-btn' : 'bg-dark'}`} onClick={toggleSwitch3}>
                            <div className={`switch-handle ${isOn3 ? 'on' : 'off'}`}></div>
                        </div>
                    </div>
                </div>
            </section>
        </body>
    );
};

export default Notification;
