import instance from "../baseInstance";

export async function getPermissionById(pathVariable) {
  const response = await instance.get("admin/permissions/" + pathVariable);

  return response.data;
}

export async function updatePermission(pathVariable, jsonData) {
  const response = await instance.put("admin/permissions/" + pathVariable, jsonData);

}

export async function deletePermission(pathVariable) {
  await instance.delete(`admin/permissions?id=${pathVariable}`
  );
}

export async function addPermission(jsonData) {
  await instance.post("admin/permissions", jsonData);
}

export async function getAllPermissions() {
  const response = await instance.get("admin/permissions/find-all");

  return response.data;
}
