import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { NotificationService } from "../../../helper/notification";
import { useNavigate } from "react-router-dom";
import { getAccountsByAccounttype, getExpiringUsers, getMostUsedMaterials, getProjectsCount, getTotalErrorLogs, getTotalInfoLogs, getTotalUsers } from "../../../api/admin/admin";
import { ClipLoader } from "react-spinners";
import DashboardCard from "../../../components/DashboardCard/DashboardCard";

import dashboard_icon_1 from "./dashboard-icon-1.png";
import DoughnutChart from "../../../components/Charts/DoughnutChart/DoughnutChart";
import { getAllOpenSupportRequests } from "../../../api/admin/supportRequest";
import { listOpenRequests } from "../../../api/admin/dataRequest";
import BarChart from "../../../components/Charts/BarChart/BarChart";

const Dashboard = () => {
  const [expiringUsers, setExpiringUsers] = useState(null);
  const [registeredUsers, setRegisteredUsers] = useState(null);
  const [projectsCount, setProjectsCount] = useState(null);
  const [openDataRequests, setOpenDataRequests] = useState(null);
  const [mostUsedMaterials, setMostUsedMaterials] = useState(null);
  const [allOpenSupportRequests, setAllOpenSupportRequests] = useState(null);
  const [infoLogsCount, setInfoLogsCount] = useState(null);
  const [errorLogsCount, setErrorLogsCount] = useState(null);
  const [accountsByAccountType, setAccountsByAccountType] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    const getDashboardItems = async () => {
      try {
        const infoLogsCountData = await getTotalInfoLogs();
        setInfoLogsCount(infoLogsCountData);
        const errorLogsCountData = await getTotalErrorLogs();
        setErrorLogsCount(errorLogsCountData);
        const expiringUsersData = await getExpiringUsers();
        setExpiringUsers(expiringUsersData);
        const registeredUsersData = await getTotalUsers();
        setRegisteredUsers(registeredUsersData);
        const projectsCountData = await getProjectsCount();
        setProjectsCount(projectsCountData);
        const openDataRequestsData = await listOpenRequests()
        setOpenDataRequests(openDataRequestsData);
        const mostUsedMaterialData = await getMostUsedMaterials();
        setMostUsedMaterials(mostUsedMaterialData);
        const allOpenSupportRequestsData = await getAllOpenSupportRequests(1, 4);
        setAllOpenSupportRequests(allOpenSupportRequestsData);
        const accountsByAccountTypeData = await getAccountsByAccounttype();
        setAccountsByAccountType(accountsByAccountTypeData);
      } catch (error) {
        NotificationService.error(error);
      }
    };

    getDashboardItems();
  }, []);

  return (
      <body className="bg-body-tertiary">
      <div className="py-5"></div>
      <section>
        <div className="d-flex justify-content-around">
          <div className="mx-1 my-3">
            <h3 >Dashboard</h3>
            <span className="secondary-text">Welcome</span>
          </div>
          <div className="input-group w-75 mx-1 my-3">
            <span className="input-group-text bg-white"><i className="bi bi-search"></i></span>
            <input
                type="text"
                placeholder="Search"
                value={""}
                onChange={() => { }}
                className="form-control border-start-0"
            />
          </div>
        </div>
      </section>
      <section className="">
        <div className="row container d-flex justify-content-around">
          <div className="col-md-3">
            <DashboardCard
                title={"All Projects"}
                destination={null}
                content={
                  projectsCount === null ? (
                          <ClipLoader
                              loading={projectsCount === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (<h2>{projectsCount}</h2>)
                }
            />
            <DashboardCard
                title={"Info Logs"}
                destination={"/dashboard/info-logs"}
                content={
                  infoLogsCount === null ? (
                          <ClipLoader
                              loading={infoLogsCount === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (<h2>{infoLogsCount}</h2>)
                }
            />
          </div>
          <div className="col-md-3">
            <DashboardCard
                title={"Registered Users"}
                destination={"/dashboard/user-list"}
                content={
                  registeredUsers === null ? (
                          <ClipLoader
                              loading={registeredUsers === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (<h2>{registeredUsers}</h2>)
                }
            />
            <DashboardCard
                title={"Error Logs"}
                destination={"/dashboard/error-logs"}
                content={
                  errorLogsCount === null ? (
                          <ClipLoader
                              loading={errorLogsCount === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (<h2>{errorLogsCount}</h2>)
                }
            />
          </div>
          <div className="col-md-6">
            <DashboardCard
                title={"Data Requests"}
                destination={"/dashboard/data-requests"}
                content={
                  openDataRequests === null ? (
                          <ClipLoader
                              loading={openDataRequests === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (<div className="">
                        {openDataRequests.sort((a, b) => new Date(b.createDate) - new Date(a.createDate)).slice(0, 3).map((openDataRequest) =>
                            <div className="d-flex justify-content-between pt-3">
                              <div className="pe-5">
                                <span className="fw-bold">{openDataRequest.username}</span>
                                <div><small>{openDataRequest.createdDate}</small></div>
                              </div>
                              <div>
                          <span
                              className={`${openDataRequest.requestType === "Create"
                                  ? "text-success" : openDataRequest.requestType === "Update"
                                      ? "text-danger" : "text-warning"} fw-bold`}>
                            {openDataRequest.requestType}
                          </span>
                              </div>
                            </div>)}
                      </div>)
                }
            />
          </div>
        </div>
      </section>
      <section>
        <div className="row container d-flex justify-content-around">
          <div className="col-md-6">
            <DashboardCard
                title={"Paid Members with Roles"}
                destination={null}
                content={
                  accountsByAccountType === null ? (
                      <ClipLoader
                          loading={accountsByAccountType === null}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                      />
                  ) : (
                      <div>{<BarChart accountData={accountsByAccountType} />}</div>
                  )

                }
            />
          </div>
          <div className="col-md-6">
            <DashboardCard
                title={"Support Requests"}
                destination={"/dashboard/support-requests"}
                content={
                  allOpenSupportRequests === null ? (
                          <ClipLoader
                              loading={allOpenSupportRequests === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (<div className="pt-4">
                        {allOpenSupportRequests.content.map((supportRequest) =>
                            <div className="d-flex justify-content-between pb-3">
                              <div className="d-flex">
                                <img src={dashboard_icon_1} height={"60%"} width={"10%"} />
                                <div className="ps-2">
                                  <span className="fw-bold">{supportRequest.subject}</span>
                                  <div><small>{supportRequest.lastUpdatedDate.replace("T", " - ").slice(0, supportRequest.lastUpdatedDate.lastIndexOf(":") + 2)}</small></div>
                                </div>
                              </div>
                              <div>
                          <span
                              className={`${supportRequest.customerServiceStatus === "OPEN"
                                  ? "text-success" : supportRequest.customerServiceStatus === "CLOSED"
                                      ? "text-danger" : "text-warning"} fw-bold`}>
                            {supportRequest.customerServiceStatus}
                          </span>
                                <div className="text-center"><small>{supportRequest.id}</small></div>
                              </div>
                            </div>
                        )}
                      </div>)
                }
            />
          </div>
        </div>
      </section>
      <section>
        <div className="row container d-flex justify-content-around">
          <div className="col-md-6">
            <DashboardCard
                title={"Expiring Users (Within 1 Week)"}
                destination={"/dashboard/expiring-users"}
                content={
                  expiringUsers === null ? (
                          <ClipLoader
                              loading={expiringUsers === null}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                          />
                      ) :
                      (
                          <div className="pt-4">
                            {expiringUsers.sort((a, b) => b.remainingDuration - a.remainingDuration).slice(0, 4).map((expiringUser) =>
                                <div className="d-flex justify-content-between pb-3">
                                  <div>
                                    <span className="text-dark fw-bold">{expiringUser.userName}</span>
                                    <div>
                                      <small>{expiringUser.endDate.replace("T", " - ").slice(0, expiringUser.endDate.lastIndexOf(":") + 2)}</small>
                                    </div>
                                  </div>
                                  <button className="btn btn-outline-secondary rounded-5">Send E-Mail</button>
                                </div>
                            )}
                          </div>
                      )
                }
            />
          </div>
          <div className="col-md-6">
            <DashboardCard
                title={"Most Used Material"}
                destination={null}
                content={
                  !mostUsedMaterials || mostUsedMaterials.length === 0 ? (
                      <ClipLoader
                          loading={!mostUsedMaterials || mostUsedMaterials.length === 0}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                      />
                  ) : (
                      <>
                        <div className="d-flex justify-content-between px-5 pt-5">
                          <div className="w-50 pe-5">
                            <div className="pb-3">
                              <DoughnutChart mostUsedMaterials={mostUsedMaterials}/>
                            </div>
                            <div className="">
                              <small className="d-flex align-self-center pe-2">
                                <div
                                    className="d-flex align-self-center rounded-circle px-1 py-1"
                                    style={{backgroundColor: "#FFE084"}}
                                ></div>
                                &nbsp;&nbsp;{mostUsedMaterials[0][0]}
                              </small>
                              <small className="d-flex align-self-center pe-2">
                                <div
                                    className="d-flex align-self-center rounded-circle px-1 py-1"
                                    style={{backgroundColor: "#46A1B9"}}
                                ></div>
                                &nbsp;&nbsp;{mostUsedMaterials[1][0]}
                              </small>
                              <small className="d-flex align-self-center pe-2">
                                <div
                                    className="d-flex align-self-center rounded-circle px-1 py-1"
                                    style={{backgroundColor: "#46A1B9"}}
                                ></div>
                                &nbsp;&nbsp;{mostUsedMaterials[2][0]}
                              </small>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div>
                              <small>
                                {mostUsedMaterials[0][0]}: {mostUsedMaterials[0][1]}
                              </small>
                              <br/>
                              <br/>
                              <br/>
                              <small>
                                {mostUsedMaterials[1][0]}: {mostUsedMaterials[1][1]}
                              </small>
                              <br/>
                              <br/>
                              <br/>
                              <small>
                                {mostUsedMaterials[2][0]}: {mostUsedMaterials[2][1]}
                              </small>
                            </div>
                          </div>
                        </div>
                      </>
                  )
                }
            />
          </div>
        </div>
      </section>
      </body>
  );
};

export default Dashboard;
